import { useSelector } from "react-redux";
import Header from "../../Components/Header/Header";
import ServicesNavBar from "../../Components/ServicesNavBar/ServicesNavBar";
import "./DashboardLayout.scss";
import { GLOBAL_CONSTANTS, ROUTES } from "./../../Constants/Constants";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";

const DashboardLayout = () => {
  /**Initial states */
  const location = useLocation();
  const [isAccounts, setIsAccounts] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [isMapView, setMapView] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  useEffect(() => {
    if (
      location.pathname.includes(ROUTES.ACCOUNTS) ||
      location.pathname.includes(ROUTES.HOMEPAGE) ||
      location.pathname.includes(ROUTES.ABOUTUS_WITHOUT_LOGIN) ||
      location.pathname.includes(ROUTES.THEMEPARK__HOMEPAGE)
    ) {
      setIsAccounts(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
    } else {
      setIsAccounts(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    }

    if (
      location.pathname.includes(ROUTES.MAP_VIEW)
    ) {
      setMapView(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
    } else {
      setMapView(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    }
  }, [location]);


  /*Below class name based on English Arabic*/
  const main_outlet_container_lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "main_outlet_container" : "main_outlet_container_arab";

  const sidebar_outlet_container_lang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "sidebar_outlet_container_english" : "sidebar_outlet_container_arabic";

  return (
    <>
      <div
        className="main_layout_container"
        dir={
          language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
            ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
            : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
        }
      >
        <div className="background_circles_wrapper">
          <div className="background_circles_container">
            {location.pathname.includes(ROUTES.TUTORS) && (
              <>
                <div className="background_circle_left__tutor"></div>
                <div className="background_circle_right__tutor"></div>
              </>
            )}
            {location.pathname.includes(ROUTES.ACTIVITYLAYOUT) && (
              <>
                <div className="background_circle_left__Activities"></div>
                <div className="background_circle_right__Activities"></div>
              </>
            )}
            {location.pathname.includes(ROUTES.UNIVERSITYLAYOUT) && (
              <>
                <div className="background_circle_left__University"></div>
                <div className="background_circle_right__University"></div>
              </>
            )}
            {location.pathname.includes(ROUTES.SCHOOLLAYOUT) && (
              <>
                <div className="background_circle_left__School"></div>
                <div className="background_circle_right__School"></div>
              </>
            )}
            {location.pathname.includes(ROUTES.THEMEPARK_LAYOUT) && (
              <>
                <div className="background_circle_left__themePark"></div>
                <div className="background_circle_right__themePark"></div>
              </>
            )}
            {location.pathname.includes(ROUTES.ACCOUNTS) && (
              <>
                <div className="background_circle_left__profile"></div>
                <div className="background_circle_right__profile"></div>
              </>
            )}
          </div>
        </div>
        <Header />
        {isMapView ? (
          <div className={`sidebar_outlet_container full-width ${sidebar_outlet_container_lang}`}>
            <div className="sidebar_outlet__visble">
              <ServicesNavBar />
            </div>
            <div className="w-100 main_outlet_container">
              <Outlet />
            </div>
          </div>
        ) : !isAccounts ? (
          // <div className="sidebar_outlet_container">
          <div className={`sidebar_outlet_container ${sidebar_outlet_container_lang}`}>
            <div className="sidebar_outlet__visble">
              <ServicesNavBar />
            </div>
            {/* <div className="w-100 main_outlet_container"> */}
            <div className={`w-100 ${main_outlet_container_lang}`}>
              <Outlet />
              {/* <Footer /> */}
            </div>
          </div>
        ) : (
          <>
            <Outlet />
            {/* <Footer /> */}
          </>
        )}
        <Footer />
      </div>
    </>
  );
};

export default DashboardLayout;
