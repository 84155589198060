import React from 'react';
import './MySVGComponent.scss';
interface MySVGComponentProps {
  checkmarkStrokeColor: string; 
}

const MySVGComponent: React.FC<MySVGComponentProps> = ({ checkmarkStrokeColor }) => {
  return (
    <div className="payment-success-animation">
      <svg viewBox="0 0 24 24" width="100" height="100">
        <circle className="checkmark-circle" cx="12" cy="12" r="10" />
        <polyline className="checkmark-check" stroke={checkmarkStrokeColor} points="6.3,12.5 10.8,17 17,8" />
      </svg>
    </div>
  );
}

export default MySVGComponent;
