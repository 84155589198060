import "./ThemeParkHomePage.scss";
import locationIcon from "../../Assets/Tutor/location.svg";
import React, { useState, useEffect } from 'react';
import NavigateNextSharpIcon from '@mui/icons-material/NavigateNextSharp';
import StarIcon from '@mui/icons-material/Star';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
// import required modules
import { Autoplay, Pagination, Navigation, Mousewheel, Keyboard, Grid } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_END_POINTS, AUTHENTICATION, FILTER_CONSTANT, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
// For cr three card
// import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent, { CardContentProps } from '@mui/material/CardContent';
import Discount from "../../Assets/home/Discount.svg";
import Share from "../../Assets/home/Share.svg";
import HeartOutline from "../../Assets/home/Heart_Outline.svg";
import HeartFilled from "../../Assets/home/Heart_Filled.svg";
import GroupLeft from "../../Assets/ThemePark/Group.svg";
import GroupRight from "../../Assets/ThemePark/Group2.svg";
// For card rading
import Rating from '@mui/material/Rating';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// For Search
import LikeFilled from "../../Assets/ThemePark/HeartFilled.svg";

import { TextField, Button, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { GetSessionStorageData, SetSessionStorageData, encryptPassData, nullUndefinedEmptyCheck, } from "../../Utilities/Helpers/Helper";
// import { useHistory } from 'react-router-dom';
import banner1 from "../../Assets/ThemePark/banner1.jpg";
import themeParkImgsvg from "../../Assets/ThemePark/unsplashGird2-svg.png";
import WebShareComponent from "../../Components/WebShare/WebShare";
import axios from "axios";
import toast from "react-hot-toast";
import { setLoginSignUpOpen, setGuestSignupOpen } from "../../Redux/Reducer/Reducer";
import TuneIcon from '@mui/icons-material/Tune';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
// import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useTranslation } from "react-i18next";
// const StyledCardContent = styled(CardContent)({
//     position: 'absolute',
//     bottom: 0,
//     width: '100%',
//     background: 'rgba(0, 0, 0, 0.5)',
//     color: 'white',
//     borderRadius:'10px',
// });
type CustomHeaders = {
    'Content-Type': string;
    Authorization?: string;
};
const StyledCardContent = styled(({ isHovered, ...rest }: CardContentProps & { isHovered?: boolean }) => (
    <CardContent {...rest} />
)) <{ isHovered?: boolean }>`

    position: absolute;
    bottom: 0;  
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 10px;
    padding: 15px 20px 10px 20px !important;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: ${(props) => (props.isHovered ? '500px' : '200px')};
  `;



//   three crad end
const ThemeParkHomePage = () => {
    /* For search & Home Page Data*/
    const { t } = useTranslation();
    const themePark_search = GetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK);
    const [searchTerm, setSearchTerm] = useState<string>(themePark_search ? themePark_search : '');
    const [searchActive, setSearchActive] = useState<boolean>(false);
    const [searchfilteredData, setSearchFilteredData] = useState<any[]>([]);
    const [themePagedData, setThemePagedData] = useState<any>([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // For View Card, Hide card
    const [hoveredStates, setHoveredStates] = useState(Array(themePagedData?.location?.length).fill(false));
    const [showMore, setShowMore] = useState(Array(themePagedData?.location?.length).fill(false));
    const [loading, setLoading] = useState(false);

    const toggleDescription = (index: number) => {
        setShowMore((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };

    const handleMouseEnter = (index: number) => {
        setHoveredStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = true;
            return newStates;
        });
    };

    const handleMouseLeave = (index: number) => {
        setHoveredStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = false;
            return newStates;
        });
    };

    /** navigate to themPark DetailedView */
    const navigateDetailedView = (value: any) => {
        navigate(ROUTES.THEMEPARK_DETAILE_VIEW + "?id=" + encryptPassData(value?.id), {
            state: { tutor_Detailes: value }
        });
    }

    /** navigate to themPark List View */
    const navigateListView = (value: any) => {
        navigate(ROUTES.THEMEPARK_LIST_VIEW, { state: { themePark: value } });
    }

    const navigateCheckOut = (value: any) => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN) || GetSessionStorageData(AUTHENTICATION.SHARED.GUEST_ACCESS_TOKEN);
        if (value.ticket_type_list.length > 0) {
            if (nullUndefinedEmptyCheck(accessToken)) {
                navigate(ROUTES.THEMEPARK_CHECKOUT + "?id=" + value?.main_events[0]?.id + '&event=' + value?.id, { state: { event: value?.id, theme_park_id: value?.main_events[0]?.id, details: value } });
            } else {
                // dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
                dispatch(setGuestSignupOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
            }
        } else {
            toast.error("Tickets not Available", {
                position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
        }

    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getThemePageData(searchTerm);
    }, [])

    const handleSearch = (value: any) => {
        setSearchTerm(value);
        if (nullUndefinedEmptyCheck(value)) {
            setSearchActive(false);
            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK, value);
            navigate(ROUTES.THEMEPARK_LAYOUT, {
                state: { search: value }
            });
        }

    };

    const handleEvents = (value: any) => {
        SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK, value);
        navigate(ROUTES.THEMEPARK_LAYOUT, {
            state: { search: value }
        });
    }

    const onInputChange = (e: any) => {
        e.preventDefault();
        let value = e.target.value;
        setSearchTerm(value);
        setSearchActive(true);
        SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK, value);

        if (nullUndefinedEmptyCheck(value)) {

            axios.get(process.env.REACT_APP_BASE_BACKEND_API_URL + API_END_POINTS.THEMEPARK_SEARCH_KEYWORDS + value, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((Response: any) => {
                setSearchFilteredData(Response?.data?.result?.data);
            })
                .catch(() => { });
        } else {
            getThemePageData('');
            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK, '');
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        //  prevent page refresh
        event.preventDefault();
        if (nullUndefinedEmptyCheck(searchTerm)) {
            // handleButtonClick("")
        }
    };
    /****/
    const getThemePageData = (searchValue: any) => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN) || GetSessionStorageData(AUTHENTICATION.SHARED.GUEST_ACCESS_TOKEN);

        const payload = {
            type: "web",
            screen: "all",
            page: 1,
            limit: 10,
            search: searchValue
        };

        const headers: CustomHeaders = {
            'Content-Type': 'application/json',
        };

        if (accessToken) {
            headers['Authorization'] = `Bearer ${accessToken}`;
        }

        axios.post(
            `${process.env.REACT_APP_BASE_BACKEND_API_URL}${API_END_POINTS.THEMEPARK_HOMEPAGE}`, payload, { headers }
        )
            .then((themePageHomeResponse) => {
                const responseData = themePageHomeResponse?.data?.result?.data;
                if (responseData) {
                    setThemePagedData(responseData);
                    setSearchActive(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching theme park data:", error);
                // Handle error or set state accordingly
            });
    };


    /*For View More Button */
    const handleButtonClick = (data: any) => {
        SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK, searchTerm);
        navigate(ROUTES.THEMEPARK_LAYOUT, {
            state: { search: searchTerm, screen: data }
        });
    };

    /*For Detail Page Func */
    const navigateThemeDetailedView = (value: any) => {
        navigate(ROUTES.THEMEPARK_DETAILE_VIEW + "?id=" + encryptPassData(value?.id), {
            state: { tutor_Detailes: value }
        });
    };

    const handleChangeFavourites = (val: any, id: any) => {
        const accessToken = GetSessionStorageData(
            AUTHENTICATION.SHARED.ACCESS_TOKEN
        );
        if (nullUndefinedEmptyCheck(accessToken)) {
            var payload = {
                service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.SIX_INT,
                service_provider: id,
                is_favourite: !val,
            };
            axiosInstance
                .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
                .then((Response: any) => {
                    if (Response?.data?.status) {
                        getThemePageData(searchTerm);
                        toast.success(Response?.data?.message, {
                            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
                        });
                    }
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
                    });
                });
        } else {
            dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
        }
    };

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });
    /*Below class name based on English Arabic*/
    const SwiperControlArab = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "swiper-control-english" : "swiper-control-arab";
    const SearchBoxArab = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_box-english" : "search_box-arab";
    const ThemeThreeCard = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "theme-three-card-english" : "theme-three-card-arab";
    const BannerSwipperArab = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "banner_swipper-english" : "banner_swipper-arab";
    const ReviewConatinerArab = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "Review-conatiner-english" : "Review-conatiner-arab";
    const ArrowSplashArab = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "arrow-splash-english" : "arrow-splash-arab";
    const Gird1 = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "Gird1" : "Gird1_arb";
    const Gird4 = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "Gird4" : "Gird4_arb";
    const Gird5 = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "Gird5" : "Gird5_arb";
    //Text Stored by variable
    const SearchParkEvent = t("Search for Parks & Events");
    const NoItemFount = t("No items found");
    const ViewMoreTxt = t("View more");
    const TRENDING_Txt = t("TRENDING");
    const ParksofTheMonth = t("Theme Parks of the month ⭐");
    const Themepark_Section_2 = t("themepark_section_2_tp_of_the_month_desc");
    const MakeSPLASH = t("Make a SPLASH");
    const RideForTheWeekend = t("Select your ride for the weekend");
    const Themepark_Review_Description = t("themepark_review_description");
    const GoogleReview_Txt = t("Google review");

    return (
        <>
            <div>
                {
                    !loading ?
                        <div className="ThemePark__HomePage">
                            <Swiper
                                className="banner_swipper"
                                dir={
                                    language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ltr" : "rtl"
                                }
                                key={language}
                                autoplay={{
                                    delay: 25000,
                                    disableOnInteraction: false,
                                }}
                                keyboard={true}
                                modules={[Pagination, Autoplay, Navigation, Mousewheel, Keyboard]}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                                pagination={{ clickable: true }}
                            >
                                {themePagedData?.banner?.map((imageSrc: any, index: any) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <img src={imageSrc} onError={(e: any) => { e.target.src = banner1; }} className="banner_image" alt="" />
                                        </SwiperSlide>
                                    )
                                })}

                                <div className={`${SwiperControlArab} swiper-control`}>
                                    <div className="swiper-button-prev" style={{ left: `-${themePagedData?.banner?.length * 7 + 19}px ` }}></div>
                                    <div className="swiper-button-next" style={{ left: `${themePagedData?.banner?.length * 6}px ` }}></div>
                                </div>
                            </Swiper>

                            <div className="ThemePark__second__conatant">

                                <div className="background_circle_1"></div>
                                <div className="background_circle_2"></div>
                                <div className="background_circle_3"></div>


                                {/* Theme Three card */}

                                <div className="search-page-container">
                                    <img src={GroupLeft} alt="" className="celebrationLeft" />
                                    <img src={GroupRight} alt="" className="celebrationRight" />



                                    <div className="search_box_container">
                                        <div className={`${SearchBoxArab} search_box`}>
                                            <TextField
                                                className="search-field"
                                                variant="outlined"
                                                value={searchTerm ?? ''}
                                                placeholder={SearchParkEvent}
                                                onChange={(e) => onInputChange(e)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <SearchIcon
                                                            onClick={() => handleSearch(searchTerm)}
                                                            style={{ color: '#C0C0C0', cursor: 'pointer' }}
                                                        />
                                                    ),
                                                    style: { borderRadius: '15px', backgroundColor: "#FFF", height: '46px' },
                                                }}
                                                onKeyPress={(e: any) => {
                                                    if (e.code === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                                                        e.preventDefault();
                                                        if (searchTerm.trim() === '') {
                                                            sessionStorage.removeItem(FILTER_CONSTANT.SEARCH_STATE.THEMEPARK);
                                                        } else {
                                                            handleSearch(searchTerm);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>

                                        {(searchTerm && searchActive) && (
                                            <Paper className="auto-complete-container" elevation={3}>
                                                {searchfilteredData?.length > 0 ? (
                                                    <List>
                                                        {searchfilteredData?.map((item) => (
                                                            <ListItem
                                                                key={item.id}
                                                                button
                                                                onClick={() => handleSearch(item.tag)}
                                                            >
                                                                <ListItemText primary={item.tag} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                ) : (
                                                    <Typography variant="body1" align="center">
                                                        <span>{NoItemFount}</span>
                                                    </Typography>
                                                )}
                                            </Paper>
                                        )}
                                    </div>


                                </div>

                                <div className="container data__conatiner" style={{ zIndex: "0", marginTop: '120px' }}>
                                    {themePagedData?.location?.slice(0, 3)?.map((data: any, index: number) => {

                                        const themeParkName = data?.themepark_name;
                                        const DataAddress = data?.address;
                                        const DataRading = data?.rating;
                                        const DataMinPrice = data?.min_price;
                                        const BookTicketText = t("Book Tickets");
                                        const DataDescrip = showMore[index] ? data?.description : `${data?.description?.substring(0, 100)}`;
                                        /* Variable to showmore Showlesss content */
                                        let showMoreContent = null;
                                        if (data?.description.length > 100 && !showMore[index]) {
                                            showMoreContent = <span style={{ cursor: 'pointer' }}>...</span>;
                                        } else if (showMore[index]) {
                                            showMoreContent = <span style={{ color: '#01B4D3', cursor: 'pointer' }}>{t("view less")}</span>;
                                        }
                                        const fromText = t("from");
                                        const aedText = t("AED");
                                        const ThemeImg0 = data.themeparkcover_images[0];
                                        const ThemeshareParams = `${ROUTES.THEMEPARK_DETAILE_VIEW}?id=${encryptPassData(data?.id)}`;

                                        return (
                                            <Card
                                                key={data.id}
                                                className={`${ThemeThreeCard} theme_three_card`}
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={() => handleMouseLeave(index)}
                                            >

                                                <CardMedia
                                                    className="theme-three-card-img"
                                                    component="img"
                                                    src={ThemeImg0}
                                                    onError={(e: any) => { e.target.src = themeParkImgsvg; }}
                                                    style={{ width: '100%', height: '540px', cursor: "pointer" }}
                                                    alt="Paella dish"
                                                    onClick={() => navigateThemeDetailedView(data)}
                                                />
                                                <StyledCardContent isHovered={hoveredStates[index]}>

                                                    <div className="theme_rating_inc">
                                                        <div className="theme_card_head">
                                                            {/* {data?.themepark_name} */}
                                                            <span>{themeParkName}</span>
                                                        </div>

                                                        <div className="theme-share-like">

                                                            <WebShareComponent
                                                                params={ThemeshareParams}
                                                            >
                                                                <img src={Share} alt="" className="mb-1" />

                                                            </WebShareComponent>

                                                            <img
                                                                src={data?.is_favourite ? LikeFilled : HeartOutline}
                                                                alt=""
                                                                onClick={() =>
                                                                    handleChangeFavourites(data?.is_favourite, data?.user)
                                                                }
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="card_map_icon">
                                                        <div title={DataAddress}><span><LocationOnIcon />{DataAddress}</span></div>
                                                    </div>

                                                    <div className="theme_rating_inc">
                                                        <div>
                                                            <Rating name="read-only" value={DataRading} readOnly emptyIcon={<StarIcon style={{ opacity: 2 }} fontSize="inherit" />} /> <span className="rating_count">{DataRading}/5</span>
                                                        </div>


                                                        <div>
                                                            {/* <Rating name="read-only" value={data?.rating} readOnly /><span  className="rating_count">4/5</span> */}
                                                            <span>{fromText} {aedText} {DataMinPrice?.replace(/(\.00|\.0)$/, '')}</span>

                                                        </div>
                                                    </div>

                                                    {hoveredStates[index] &&
                                                        <div className="hover_content" >
                                                            <span>{DataDescrip}</span>
                                                            {/* <div className="showmore" onClick={() => toggleDescription(index)} > {(data?.description.length > 100 && !showMore[index]) && <span>...</span>} {showMore[index] && <span style={{ color: '#01B4D3' }}>view less</span>}</div> */}
                                                            <div className="showmore" onClick={() => toggleDescription(index)} >
                                                                {showMoreContent}
                                                            </div>

                                                            <button className="booking_button mt-2" onClick={() => navigateCheckOut(data)}><span>{BookTicketText}</span></button>
                                                        </div>


                                                    }
                                                </StyledCardContent>
                                            </Card>
                                        )
                                    })}
                                </div>

                                <div className="container">
                                    <div className="theme-view-more-button" onClick={() => handleButtonClick("location")}>
                                        <Button disableRipple>
                                            <span>{ViewMoreTxt}</span>
                                        </Button>
                                    </div>
                                </div>

                                <div className="container mt-5">
                                    <div className="row Trending__wapper">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                            <div className="left__wapper">
                                                <div className="trending_icon">

                                                    <TrendingUpIcon />
                                                    <div className="Trending__text"><span>{TRENDING_Txt}</span></div>
                                                </div>
                                                <div className="title "><span>{ParksofTheMonth}</span></div>
                                                <div className="desctiption "><span>{Themepark_Section_2}</span></div>
                                            </div>

                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                            <Swiper
                                                slidesPerView={1}
                                                spaceBetween={30}
                                                dir={
                                                    language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ltr" : "rtl"
                                                }
                                                key={language}
                                                grid={{
                                                    rows: 1,
                                                }}
                                                keyboard={true}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                modules={[Pagination, Navigation, Keyboard, Grid]}
                                                navigation={{
                                                    nextEl: '.swiper-button-next',
                                                    prevEl: '.swiper-button-prev',
                                                }}
                                                className={`${BannerSwipperArab} trending_swipper`}
                                                allowTouchMove={false}
                                                breakpoints={{
                                                    320: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 20,
                                                    },
                                                    768: {
                                                        slidesPerView: 2,
                                                        spaceBetween: 20,
                                                    },
                                                    1024: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 20,
                                                    },
                                                }}
                                            >
                                                {themePagedData?.month?.map((item: any, i: any) => {
                                                    const ITEM_ADDRESS = item?.address;
                                                    const THEMEPARK_NAME = item?.themepark_name;
                                                    const AED_TXT = t("AED");
                                                    const ITEM_MIN_PRICE = item?.min_price;
                                                    return (
                                                        <>
                                                            <SwiperSlide key={i}>
                                                                <div className="rightSide_card_corosal" onClick={() => navigateDetailedView(item)}>
                                                                    <img src={item?.themeparkcover_images[0]} onError={(e: any) => { e.target.src = themeParkImgsvg; }} alt="" className="coverImg" draggable="false" />
                                                                    <div className="name mt-2"><span>{THEMEPARK_NAME}</span></div>
                                                                    <div className="location__wapper">
                                                                        <img src={locationIcon} alt="" />
                                                                        <span className="location" title={ITEM_ADDRESS}>{ITEM_ADDRESS}</span>
                                                                    </div>
                                                                    <div className="Amount mt-2"><span>{AED_TXT} {ITEM_MIN_PRICE}</span></div>
                                                                </div>
                                                            </SwiperSlide>
                                                        </>
                                                    )
                                                })}

                                                <div className="swiper-control">
                                                    <div className="swiper-button-prev"></div>
                                                    <div className="swiper-button-next" style={{ left: `${themePagedData?.month?.length * 14 + 16}px ` }}></div>
                                                </div>
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>

                                <div className="container splash__container">
                                    <div className="title__wapper">
                                        <div className="title"><span>{MakeSPLASH}</span></div>
                                        <NavigateNextSharpIcon className={`${ArrowSplashArab} arrow__splash`} onClick={() => handleButtonClick("splash")} />
                                    </div>

                                    <div className="splash__card__wapper">
                                        {themePagedData?.splash?.slice(0, 5)?.map((item: any, i: any) => {
                                            const THEMECOVER_IMG = item?.themeparkcover_images[0];
                                            const THEMEPARK_NAME = item?.themepark_name;
                                            const ITEM_RADING = item?.rating;

                                            return (
                                                <>
                                                    <div key={i} className="splash__card__conatiner mt-4" onClick={() => navigateDetailedView(item)}>
                                                        <img src={THEMECOVER_IMG} className="card__img" onError={(e: any) => { e.target.src = themeParkImgsvg; }}
                                                            alt="" />
                                                        <div className="card__contant__wapper">
                                                            <div className="text__contant"><span>{THEMEPARK_NAME}</span></div>
                                                            <div className="title__wapper">
                                                                <span className="StarIcon" ><StarIcon /></span>
                                                                <span className="text__contant">{ITEM_RADING}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className=" Gallary__container">
                                    <div className="container">
                                        <div className="title mb-4"><span>{RideForTheWeekend}</span></div>

                                        <div className="Gallary__Wapper">
                                            {themePagedData?.event && themePagedData.event.length >= 5 && (
                                                <>
                                                    <div className="layer">
                                                        <img src={themePagedData.event[0]?.event_image} alt="" className={Gird1} onClick={() => handleEvents(themePagedData.event[0]?.event_name)} onError={(e: any) => { e.target.src = themeParkImgsvg; }} />
                                                        <button className="Gird1Btn Button__wapper" onClick={() => handleEvents(themePagedData.event[0]?.event_name)} >{themePagedData.event[0]?.event_name}</button>
                                                    </div>

                                                    <div className="layer">
                                                        <img src={themePagedData.event[1]?.event_image} alt="" className="Gird2" onClick={() => handleEvents(themePagedData.event[1]?.event_name)} onError={(e: any) => { e.target.src = themeParkImgsvg; }} />
                                                        <button className="Gird2Btn Button__wapper" onClick={() => handleEvents(themePagedData.event[1]?.event_name)} >{themePagedData.event[1]?.event_name}</button>
                                                        <button className="Gird3Btn Button__wapper" onClick={() => handleEvents(themePagedData.event[2]?.event_name)} >{themePagedData?.event[2]?.event_name}</button>
                                                        <img src={themePagedData?.event[2]?.event_image} alt="" className="Gird3" onClick={() => handleEvents(themePagedData.event[2]?.event_name)} onError={(e: any) => { e.target.src = themeParkImgsvg; }} />
                                                    </div>
                                                    <div className="layer">
                                                        <img src={themePagedData?.event[3]?.event_image} alt="" className={Gird4} onClick={() => handleEvents(themePagedData.event[3]?.event_name)} onError={(e: any) => { e.target.src = themeParkImgsvg; }} />
                                                        <button className="Gird4Btn Button__wapper" onClick={() => handleEvents(themePagedData.event[3]?.event_name)} >{themePagedData?.event[3]?.event_name}</button>
                                                        <button className="Gird5Btn Button__wapper" onClick={() => handleEvents(themePagedData.event[4]?.event_name)} >{themePagedData?.event[4]?.event_name}</button>
                                                        <img src={themePagedData?.event[4]?.event_image} alt="" className={Gird5} onClick={() => handleEvents(themePagedData.event[4]?.event_name)} onError={(e: any) => { e.target.src = themeParkImgsvg; }} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ThemePark__second__conatant">
                                <div className="ThemePark_background_circle_container"></div>
                                <div className={`${ReviewConatinerArab} container Review__conatiner`}>
                                    <Rating name="half-rating-read" className="StarIcon" precision={0.5} value={4.5} readOnly />
                                    <div className="discription"><span>{Themepark_Review_Description}</span></div>
                                    <div className="review__text">- {GoogleReview_Txt}</div>
                                </div>
                            </div>
                            <div className="gradient-div"></div>
                        </div> : ""
                }
            </div>
        </>
    )
}

export default ThemeParkHomePage;