import AliceCarousel from "react-alice-carousel";
import "./ActivitiesCardsCarousel.scss";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
/**Assets */
import card_bg from "../../Assets/Tutor/card_bg.png";
import left from "../../Assets/Tutor/left.svg";
import right from "../../Assets/Tutor/right.svg";
import { useEffect, useState, useRef } from "react";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";
import { useNavigate } from "react-router-dom";
import campIcon from "../../Assets/Activities/camp_icon.svg";
import ptIcon from "../../Assets/Activities/cap.svg";
import WebShareComponent from "../WebShare/WebShare";
import toast from "react-hot-toast";
import { setLoginSignUpOpen } from "../../Redux/Reducer/Reducer";
import { GetSessionStorageData, encryptPassData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { useDispatch } from "react-redux";
import shareIcon from "../../Assets/Activities/share_activity.svg";
import LikeIcon from "../../Assets/Activities/like_activity.svg";
import ActivityFill from "../../Assets/HeartFill/ActivityFill.svg";
import locationIcon from "../../Assets/Tutor/location.svg";
import StarIcon from '@mui/icons-material/Star';
import moment from "moment";
import calendar_ac from "../../Assets/Activities/calendar_ac.svg";
import { Container, Rating } from "@mui/material";
import spacemen from "../../Assets/Global/spacemen.svg";
import { Autocomplete, Tab, Tabs, TextField, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";


function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ActivitiesCardsCarousel = ({ radius, lat, lang, ActivityList, ActivityApiCall }: any) => {

    /**Initial states */
    const [activeIndex, setActiveIndex] = useState(0);
    const [popularList, setPopularList] = useState<any>([]);
    const [activeNext, setactiveNext] = useState<any>(true);
    const [activePrev, setactivePrev] = useState<any>(true);
    const navigate = useNavigate();
    const carouselRef = useRef<any>(null);
    const carouselRefCamp = useRef<any>(null);
    const dispatch = useDispatch();
    const [Tapvalue, setTapValue] = useState(0);
    const { t } = useTranslation();


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTapValue(newValue);
    };

    useEffect(() => {
        // getPopular_TutorList();
    }, []);

    /**Navigate to tutor deyailed view */
    const navigateActiviesDetailedView = (ActiviesDetailes: any) => {
        navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(ActiviesDetailes?.id) + '&type=' + encryptPassData(ActiviesDetailes?.is_service_type), { state: { Activies_Detailes: ActiviesDetailes } });
    }

    /**Navigate to Activity List view */
    const navigateActivityListView = () => {
        navigate(ROUTES.ACTIVITY_LIST_VIEW, {
            state: {
                activities_list: "", search_text: "",
                redius: radius, lat: lat, lang: lang,
            }
        });
    }

    /**Slide previous card */
    const slidePrev = () => {
        if (carouselRef?.current) {
            carouselRef?.current?.slidePrev();
        }
    };

    /**Slide next card */
    const slideNext = () => {
        if (carouselRef?.current) {
            carouselRef?.current?.slideNext();
        }
    };

    /**Slide previous card */
    const slidePrevCamp = () => {
        if (carouselRefCamp?.current) {
            carouselRefCamp?.current?.slidePrev();
        }
    };

    /**Slide next card */
    const slideNextCamp = () => {
        if (carouselRefCamp?.current) {
            carouselRefCamp?.current?.slideNext();
        }
    };

    /**Sync card index on slide */
    const syncActiveIndex = ({ item }: any) => {
        setActiveIndex(item);
    };

    /**Get Popular Tutor List*/
    const getPopular_TutorList = () => {
        axiosInstance
            .get(`${API_END_POINTS.POPULAR_ACTIVITIES_LIST}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    var arr1 = Response?.data?.result?.data?.camp;
                    var arr2 = Response?.data?.result?.data?.personal;
                    var children = [...arr1, ...arr2];
                    setActiveIndex(0);
                    setPopularList(Response?.data?.result?.data);
                }
            })
            .catch(() => { });
    };

    /**Initial viewList for card Data */
    const viewList = popularList?.personal?.map((item: any, i: any) => {
        return (
            <>
                <div className="Activies_card_carousel_wrapper" key={i} onClick={() => navigateActiviesDetailedView(item)}>
                    <div className="Activies_card_wrapper">
                        <img src={ptIcon} alt="" className="cornorIcon" />
                        <img className="card_bg_img" src={item?.profile_image?.length > 0 ? item?.profile_image : card_bg} alt="Learn and Play" />
                        <div className="Activies_card_text">
                            {item?.profile_details?.firstname + " " + item?.profile_details?.lastname}
                        </div>
                    </div>
                </div>
            </>
        )
    });

    /**Initial viewList for card Data */
    const CampList = popularList?.camp?.map((item: any, i: any) => {
        return (
            <>
                <div className="Activies_card_carousel_wrapper" key={i} onClick={() => navigateActiviesDetailedView(item)}>
                    <div className="Activies_card_wrapper">
                        <img src={campIcon} alt="" className="cornorIcon" />
                        <img className="card_bg_img" src={item?.camp_images?.length > 0 ? item?.camp_images[0] : card_bg} alt="Learn and Play" />
                        <div className="Activies_card_text">
                            {item?.camp_session_title}
                        </div>
                    </div>
                </div>
            </>
        )
    });

    popularList?.camp?.length > 10 && (
        <>
            {CampList?.push(
                <div className="activity_card_carousel_exploreAll__wapper"
                    onClick={() => navigateActivityListView()}>
                    <div className="text">Explore all</div>
                    <div className="title">Camps</div>
                </div >
            )}
        </>
    );

    popularList?.personal?.length > 10 && (
        <>
            {viewList?.push(
                <div className="activity_card_carousel_exploreAll__wapper"
                    onClick={() => navigateActivityListView()}>
                    <div className="text">Explore all</div>
                    <div className="title">Personal Triner</div>
                </div >
            )}
        </>
    );

    /** Favourites Api*/
    const handleChangeFavourites = (val: any, id: any, is_service_type: any, campId: any) => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            var payload: any
            if (is_service_type == "camp") {
                payload = {
                    service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.THREE_INT,
                    service_provider: id,
                    is_favourite: !val,
                    camp_session: campId
                }
            } else {
                payload = {
                    service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT,
                    service_provider: id,
                    is_favourite: !val
                }
            }
            axiosInstance
                .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
                .then((Response: any) => {
                    if (Response?.data?.status) {
                        // getPopular_TutorList();
                        ActivityApiCall();
                        toast.success(Response?.data?.message, {
                            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                        });
                    }
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    });
                });
        } else {
            dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
        }
    };

    return (
        <>
            {/* <div className="Activies_card_carousel_container">
                <div className="arrow_nav_tile_contianer">
                    <div className="Activies_carousel_title">
                        Find sports Camps near you
                    </div>
                    <div className="arrow_navs">
                        <img
                            src={left}
                            onClick={slidePrevCamp}
                            alt="Learn and Play"
                            className="left_arrow"
                        />
                        <img
                            src={right}
                            onClick={slideNextCamp}
                            alt="Learn and Play"
                            className="right_arrow"
                        />
                    </div>
                </div>
                <AliceCarousel
                    infinite
                    mouseTracking
                    autoWidth
                    disableButtonsControls
                    items={CampList}
                    disableDotsControls
                    activeIndex={activeIndex}
                    onSlideChanged={syncActiveIndex}
                    animationDuration={2000}
                    animationType="fadeout"
                    autoPlay={false}
                    responsive={{
                        0: { items: 1 },
                        600: { items: 2 },
                        1024: { items: 3 },
                    }}
                    ref={carouselRefCamp}
                />

                <div className="arrow_nav_tile_contianer mt-3">
                    <div className="Activies_carousel_title">
                        Find sports Personal Triner near you
                    </div>
                    <div className="arrow_navs">
                        <img
                            src={left}
                            onClick={slidePrev}
                            alt="Learn and Play"
                            className="left_arrow"
                        />
                        <img
                            src={right}
                            onClick={slideNext}
                            alt="Learn and Play"
                            className="right_arrow"
                        />
                    </div>
                </div>
                <AliceCarousel
                    infinite
                    mouseTracking
                    autoWidth
                    disableButtonsControls
                    items={viewList}
                    disableDotsControls
                    activeIndex={activeIndex}
                    onSlideChanged={syncActiveIndex}
                    animationDuration={2000}
                    animationType="fadeout"
                    autoPlay={false}
                    responsive={{
                        0: { items: 1 },
                        600: { items: 2 },
                        1024: { items: 3 },
                    }}
                    ref={carouselRef}
                />
            </div> */}


            <div className="d-none d-xl-block">
                <div className="row  ">
                    <div className="col-md-6 col-sm-12">
                        <div className="Activies_carousel_title">
                            {t("Find sports Camps near you")}
                        </div>
                        <div className="activities__listView__container mt-3">
                            {ActivityList?.camp?.slice(0, 5)?.map((item: any, i: any) => {
                                const startDate = new Date(item?.start_date);
                                const today = new Date();
                                today.setHours(0, 0, 0, 0);

                                // if (!(startDate < today)) {
                                return (
                                    <>
                                        <div className="activities__list__wapper" key={i} onClick={() => navigateActiviesDetailedView(item)}>
                                            <div className="activities__leftside">
                                                <img src={campIcon} alt="" className="identity_icon" />
                                                <img className="profile_img" src={item?.camp_images.length > 0 ? item?.camp_images[0] : card_bg} alt="activitiesImg"
                                                    onError={(e: any) => { e.target.src = card_bg; }} />
                                            </div>
                                            <div className="activities__rightside">
                                                <div className="activity_top">
                                                    <div className="activities__name">
                                                        <div className="age_group_wapper">
                                                            <span className="name d-block d-lg-none">{item?.camp_session_title?.substring(0, 12) + "..."}</span>
                                                            <span className="name d-none d-md-block">{item?.camp_session_title}</span>
                                                            <span className="age_group_badge" >{t("under")} {item?.max_age}</span>
                                                        </div>
                                                        <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                                                            <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(item?.id) + '&type=' + encryptPassData(item?.is_service_type)}>
                                                                <img src={shareIcon} alt="" className="mb-1" />
                                                            </WebShareComponent>
                                                            {item?.is_favourite ? (<img src={ActivityFill} alt=""
                                                                onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />) : (<img src={LikeIcon} alt=""
                                                                    onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />)}
                                                        </div>
                                                    </div>

                                                    <div className="camp_dates">By {item?.camp_name}</div>

                                                    <div className="activities__location">
                                                        <img src={locationIcon} alt="" />
                                                        <span className="location" title={item?.city}>{item?.city}</span>
                                                    </div>

                                                    <div className="activities__camp_dates">
                                                        <img src={calendar_ac} alt="" className="camp_dates__icon" />
                                                        <div className="camp_dates">{moment(new Date(item?.start_date)).format("MMM DD")} - {moment(new Date(item?.end_date)).format("MMM DD")}</div>
                                                    </div>


                                                </div>
                                                <div className="activities__rating">
                                                    <div className="total_class_wapper">
                                                        <div className="text__contant">{t("AED")} {item?.total_class_fee}</div>
                                                        <div className="total_class_text">{t("for")} {item?.total_class} {t("classes")}</div>
                                                    </div>
                                                    <div className="d-flex flex-row">
                                                        {/* {item?.rating && (<StarIcon className="StarIcon" />)} */}
                                                        <Rating name="half-rating-read" className="StarIcon" value={parseInt(item?.rating)} readOnly />
                                                        <span className="points ms-2">{item?.rating}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div >
                                    </>
                                )
                                // }
                            })}
                        </div>
                    </div>
                    <div className="p-0 col-md-6 col-sm-12 find-sport-personal-wrap">
                        <div className="Activies_carousel_title">
                            {t("Find sports Personal Trainer near you")}
                        </div>
                        <div className="activities__listView__container ">
                            {ActivityList?.personal?.slice(0, 5)?.map((item: any, i: any) => {
                                return (
                                    <>
                                        <div className="activities__list__wapper" key={i} onClick={() => navigateActiviesDetailedView(item)}>
                                            <div className="activities__leftside">
                                                <img src={ptIcon} alt="" className="identity_icon" />
                                                <img className="profile_img" src={nullUndefinedEmptyCheck(item?.profile_image) ? item?.profile_image : card_bg} alt="activitiesImg"
                                                    onError={(e: any) => { e.target.src = card_bg; }} />
                                            </div>
                                            <div className="activities__rightside">
                                                <div className="activity_top">
                                                    <div className="activities__name">
                                                        <span className="name d-block d-lg-none">{item?.type_camp ? item?.campname?.substring(0, 12) + "..." : (item?.profile_details?.firstname + item?.profile_details?.lastname)?.substring(0, 12) + "..."} </span>
                                                        <span className="name d-none d-md-block">{item?.type_camp ? item?.campname : item?.profile_details?.firstname + " " + item?.profile_details?.lastname}</span>
                                                        {/* <span className="name">{item?.type_camp ? item?.campname : item?.profile_details?.firstname + item?.profile_details?.lastname}</span> */}
                                                        <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                                                            <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(item?.id) + '&type=' + encryptPassData(item?.is_service_type)}>
                                                                <img src={shareIcon} alt="" className="mb-1" />
                                                            </WebShareComponent>
                                                            {item?.is_favourite ? (<img src={ActivityFill} alt=""
                                                                onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />) : (<img src={LikeIcon} alt=""
                                                                    onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />)}
                                                        </div>
                                                    </div>

                                                    <div className="activities__location">
                                                        <img src={locationIcon} alt="" />
                                                        <span className="location" title={item?.city}>{item?.city}</span>
                                                    </div>

                                                    <div className="Slots_activities">
                                                        <div className="lable"><img src={item?.trainer_activity?.length > 0 ? item?.trainer_activity[0]?.category_icon : ""} alt="" />{item?.trainer_activity?.length > 0 ? item?.trainer_activity[0]?.activites : ""}</div>
                                                    </div>

                                                    {/* <div className="Slots_activities">
                                                    <div className='Slots'>Slots:</div>
                                                    {item?.is_slots_available && (<div className='SlotsYes'>Yes</div>)}
                                                </div> */}

                                                </div>

                                                <div className="activities__rating">
                                                    <div className="text__contant">{t("AED")} {item?.trainer_activity?.length > 0 ? item?.trainer_activity[0]?.expected_fee : ""}</div>
                                                    <div className="d-flex flex-row">
                                                        {/* {item?.rating && (<StarIcon className="StarIcon" />)} */}
                                                        <Rating name="half-rating-read" className="StarIcon" value={parseInt(item?.rating)} readOnly />
                                                        <span className="points ms-2">{item?.rating}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div >
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>


            <div className="row  d-lg-block d-xl-none">
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        marginTop: "2rem",
                    }}
                >
                    <Tabs
                        className="Activities__Taps"
                        value={Tapvalue}
                        centered
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        textColor="secondary"
                        indicatorColor="secondary"
                    >
                        <Tab label="Camps" {...a11yProps(0)} />
                        <Tab label="Personal trainers" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <div className="activities__listView__container ">
                    <TabPanel value={Tapvalue} index={0}>
                        {ActivityList?.camp?.map((item: any, i: any) => {

                            const startDate = new Date(item?.start_date);
                            const today = new Date();
                            today.setHours(0, 0, 0, 0);

                            // if (!(startDate < today)) {

                            return (
                                <>
                                    <div className="activities__list__wapper " key={i} onClick={() => navigateActiviesDetailedView(item)}>
                                        <div className="activities__leftside">
                                            <img src={campIcon} alt="" className="identity_icon" />
                                            <img className="profile_img" src={item?.camp_images.length > 0 ? item?.camp_images[0] : card_bg} alt="activitiesImg"
                                                onError={(e: any) => { e.target.src = card_bg; }} />
                                        </div>
                                        <div className="activities__rightside">
                                            <div className="activity_top">
                                                <div className="activities__name">
                                                    <div className="age_group_wapper">
                                                        <span className="name d-block d-lg-none">{item?.camp_session_title?.substring(0, 12) + "..."}</span>
                                                        <span className="name d-none d-md-block">{item?.camp_session_title}</span>
                                                        <span className="age_group_badge" >{t("under")}{item?.max_age}</span>
                                                    </div>
                                                    <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                                                        <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(item?.id) + '&type=' + encryptPassData(item?.is_service_type)}>
                                                            <img src={shareIcon} alt="" className="mb-1" />
                                                        </WebShareComponent>
                                                        {item?.is_favourite ? (<img src={ActivityFill} alt=""
                                                            onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />) : (<img src={LikeIcon} alt=""
                                                                onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />)}
                                                    </div>
                                                </div>

                                                <div className="camp_dates">By {item?.camp_name}</div>

                                                <div className="activities__location">
                                                    <img src={locationIcon} alt="" />
                                                    <span className="location" title={item?.city}>{item?.city}</span>
                                                </div>

                                                <div className="activities__camp_dates">
                                                    <img src={calendar_ac} alt="" className="camp_dates__icon" />
                                                    <div className="camp_dates">{moment(new Date(item?.start_date)).format("MMM DD")} - {moment(new Date(item?.end_date)).format("MMM DD")}</div>
                                                </div>


                                            </div>
                                            <div className="activities__rating">
                                                <div className="total_class_wapper">
                                                    <div className="text__contant">{t("AED")} {item?.total_class_fee}</div>
                                                    <div className="total_class_text">{t("for")} {item?.total_class} {t("classes")}</div>
                                                </div>
                                                <div className="d-flex flex-row">
                                                    {/* {item?.rating && (<StarIcon className="StarIcon" />)} */}
                                                    <Rating name="half-rating-read" className="StarIcon" value={parseInt(item?.rating)} readOnly />
                                                    <span className="points ms-2">{item?.rating}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div >
                                </>
                            )
                            // }
                        })}
                        {ActivityList?.camp?.length === 0 && (
                            <>
                                <div className="No__data__contant">
                                    <img src={spacemen} alt="" />
                                    <div className="text">{t("No Data Found")}</div>
                                </div>
                            </>
                        )}
                    </TabPanel>
                    <TabPanel value={Tapvalue} index={1} >
                        {ActivityList?.personal?.map((item: any, i: any) => {
                            return (
                                <>
                                    <div className="activities__list__wapper" key={i} onClick={() => navigateActiviesDetailedView(item)}>
                                        <div className="activities__leftside">
                                            <img src={ptIcon} alt="" className="identity_icon" />
                                            <img className="profile_img" src={nullUndefinedEmptyCheck(item?.profile_image) ? item?.profile_image : card_bg} alt="activitiesImg"
                                                onError={(e: any) => { e.target.src = card_bg; }} />
                                        </div>
                                        <div className="activities__rightside">
                                            <div className="activity_top">
                                                <div className="activities__name">
                                                    <span className="name d-block d-lg-none">{item?.type_camp ? item?.campname?.substring(0, 12) + "..." : (item?.profile_details?.firstname + item?.profile_details?.lastname)?.substring(0, 12) + "..."} </span>
                                                    <span className="name d-none d-md-block">{item?.type_camp ? item?.campname : item?.profile_details?.firstname + " " + item?.profile_details?.lastname}</span>

                                                    {/* <span className="name">{item?.type_camp ? item?.campname : item?.profile_details?.firstname + item?.profile_details?.lastname}</span> */}
                                                    <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                                                        <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(item?.id) + '&type=' + encryptPassData(item?.is_service_type)}>
                                                            <img src={shareIcon} alt="" className="mb-1" />
                                                        </WebShareComponent>
                                                        {item?.is_favourite ? (<img src={ActivityFill} alt=""
                                                            onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />) : (<img src={LikeIcon} alt=""
                                                                onClick={() => handleChangeFavourites(item.is_favourite, item?.user, item?.is_service_type, item?.id)} />)}
                                                    </div>
                                                </div>

                                                <div className="activities__location">
                                                    <img src={locationIcon} alt="" />
                                                    <span className="location" title={item?.city}>{item?.city}</span>
                                                </div>

                                                <div className="Slots_activities">
                                                    <div className="lable"><img src={item?.trainer_activity?.length > 0 ? item?.trainer_activity[0]?.category_icon : ""} alt="" />{item?.trainer_activity?.length > 0 ? item?.trainer_activity[0]?.activites : ""}</div>
                                                </div>

                                                {/* <div className="Slots_activities">
                                                    <div className='Slots'>Slots:</div>
                                                    {item?.is_slots_available && (<div className='SlotsYes'>Yes</div>)}
                                                </div> */}

                                            </div>

                                            <div className="activities__rating">
                                                <div className="text__contant">{t("AED")} {item?.trainer_activity?.length > 0 ? item?.trainer_activity[0]?.expected_fee : ""}</div>
                                                <div className="d-flex flex-row">
                                                    {/* {item?.rating && (<StarIcon className="StarIcon" />)} */}
                                                    <Rating name="half-rating-read" className="StarIcon" value={parseInt(item?.rating)} readOnly />
                                                    <span className="points ms-2">{item?.rating}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div >
                                </>
                            )
                        })}
                        {ActivityList?.personal?.length === 0 && (
                            <>
                                <div className="No__data__contant">
                                    <img src={spacemen} alt="" />
                                    <div className="text">{t("No Data Found")}</div>
                                </div>
                            </>
                        )}
                    </TabPanel>
                </div>
            </div>


        </>
    )
}

export default ActivitiesCardsCarousel;