import { Navigate, Outlet } from "react-router-dom";
import { AUTHENTICATION, ROUTES } from "../../Constants/Constants";
import { GetSessionStorageData } from "../Helpers/Helper";

const PublicRoutesCheck = () => {
  /**Get access token from session storage */
  const token = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);

  return (
    <>
      {" "}
      <Outlet />
    </>
  );
};

export default PublicRoutesCheck;
