import tick from "../../../Assets/Tutor/tick.svg";
import vector from "../../../Assets/Tutor/vector.svg";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import "./TutorsPaymentSuccess.scss";
import { Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES } from "../../../Constants/Constants";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MobileCalenderIcon from "../../../Assets/Tutor/tutorPaymentCalIcon.svg";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { SetSessionStorageData, nullUndefinedEmptyCheck } from "../../../Utilities/Helpers/Helper";
import moment from "moment";
import MySVGComponent from "../../../Assets/Asserts-Common/MySVGComponent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TutorsPaymentSuccess = () => {
  const route = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [CourseOpen, setCourseOpen] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Calendar")
    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
    navigate(ROUTES.CHILDCALENDER);
  };

  // An array with one date is considered sorted and continuous.
  const isDateArray = (arr: any) => {
    if (arr.length <= 1) {
      return true;
    }

    for (let i = 1; i < arr.length; i++) {
      const currentDate: any = new Date(arr[i]);
      const previousDate: any = new Date(arr[i - 1]);

      // Check if the current date is not one day after the previous date
      if (currentDate - previousDate !== 24 * 60 * 60 * 1000) {
        return false; // Dates are not continuous.
      }

      // Check if the current date is greater than or equal to the previous date
      if (currentDate <= previousDate) {
        return false; // Dates are not sorted in ascending order.
      }
    }
    return true; // Dates are sorted in ascending order and continuous.
  };

  function formatDatesWithSpaces(dateArray: any) {
    const formattedDates = dateArray.map((dateStr: any) => {
      const [year, month, day] = dateStr.split("-");
      return `${day}/${month}/${year}`;
    });

    return formattedDates.join(", ");
  }

  const handleNavigate = () => {
    // URL of the .ics file you want to download
    const icsFileUrl = nullUndefinedEmptyCheck(route?.state?.calender_link)
      ? route?.state?.calender_link[0]
      : "";

    // Create an anchor element
    const anchor = document.createElement("a");
    anchor.href = icsFileUrl;
    anchor.download = "(L & P)Calender.ics";

    // Trigger a click event on the anchor element
    anchor.click();
    handleClose();
  };

  const [courseIcon, setCourseIcon] = useState('');

  useEffect(() => {
    const SSetCourseIcon = route?.state?.ProfileData?.tutor_subjects[0]?.coursecategory_icon;
    setCourseIcon(SSetCourseIcon);
  }, [route?.state?.ProfileData?.tutor_subjects[0]?.coursecategory_icon]);

  var ArrayData = route?.state?.orderDetailes?.map((data: any) => {
    return data?.title + isDateArray(data?.dates) ? "Sessions:" + data?.dates?.length + "(Date From:" +
      moment(data?.dates[0]).format("DD/MM/YYYY") + '-' + 'To:' + moment(data?.dates[data?.dates?.length - 1]).format("DD/MM/YYYY") + ")"
      : "Sessions:" + data?.dates?.length + 'Date' + formatDatesWithSpaces(data?.dates)
  });

  const ReceiptPayload = {
    session_title: route?.state?.free_trial ? route?.state?.free_trial_data?.title : route?.state?.orderDetailes[0]?.title,
    payment_total: route?.state?.free_trial ? '0.00' : route?.state?.PaymentTotal,
    course_detail: route?.state?.orderDetailes,
    start_date: ArrayData,
    end_date: '',
    tutor_name: route?.state?.free_trial ? route?.state?.free_trial_data?.name : route?.state?.ProfileData?.profile_details?.firstname + route?.state?.ProfileData?.profile_details?.lastname,
    tutor_image: route?.state?.free_trial ? route?.state?.free_trial_data?.profile_image : route?.state?.ProfileData?.profile_image,
    transaction_id: '485983593829238',
    current_datetime: moment().format("MMMM Do, YYYY h:mm a"),
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const Left_border_radius = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "border_radius_eng" : "border_radius_arb";

  return (
    <React.Fragment>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <div className="tutorsuccess-container">
          <div className="paymentsuccessful__layout">
            <div className={`paymentsuccessful-left ${Left_border_radius}`}>
              <div className="alignment3">
                {/* <img src={tick} alt="" className="img-tick" /> */}
                <span className="img-tick" ><MySVGComponent checkmarkStrokeColor="#178FFF" /></span>
                <label htmlFor="" className="payment-text">
                  {t("Payment Successful")} !!
                </label>
              </div>
            </div>
            <div className="paymentsuccessfull-right">
              {route?.state?.free_trial ? (
                <div className="topContant">
                  <div className="left__contant">
                    {route?.state?.free_trial_data?.title}
                  </div>
                  <div className="right__contant">{t("AED")} 0.00</div>
                </div>
              ) : (
                <div className="topContant">
                  <div className="left__contant">
                    <img src={courseIcon} width="34px" height="34px" alt="CourseIcon" />
                    {nullUndefinedEmptyCheck(route?.state?.orderDetailes)
                      ? route?.state?.orderDetailes[0]?.title
                      : ""}
                  </div>
                  <div className="right__contant">
                    {t("AED")} {route?.state?.PaymentTotal}
                  </div>
                </div>
              )}

              <div className="course__wapper">
                <div className="course-detail-show">
                  <div className="title">{t("Course Details")}</div>
                  <KeyboardArrowDownIcon
                    className={CourseOpen ? "Icon" : "IconInActive"}
                    onClick={() => setCourseOpen(!CourseOpen)}
                  />
                </div>
                {CourseOpen && (
                  <>
                    {route?.state?.free_trial ? (
                      <>
                        <div className="course__Name">
                          {route?.state?.free_trial_data?.title}
                        </div>
                        <div className="Sessions">
                          {t("Sessions")}: 1 (Date From:{" "}
                          {route?.state?.free_trial_data?.session_slot_date} To:{" "}
                          {route?.state?.free_trial_data?.session_slot_enddate})
                        </div>

                        <div className="profile__wapper">
                          <img
                            src={
                              nullUndefinedEmptyCheck(
                                route?.state?.free_trial_data?.profile_image
                              )
                                ? route?.state?.free_trial_data?.profile_image
                                : tutor_img
                            }
                            alt="" onError={(e: any) => { e.target.src = tutor_img; }}
                          />
                          <div className="profile__name">
                            {route?.state?.free_trial_data?.name}{" "}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="booked__detailes_wapper">
                          {route?.state?.orderDetailes?.map((data: any) => {
                            return (
                              <>
                                <div className="course__Name">{data?.title}</div>
                                {isDateArray(data?.dates) ? (
                                  <div className="Sessions">
                                    {t("Sessions")}: {data?.dates?.length} (Date From:{" "}
                                    {moment(data?.dates[0]).format("DD/MM/YYYY")} -
                                    To:{" "}
                                    {moment(
                                      data?.dates[data?.dates?.length - 1]
                                    ).format("DD/MM/YYYY")}
                                    )
                                  </div>
                                ) : (
                                  <div className="Sessions">
                                    {t("Sessions")}: {data?.dates?.length} (Date{" "}
                                    {formatDatesWithSpaces(data?.dates)})
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}

                    <div className="profile__wapper">
                      <img
                        src={
                          nullUndefinedEmptyCheck(
                            route?.state?.ProfileData?.profile_image
                          )
                            ? route?.state?.ProfileData?.profile_image
                            : tutor_img
                        }
                        alt="" onError={(e: any) => { e.target.src = tutor_img; }}
                      />
                      <div className="profile__name">
                        {route?.state?.ProfileData?.profile_details?.firstname}{" "}
                        {route?.state?.ProfileData?.profile_details?.lastname}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="transaction-container">
                <label className="td-text">{t("Transaction Details")}</label>
                <div className="transaction-deatiles__wapper">
                  <div className="ti-container">
                    <label>{t("Transaction ID")}</label>
                    <span>485983593829238</span>
                  </div>
                  <div className="t12-container">
                    <label>{t("Date & Time")}</label>
                    <span>{moment().format("MMMM Do, YYYY h:mm a")}</span>
                  </div>
                </div>
                <div className="display-css">
                  {/* <button className="btn-share">
                    <ShareOutlinedIcon className="share__icon" />
                    <span>Share Receipt</span>
                  </button> */}
                  <div></div>
                  <button
                    className="btn-done"
                    onClick={() => handleClickOpen()}
                  >
                    {t("Done")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {open && (
          <div className="overlay-ModelBackround">
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                  sx={{
                    position: "absolute",
                    top: 6,
                    right: 13,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div className="Tutor__Payment__Success__Pop">
                  <div className="Payment__Pop__wapper">
                    <img src={MobileCalenderIcon} alt="" />
                    <div className="text">{t("Add booking to your calendar?")}</div>
                  </div>
                  <div className="Payment__bottom__btn">
                    <button className="backBtn" onClick={handleClose}>
                      {t("Cancel")}
                    </button>
                    <button
                      className="SuccessBtn"
                      onClick={() => handleNavigate()}
                    >
                      {t("Okay")}
                    </button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </Container>
    </React.Fragment >
  );
};

export default TutorsPaymentSuccess;
