import "./TutorHubDocuments.scss";
import DownloadImg from "../../Assets/TutorHub/DownloadImg.svg";
import TutorHubDocument_Img from "../../Assets/TutorHub/TutorHubDocument.svg";
import Pdf_Img from "../../Assets/Global/drive.svg";
import { API_END_POINTS, GLOBAL_CONSTANTS } from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { useEffect, useState } from "react";
import { store } from "../../Redux/Store/Store";
import { setLoader } from "../../Redux/Reducer/Reducer";
import moment from "moment";
import { useTranslation } from "react-i18next";

const TutorHubDocuments = ({ getData, type }: any) => {
  const [DocumentsList, setDocumentsList] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getTutorDocumentsData();
  }, []);

  /**Get Tutor Hub Documents List*/
  const getTutorDocumentsData = () => {
    axiosInstance
      .get(
        `${API_END_POINTS.HUB_DOCUMENT_LIST +
        getData?.service_provider_id +
        "&booking_id=" +
        getData?.id
        }`
      )
      .then((Response: any) => {
        if (Response?.data?.status) {
          setDocumentsList(Response?.data?.result?.data);
        }
      })
      .catch(() => { });
  };

  const DownloadPdf = (data: any) => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    fetch(data, {
      method: "GET",
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const urlParts = data?.split('/');
        const fileName = urlParts[urlParts?.length - 1];
        a.style.display = "none";
        a.href = url;
        a.download = fileName; // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setTimeout(() => {
          store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
        }, 500);
      });
  };

  const PdffileName = (name: any) => {
    const urlParts = name?.split('/');
    return urlParts[urlParts?.length - 1];
  }

  return (
    <>
      <div className="TutorHubDocuments___container">
        <div className="Header__wapper">
          <img src={TutorHubDocument_Img} alt="" />
          <div className="title">{t("Documents")}</div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            {DocumentsList.length > 0 && (
              <div className="Show__time">
                {moment(DocumentsList[0]?.date).format("MMM Do YYYY")}
              </div>
            )}
          </div>

          {type === GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT && (
            <>
              {DocumentsList?.map((data: any, i: any) => {
                return (
                  <>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                      <div className="Pdf__container">
                        <div className="left__contant">
                          <div className="tutor__img__wapper">
                            <img src={Pdf_Img} alt="" />
                          </div>
                          <div className="d-flex flex-column">
                            <div className="Black__text">{PdffileName(data?.file_link)}</div>
                            <div className="name__text">{data?.format}</div>
                          </div>
                        </div>
                        <img
                          src={DownloadImg}
                          className="DownloadImg"
                          onClick={() => DownloadPdf(data?.file_link)}
                          alt=""
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          )}
          {type === GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT && (
            <>
              {DocumentsList?.map((data: any, i: any) => {
                return (
                  <>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12" key={i}>
                      <div className="Activity__Pdf__container">
                        <div className="left__contant">
                          <div className="tutor__img__wapper">
                            <img src={Pdf_Img} alt="" />
                          </div>
                          <div className="d-flex flex-column">
                            <div className="Black__text">{data?.name}</div>
                            <div className="name__text">{data?.format}</div>
                          </div>
                        </div>
                        <img
                          src={DownloadImg}
                          className="DownloadImg"
                          onClick={() => DownloadPdf(data?.file_link)}
                          alt=""
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TutorHubDocuments;
