import "./ActivitiesCalendarView.scss";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ACCOUNTS, API_END_POINTS, AUTHENTICATION, DayTotalHours, GLOBAL_CONSTANTS, ROUTES } from '../../../Constants/Constants';
import { format } from "date-fns";
import { axiosInstance } from '../../../Utilities/Interceptor/Interceptor';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from 'moment';
import Checkbox from '@mui/material/Checkbox';
import activitiesIcon from "../../../Assets/Activities/activitiesIcon.svg";
import back_img from "../../../Assets/Activities/back_img.svg";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GetSessionStorageData, SetSessionStorageData, nullUndefinedEmptyCheck } from '../../../Utilities/Helpers/Helper';
import defaultProfile from "../../../Assets/Authentication/defaultProfile.svg";
import { Dialog, DialogContent, DialogTitle, stackClasses } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import TimeClock from "../../../Assets/Activities/TimeClock.svg";
import TimeClock from "../../../Assets/Activities/clock-white.svg";
import axios from "axios";
import { setLoginSignUpOpen } from "../../../Redux/Reducer/Reducer";
import { useDispatch, useSelector } from "react-redux";
import Discount_symble_blue from "../../../Assets/Global/Discount_activity.svg";
import Discount_Symbole from "../../../Assets/Global/Discount_Symbole.svg";
import toast from "react-hot-toast";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import add from "../../../Assets/Header/add.svg";
import MobileCalenderIcon from "../../../Assets/Tutor/tutorPaymentCalIcon.svg";
import { useTranslation } from "react-i18next";

function renderEventContent(eventInfo: any) {
    return (
        <>
        </>
    )
}

const ActivitiesCalendarView = () => {
    const route = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [calendarData, setCalendarData] = useState<any>([])
    const [PassClickData, setPassClickData] = useState<any>([])
    const [DynamicDate, setDynamicDate] = useState<any>(new Date())
    const [selectedCheckBox, setSelectedCheckBox] = useState<any>([]);
    const [AvailableSlot, setAvailableSlot] = useState<any>([]);
    const [childProfileData, setChildProfileData] = useState<any>([]);
    const [parentProfileData, setParentProfileData] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState<any>([]);
    const [selectedDate, setSelectedDate] = useState<any>('');
    const [open, setOpen] = useState(false);
    const [PassErrorMsg, setPassErrorMsg] = useState('');
    const [CheckAvailabeTime, setCheckAvailabeTime] = useState(true);
    const [ChildSelectOpen, setChildSelectOpen] = useState(false);
    const [AuthenticationOpen, setAuthenticationOpen] = useState(false);
    const [DiscountList, setDiscountList] = useState([]);
    const [DiscountArray, setDiscountArray] = useState<any>([]);
    const [DiscountSelect, setDiscountSelect] = useState<any>('');
    const [DiscountSelectOpen, setDiscountSelectOpen] = useState<any>(false);
    const [FinalPromtCheck, setFinalPromtCheck] = useState<any>(false);
    const [EmptyMesg, setEmptyMesg] = useState<any>(false);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [ReschedulePopOpen, setReschedulePopOpen] = useState(false);

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const calendarHeight = viewportWidth <= 720 ? 280 : 500;

    const handleClose = () => {
        setOpen(false);
        navigate(-1);
        setPassErrorMsg('')
    };

    const handleChildSelectClose = () => {
        setChildSelectOpen(false);
    };

    const handleCloseReschedule = () => {
        setReschedulePopOpen(false);
        SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "Calendar")
        SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
        navigate(ROUTES.CHILDCALENDER);
    };

    const handleNavigateReschedule = () => {
        let payload = {
            booking_id: route?.state?.person?.booking_id,
            booked_time_slot_id: route?.state?.person?.id,
            child_id: selectedOption?.is_primary ? null : selectedOption?.id.toString(),
            teach_service_provider_id: selectedCheckBox[0]?.teach_service_provider_id.toString(),
            sp_user_id: selectedCheckBox[0]?.user_id,
            booked_email: GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL),
            booked_phone_number: GetSessionStorageData(AUTHENTICATION.SHARED.MOBILE),
            time_slot_ids: [
                selectedCheckBox[0]?.id.toString()
            ],
            booked_slot_detail: [
                {
                    time_slot_id: selectedCheckBox[0]?.id,
                    start_time: selectedCheckBox[0]?.start_time,
                    end_time: selectedCheckBox[0]?.end_time,
                    slot_price: selectedCheckBox[0]?.slot_price,
                    start_date: moment(selectedCheckBox[0]?.start).format(
                        GLOBAL_CONSTANTS.INITIAL_VALUES.DATE_FORMAT),
                    session_slot_id: selectedCheckBox[0]?.session_slot,
                    session_id: selectedCheckBox[0]?.session_id
                }
            ],
            course_type: "offline",
            free_session: false,
            is_primary: selectedOption?.is_primary ? true : false,
            parent_id: selectedOption?.is_primary ? selectedOption?.id : null
        }
        axiosInstance
            .put(`${API_END_POINTS.TUTOR_RESCHEDULE}`, payload)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    toast.success(Response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
                    });
                    // URL of the .ics file you want to download
                    const icsFileUrl = nullUndefinedEmptyCheck(Response?.data?.result.data)
                        ? Response?.data?.result.data[0]
                        : "";
                    const anchor = document.createElement("a");
                    anchor.href = icsFileUrl;
                    anchor.download = "(L & P)Calender.ics";
                    anchor.click();
                    handleCloseReschedule();
                }
            })
            .catch((error) => { });
    };

    useEffect(() => {
        getSessionList();
        if (!route?.state?.is_reschedule) {
            get_Discount_List();
        }
    }, [])

    useEffect(() => {
        if (nullUndefinedEmptyCheck(selectedCheckBox)) {
            // Find the object with 'total_slots' equal to 'selectCount'
            const selectedObject: any = DiscountList.find((item: any) => item?.buy_slots === selectedCheckBox?.length);
            if (nullUndefinedEmptyCheck(selectedObject) && DiscountSelect !== selectedObject?.id) {
                if (DiscountArray.includes(selectedObject)) {
                    setDiscountArray(
                        DiscountArray.filter((item: any) => item?.id !== selectedObject?.id)
                    );
                } else {
                    setDiscountArray([...DiscountArray, selectedObject]);
                }
                setDiscountSelectOpen(true)
            }
        }
    }, [DiscountList, selectedCheckBox])

    // useEffect(() => {
    //     if (nullUndefinedEmptyCheck(selectedCheckBox)) {
    //         const selectedObject: any = DiscountList.find((item: any) => item?.total_slots <= selectedCheckBox?.length);
    //         if (nullUndefinedEmptyCheck(selectedObject)) {
    //             setDiscountSelect(selectedObject?.id)
    //         }
    //     }
    // }, [selectedCheckBox])

    useEffect(() => {
        if (nullUndefinedEmptyCheck(DiscountSelect)) {
            var Array: any = DiscountList?.filter((item: any) => item?.id === DiscountSelect)
            setDiscountArray(Array);
        }
    }, [DiscountSelect, DiscountList])

    /**Get Discount List*/
    const get_Discount_List = () => {
        axiosInstance
            .get(`${API_END_POINTS.DISCOUNT_LIST + "?trainer_activity_id=" + route?.state?.activity_sesstion_id}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    setDiscountList(Response?.data?.result?.data)
                }
            })
            .catch(() => { });
    };

    /** Navigate to CheckOut Page*/
    const navigateCheckOut = () => {
        if (!route?.state?.is_reschedule) {
            var navigateArray: any = [];
            AvailableSlot?.filter((item: any) => {
                selectedCheckBox?.map((data: any) => {
                    if (data?.id == item?.id) {
                        navigateArray.push(item)
                    }
                })
            })
            if (selectedCheckBox.length > 0) {
                var transformedData: any = [];
                // Iterate through the input response and transform each item
                navigateArray.forEach((item: any) => {
                    transformedData?.push({
                        start_time: item.start_time,
                        end_time: item.end_time,
                        start_date: moment(item.start).format(
                            GLOBAL_CONSTANTS.INITIAL_VALUES.DATE_FORMAT
                        ),
                    });
                });
                let payload = {
                    is_primary: selectedOption?.is_primary ? true : false,
                    parent_id: selectedOption?.is_primary ? selectedOption?.id : null,
                    child_id: selectedOption?.is_primary ? null : selectedOption?.id.toString(),
                    booked_slot_detail: transformedData,
                    free_session: false,
                    teach_service_provider_id: navigateArray[0]?.teach_service_provider_id?.toString(),
                    sp_user_id: navigateArray[0]?.user_id,
                }
                axiosInstance
                    .post(`${API_END_POINTS.TUTOR_BOOKING_VALIDATION}`, payload)
                    .then((Response: any) => {
                        if (Response?.data?.status) {
                            navigate(ROUTES.ACTIVITY_CHECKOUT, {
                                state: {
                                    activities_checkOut: navigateArray, childProfileData: selectedOption, person: route?.state?.person, camp_book_session: false,
                                    activity_sesstion_id: route?.state?.activity_sesstion_id,
                                    discount_id: DiscountSelect,
                                }
                            })
                        }
                    })
                    .catch((error) => { });
            }
        } else {
            // setChildSelectOpen(true);
            if (selectedCheckBox?.length === 1) {
                setReschedulePopOpen(true);
            } else {
                toast.error('Select Only One Session to Reschedule', {
                    position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
                });
            }
        }
    }

    /** handleCheckbox*/
    const handleCheckbox = (option: any) => {
        if (selectedCheckBox.includes(option)) {
            setSelectedCheckBox(
                selectedCheckBox.filter((item: any) => item?.id !== option?.id)
            );
        } else {
            if (route?.state?.is_reschedule) {
                setSelectedCheckBox([option]);
            } else {
                setSelectedCheckBox([...selectedCheckBox, option]);
            }
        }
    };

    /**Calendar Data binding */
    const events = AvailableSlot?.map((data: any) => {
        return {
            start: new Date(data?.start),
            end: new Date(data?.end),
            id: data?.id,
            // display: "background",
            color: 'red',
            backgroundColor: "#178FFF",
            title: data?.title,
        }
    })

    /**Get CalendarData Api call*/
    const getSessionList = () => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            getProfileList();
            if (route?.state?.camp) {
                axios
                    .get(`${process.env.REACT_APP_BASE_BACKEND_API_URL + API_END_POINTS.ACTIVITIES_SESSIONLIST_CAMP + route?.state?.activity_sesstion_id}`, {
                        headers: {
                            Authorization: "Bearer " + accessToken,
                        },
                    })
                    .then((Response: any) => {
                        if (Response?.data?.status) {
                            var Arr: any = []
                            setCalendarData(Response?.data?.result?.data)
                            Response?.data?.result?.data?.map((item: any) => {
                                return Arr.push(...item.available_slots)
                            })
                            setAvailableSlot(Arr);
                        }
                    })
                    .catch((error) => {
                        if (error?.response?.data?.status == false) {
                            setPassErrorMsg(error?.response?.data.message)
                            setOpen(true);
                        }
                    });
            } else {
                axios
                    .get(`${process.env.REACT_APP_BASE_BACKEND_API_URL + API_END_POINTS.ACTIVITIES_SESSIONLIST_PERSONAL + route?.state?.activity_sesstion_id}`, {
                        headers: {
                            Authorization: "Bearer " + accessToken,
                        },
                    })
                    .then((Response: any) => {
                        if (Response?.data?.status) {
                            var Arr: any = []
                            setCalendarData(Response?.data?.result?.data)
                            Response?.data?.result?.data?.map((item: any) => {
                                return Arr.push(...item.available_slots)
                            })
                            // const currentDateTime = new Date();
                            // var findDate = Arr?.filter((item: any) => new Date(item?.start) > currentDateTime)
                            // Get the current date
                            const currentDate = new Date();
                            // Subtract one day from the current date to get the date for the day before
                            const beforeDate = new Date(currentDate);
                            beforeDate.setDate(currentDate.getDate() - 1);
                            // Extract the date part (without time) from the before date
                            const beforeDateWithoutTime = new Date(beforeDate.getFullYear(), beforeDate.getMonth(), beforeDate.getDate());
                            const findDate = Arr?.filter((item: any) => {
                                const itemDate = new Date(item?.start);
                                const itemDateWithoutTime = new Date(itemDate.getFullYear(), itemDate.getMonth(), itemDate.getDate());
                                return itemDateWithoutTime >= beforeDateWithoutTime;
                            });
                            setAvailableSlot(findDate);
                        }
                    })
                    .catch((error) => {
                        if (error?.response?.data?.status == false) {
                            setPassErrorMsg(error?.response?.data.message)
                            setOpen(true);
                        }
                    });
            }
        } else {
            setAuthenticationOpen(true);
        }
    };

    /**Get profile data */
    const getProfileList = () => {
        axiosInstance
            .get(`${API_END_POINTS.PROFILE_LIST}`)
            .then((profileListResponse: any) => {
                var selectArray: any = [];
                if (
                    profileListResponse.data[AUTHENTICATION.SHARED.STATUS] ===
                    AUTHENTICATION.SHARED.TRUE
                ) {
                    setParentProfileData(profileListResponse.data.result.data.parent);
                    setChildProfileData(profileListResponse?.data?.result?.data?.child);
                    profileListResponse.data.result.data.parent?.map((item: any) =>
                        selectArray.push(item)
                    );
                    profileListResponse?.data?.result?.data?.child?.map((item: any) =>
                        selectArray.push(item)
                    );
                    var SelectedArr = selectArray?.filter(
                        (item: any) => item.user == route?.state?.person?.booked_to || item.child == route?.state?.person?.booked_to
                    );
                    if (route?.state?.is_reschedule) {
                        setSelectedOption(SelectedArr[0]);
                    } else {
                        setSelectedOption(profileListResponse?.data?.result?.data?.parent[0]);
                    }
                }
            })
            .catch(() => { });
    };

    /**onclick Function to get particular calender data*/
    const handleEventClick = (eventInfo: any) => {
        setDynamicDate(new Date(eventInfo?.event?.start))
        const filteredEvents = AvailableSlot?.filter((item: any) => {
            return moment(item?.start).format("YYYY-MM-DD") === moment(eventInfo?.event?.start).format("YYYY-MM-DD")
        });
        setPassClickData(filteredEvents)

    };
    const dayCellClassNames = (data: any) => {

    }
    const dayCellContent = (arg: any) => {
        // Check if the current day belongs to the custom date's column
        // if (arg.date.getDate() == DynamicDate.getDate()) {
        //     return (
        //         <div className='dayCellActivities__selected'>
        //             {arg.dayNumberText}
        //         </div>
        //     );
        // }

        return (
            <div className='dayCellActivities__Unselected'>
                {arg.dayNumberText}
            </div>
        );
    };

    const dayHeaderContent = (arg: any) => {
        return (
            <div translate="no" className='custom-day-header'>
                {arg.text}
            </div>
        );
    };

    const dayCellClassName = (info: any) => {

        let paymentcheck = events?.find(
            (values: any) =>
                moment(values.start).format("YYYY-MM-DD") ===
                moment(info.date).format("YYYY-MM-DD")
        );
        let selecedcheck = selectedCheckBox?.find(
            (values: any) =>
                moment(values.start).format("YYYY-MM-DD") ===
                moment(info.date).format("YYYY-MM-DD")
        );
        if (selecedcheck) {
            return ["custom-background_Activities_selecedcheck"];
        } else if (paymentcheck) {
            return ["custom-background_Activities"];
        }
        return ["disabled-date"];
    }

    /**onclick Function to get particular child data*/
    const handleChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    // Handle the date selection event here
    const handleDateSelect = (selectInfo: any) => {
        // console.log(selectInfo, "selectInfo")
        // const selectedDate = selectInfo.startStr;
        const selectedDate: any = AvailableSlot?.filter((item: any) => {
            return moment(item?.start).format("YYYY-MM-DD") === moment(selectInfo.startStr).format("YYYY-MM-DD")
        });

        // Add your custom logic here to change the background color
        const selectedDayElement: any = document.querySelector(`.fc-day[data-date="${selectedDate[0]?.session_slot_date}"]`);

        if (selectedDayElement) {
            // selectedDayElement.style.backgroundColor = '#F0962C'; // Change the background color
        }

        setDynamicDate(selectInfo?.startStr);
        setSelectedDate(moment(selectInfo.startStr).format("DD-MM-YYYY"));
        const filteredEvents = AvailableSlot?.filter((item: any) => {
            return moment(item?.start).format("YYYY-MM-DD") === moment(selectInfo.startStr).format("YYYY-MM-DD")
        });
        const sortedSessionSlots = filteredEvents.slice().sort((a: any, b: any) => {
            const startTimeA: any = new Date(`1970-01-01T${a.start_time}`);
            const startTimeB: any = new Date(`1970-01-01T${b.start_time}`);
            return startTimeA - startTimeB;
        });
        setPassClickData(sortedSessionSlots);
        // Assuming filteredEvents is an array of events you want to toggle
        // filteredEvents.forEach((event: any) => {
        //     if (!selectedCheckBox.some((selectedEvent: any) => selectedEvent.id === event.id)) {
        //         // Event is not already selected, add it
        //         setSelectedCheckBox((prev: any) => [...prev, event]);
        //     }
        // });
    };

    const handleAuthenticationClose = () => {
        setAuthenticationOpen(false);
        navigate(ROUTES.HOMEPAGE)
    };
    const handleAuthenticationCloseLogin = () => {
        setAuthenticationOpen(false);
        // navigate(ROUTES.HOMEPAGE)
        dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    };

    /** handle Discount*/
    const handleDiscount = (option: any) => {
        axiosInstance
            .get(`${API_END_POINTS.VALIDATE_DISCOUNT + option}`)
            .then((Response: any) => {
                if (Response?.data?.result?.is_discount) {
                    DiscountSelect == option ? setDiscountSelect("") : setDiscountSelect(option);
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message, {
                    position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                });
            });
    };

    const handleSelectAllow = (arg: any) => {
        let SelectAllow = events?.find(
            (values: any) =>
                moment(values.start).format("YYYY-MM-DD") ===
                moment(arg.start).format("YYYY-MM-DD")
        );
        if (SelectAllow) {
            return SelectAllow?.start;
        }
    };
    /*Below class name based on English Arabic*/
    const CalendarView__child__select = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "CalendarView__child__select" : "CalendarView__child__select_arb";

    return (
        <>
            <div className="calendar_view_container">
                <div className="tutor_calendar_head_breadcam">
                    <span className="light" onClick={() => navigate(ROUTES.ACTIVITYLAYOUT)}>
                        {t("Activities")}
                    </span>
                    <ArrowForwardIosIcon className="icon" />
                    <span className="light" onClick={() => navigate(-1)}>
                        {route?.state?.is_reschedule ? (
                            <>
                                {route?.state?.trainer_name}
                            </>
                        ) : (
                            <>
                                {route?.state?.person?.profile_details?.firstname + " " + route?.state?.person?.profile_details?.lastname}
                            </>
                        )}
                    </span>
                    <ArrowForwardIosIcon className="icon" />
                    <span className="dark">{t("Calendar")}</span>
                </div>
                <div className='row activity_calender_view_respon'>
                    <div className='col-lg-8'>
                        <div className='CalendarView__container'>
                            <div className='Calendar__discount__container__activity'>
                                {DiscountList?.map((data: any, i) => {
                                    return (
                                        <>
                                            <div className={DiscountSelect == data?.id ? "discount__card__active" : "discount__card"} onClick={() => handleDiscount(data?.id)}>
                                                <img src={DiscountSelect == data?.id ? Discount_Symbole : Discount_symble_blue} alt="" className='discount__Img' />
                                                <div className='Black_text'>{t("Buy")} {data?.buy_slots} {t("sessions")},</div>
                                                <div className='blue__text'>{t("Get")} {data?.free_slots}</div>
                                                <div className='badge_blue'>{t("free")}</div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className={CalendarView__child__select}>
                                {/* <div className="tittle-div">
                                <img src={back_img} alt="" className="back-img" onClick={() => navigate(-1)} />
                                <img src={activitiesIcon} alt="" className="tutor-icon" />
                                <label className="text-tittle">Activities</label>
                            </div> */}
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl>
                                        <Select
                                            labelId="custom-select-label"
                                            id="custom-select"
                                            className='child_select__dropdown'
                                            value={selectedOption}
                                            disabled={route?.state?.is_reschedule}
                                            onChange={handleChange}
                                        >
                                            {parentProfileData?.map(
                                                (parentProfileElements: any, i: any) => {
                                                    return (
                                                        <MenuItem key={i} value={parentProfileElements} className="avatar_container">
                                                            <div className="avatar_name_wrapper">
                                                                <div className="avatar_name_active_container">
                                                                    <img
                                                                        src={
                                                                            nullUndefinedEmptyCheck(
                                                                                parentProfileElements.profile_image
                                                                            )
                                                                                ? parentProfileElements.profile_image
                                                                                : defaultProfile
                                                                        } onError={(e: any) => { e.target.src = defaultProfile; }}
                                                                        alt="Learn and Play"
                                                                        className="avatar_pic"
                                                                    />
                                                                    <div className="profile_name_role">
                                                                        <div className="profile_name">
                                                                            {parentProfileElements?.name}
                                                                        </div>
                                                                        <div className="role">{t("Parent")}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                            {childProfileData?.map(
                                                (childProfileElements: any, i: any) => {
                                                    return (
                                                        <MenuItem key={i} value={childProfileElements}>
                                                            <div className="avatar_name_wrapper">
                                                                <div className="avatar_name_active_container">
                                                                    <img
                                                                        src={
                                                                            nullUndefinedEmptyCheck(
                                                                                childProfileElements.profile_image
                                                                            )
                                                                                ? childProfileElements.profile_image
                                                                                : defaultProfile
                                                                        } onError={(e: any) => { e.target.src = defaultProfile; }}
                                                                        alt="Learn and Play"
                                                                        className="avatar_pic"
                                                                    />
                                                                    <div className="profile_name_role">
                                                                        <div className="profile_name">
                                                                            {childProfileElements?.name}
                                                                        </div>
                                                                        <div className="role">{t("Kid")}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                            {parentProfileData?.length + childProfileData?.length < 5 && (
                                                <MenuItem
                                                    onClick={() => {
                                                        SetSessionStorageData(
                                                            AUTHENTICATION.SHARED.PROFILE_HEADER,
                                                            AUTHENTICATION.SHARED.PROFILE_ADD
                                                        );
                                                        navigate(ROUTES.ADD_PROFILE);
                                                    }}
                                                >
                                                    <div className="add_profile_container">
                                                        <img
                                                            src={add}
                                                            alt="Learn and Play"
                                                            className="add_icon"
                                                        />
                                                        <div className="add_profile_label">{t("Add L&P Account")}</div>
                                                    </div>
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className='slot_wapper'>
                                <div className='slot__item'>
                                    <div className="selected_slot"></div>
                                    <span>{t("Selected slot")}</span>
                                </div>
                                <div className='slot__item'>
                                    <div className="avilableSlot"></div>
                                    <span>{t("Available slots")}</span>
                                </div>
                                <div className='slot__item'>
                                    <div className="notAvilableSlot"></div>
                                    <span>{t("Not available slot")}</span>
                                </div>
                            </div>
                            <div translate='no'>
                                <FullCalendar
                                    plugins={[
                                        dayGridPlugin,
                                        timeGridPlugin,
                                        interactionPlugin
                                    ]}
                                    initialView='dayGridMonth'
                                    events={events}
                                    select={handleDateSelect}
                                    longPressDelay={1}
                                    firstDay={1}
                                    validRange={{
                                        start: new Date(), // today
                                        end: new Date(new Date().getFullYear(), new Date().getMonth(), 1) // beginning of current month
                                    }}
                                    unselectAuto={false}
                                    selectAllow={handleSelectAllow}
                                    contentHeight={calendarHeight}
                                    // showNonCurrentDates={false}
                                    // displayEventTime={true}
                                    selectable={true}
                                    eventContent={renderEventContent}
                                    expandRows={false}
                                    // eventClick={handleEventClick}
                                    dayCellContent={dayCellContent}
                                    dayCellClassNames={dayCellClassName}
                                    dayHeaderContent={dayHeaderContent}
                                    slotEventOverlap={false} // Prevent events from overlapping
                                    headerToolbar={{
                                        left: "title,prev,next",
                                        center: "timeGridWeek,dayGridMonth",
                                        right: "",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='CalendarView__rightside__Activities'>
                            <div className="top-section">
                                <div className='CalendarView__datePicker'>
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    className="datePicker"
                                    disablePast
                                    format="dd-MM-yyyy"
                                    value={DynamicDate}
                                    onChange={(date) => { setDynamicDate(date) }}
                                />
                            </LocalizationProvider> */}
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    className="datePicker"
                                    disableFuture
                                />
                            </LocalizationProvider> */}

                                    {/* <div className={CheckAvailabeTime ? "chosse_time_Conatiner__Active" : "chosse_time_Conatiner"} onClick={() => setCheckAvailabeTime(!CheckAvailabeTime)}>
                                        <img src={TimeClock} alt="" />
                                        <span>{t("Available slots")}</span>
                                    </div> */}


                                </div>
                                {/* <div className='Timeline__Calendar_container'>
                            <FullCalendar
                                plugins={[timeGridPlugin]}
                                initialView={'timeGridDay'}
                                headerToolbar={{
                                    left: 'prev,next',
                                    center: 'title',
                                    right: 'timeGridWeek,timeGridDay' // user can switch between the two
                                }}
                                allDaySlot={false}
                                events={PassClickData}
                                eventOverlap={false}
                                displayEventTime={true}
                                slotDuration="01:00:00"
                                initialDate={new Date(DynamicDate)}
                                firstDay={1}
                                key={DynamicDate}
                            />
                        </div> */}

                                <div className='rightSide__top__Date'>
                                    {moment(DynamicDate).format("dddd, MMMM Do YYYY,")}
                                </div>

                                <div className='rightSide_slot_container_wapper'>
                                    {CheckAvailabeTime ? (
                                        <>
                                            {PassClickData?.map((item: any, i: any) => {
                                                const matchingTimeData = DayTotalHours?.find((data: any) => data?.time === moment(item?.start).format("h a"));
                                                if (matchingTimeData) {
                                                    return (
                                                        <>
                                                            <div className='slot_container_Position' key={i}>


                                                                {/* <div className='DotedLine__wapper'> */}
                                                                {/* <div className='time'>{matchingTimeData?.time}</div> */}
                                                                {/* <div className="dotted-line"></div> */}
                                                                {/* {i === PassClickData?.length - 1 && (
                                                                    <div className='time'>{moment(item?.end).format("h a")}</div>
                                                                )} */}
                                                                {/* <div className="time">
                                                                    {moment(item?.start).format("h a") === '11 pm' ? "11:59 pm" : moment(item?.end).format("h a")}
                                                                </div>
                                                            </div> */}


                                                                {matchingTimeData?.time == moment(item?.start).format("h a") ? (
                                                                    <div className='Slot_container' key={i} onClick={() => handleCheckbox(item)}>
                                                                        <div className='Slot__activities_img_wapper'>
                                                                            <img src={activitiesIcon} alt="" />
                                                                        </div>
                                                                        <div className='Slot__activities_contant_wapper'>
                                                                            <div className='Slot_game_name'>{item?.title}
                                                                                <Checkbox
                                                                                    checked={selectedCheckBox.includes(item)}
                                                                                    onChange={() => handleCheckbox(item)}
                                                                                    className="slot-checkbox"
                                                                                    sx={{
                                                                                        '& .MuiIconButton-root': {
                                                                                            border: 'none',
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="tutor-createTime-wrap">
                                                                                <div className='Slot_badge'>{t("Slot Available")}</div>
                                                                                <div className="createdBy_Activity">
                                                                                    {moment(item?.start).format("h:mm a")} to {moment(item?.end).format("h:mm a")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : ""}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })}

                                        </>) :
                                        (
                                            <>
                                                {/* {DayTotalHours?.map((data: any, i: any) => {
                                                return (
                                                    <>
                                                        <div className='slot_container_Position' key={i}>
                                                            <div className='DotedLine__wapper'>
                                                                <div className='time'>{data.time}</div>
                                                                <div className="dotted-line"></div>
                                                            </div>
                                                            {PassClickData?.map((item: any, i: any) => {
                                                                return (
                                                                    <>
                                                                        {data?.time == moment(item?.start).format("h a") ? (
                                                                            <div className='Slot_container' key={i} onClick={() => handleCheckbox(item)}>
                                                                                <div className='Slot__activities_img_wapper'>
                                                                                    <img src={activitiesIcon} alt="" />
                                                                                </div>
                                                                                <div className='Slot__activities_contant_wapper'>
                                                                                    <div className='Slot_game_name'>{item?.title}
                                                                                        <Checkbox
                                                                                            checked={selectedCheckBox.includes(item)}
                                                                                            onChange={() => handleCheckbox(item)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="tutor-createTime-wrap">
                                                                                    <div className='Slot_badge'>{t("Slot Available")}</div>
                                                                                    <div className="createdBy_Activity">
                                                                                     {data?.time} to {moment(item?.start).format("h a") === '11 pm' ? "11:59 pm" : moment(item?.end).format("h a")}
                                                                                   </div>
                                                                                   </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : ""}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </>
                                                )
                                            })} */}
                                            </>
                                        )}
                                </div>
                            </div>


                            <div className='Bottom__book__slot__container'>
                                <div className='Book__slot__left' translate={'no'}>
                                    <span className='Selected__text'>{t("Slots Selected")}</span>
                                    {nullUndefinedEmptyCheck(DiscountSelect) ? (
                                        <span className="count__text" translate={'no'}>
                                            {selectedCheckBox?.length}/{DiscountArray[0]?.total_slots}{" "}
                                            {t("Slots")}
                                        </span>
                                    ) : (
                                        <span className="count__text" translate={'no'}>
                                            {selectedCheckBox?.length} {t("Slots")}
                                        </span>
                                    )}
                                </div>
                                <div className='Book__slot__right'>
                                    {nullUndefinedEmptyCheck(DiscountSelect) ? (
                                        <button className={selectedCheckBox?.length >= DiscountArray[0]?.total_slots ? 'Activities__book__slot__btnActivie' : 'Activities__book__slot__btnInActive'} disabled={selectedCheckBox?.length >= DiscountArray[0]?.total_slots ? false : true}
                                            onClick={navigateCheckOut}>{t("Book slots")}</button>
                                    ) : (
                                        <button
                                            className={
                                                selectedCheckBox?.length > 0
                                                    ? "Activities__book__slot__btnActivie"
                                                    : "Activities__book__slot__btnInActive"
                                            }
                                            disabled={selectedCheckBox?.length > 0 ? false : true}
                                            onClick={navigateCheckOut}
                                        >
                                            {t("Book slots")}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {open && (
                <div className="overlay-ModelBackround">
                    <Dialog open={open}>
                        <DialogContent>
                            <div className="child_choose_calendar">
                                <img src={defaultProfile} alt="" />
                                <span>{PassErrorMsg}</span>
                                <button className="Activitie__Back__navigateBtn" onClick={handleClose}>{t("Back")}</button>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )}

            {ChildSelectOpen && (
                <div className="overlay-ModelBackround">
                    <Dialog open={ChildSelectOpen}>
                        <DialogContent>
                            <div className='ChildSelect__container'>
                                <div className='text__contant'>{t("Add Child to Book slot session")}</div>
                                <div className="ChildSelectBtn__wapper__Activities">
                                    <button className='BackBtn' onClick={handleChildSelectClose}>{t("Back")}</button>
                                    <button className='OkayBtn' onClick={() => {
                                        SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, AUTHENTICATION.SHARED.PROFILE_ADD)
                                        navigate(ROUTES.ADD_PROFILE)
                                    }}>{t("Okay")}</button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )}

            {AuthenticationOpen && (
                <div className="overlay-ModelBackround">
                    <Dialog open={AuthenticationOpen}>
                        <DialogContent>
                            <div className='ChildSelect__container'>
                                <div className='text__contant'>{t("Please Login the user")}</div>
                                <div className="ChildSelectBtn__wapper__Activities">
                                    <button className='BackBtn' onClick={handleAuthenticationClose}>{t("Back")}</button>
                                    <button className='OkayBtn' onClick={handleAuthenticationCloseLogin}>{t("Okay")}</button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )}

            {DiscountSelectOpen && (
                <div className="overlay-ModelBackround">
                    <Dialog open={DiscountSelectOpen}>
                        <DialogContent>
                            <div className='ChildSelect__container'>
                                <div className='discount__title'>{t("Available Discount")}:</div>
                                {!FinalPromtCheck && !EmptyMesg && (<div className='discount__body'>{t("you can either choose the best discount or you can available your selected discount")}</div>)}
                                {FinalPromtCheck && (<div className='discount__body'>{t("Hey there! we provide you the best discount, you can eithe choose anyone and can proceed or you can continue without discount")} </div>)}
                                {EmptyMesg && !FinalPromtCheck && (<div className='discount__body'>{t("Good day, Choose more slots and available discounts and enjoy!")}</div>)}
                                <div className='Calendar__discount__container__activity'>
                                    {DiscountArray?.map((data: any, i: any) => {
                                        return (
                                            <>
                                                <div className={DiscountSelect == data?.id ? "discount__card__active" : "discount__card"} onClick={() => {
                                                    handleDiscount(data?.id);
                                                    setDiscountSelectOpen(false);
                                                }}>
                                                    <img src={DiscountSelect == data?.id ? Discount_Symbole : Discount_symble_blue} alt="" className='discount__Img' />
                                                    <div className='Black_text'>{t("Buy")} {data?.buy_slots} {t("sessions")},</div>
                                                    <div className='blue__text'>{t("Get")} {data?.free_slots}</div>
                                                    <div className='badge_blue'>{t("free")}</div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <div className="ChildSelectBtn__wapper__Tutor">
                                    <button className='BackBtn' onClick={() => {
                                        setFinalPromtCheck(false);
                                        setDiscountSelectOpen(false);
                                        setEmptyMesg(false);
                                    }}>Back</button>
                                    {FinalPromtCheck || EmptyMesg && (<button className='OkayBtn' onClick={navigateCheckOut}>{t("Book slots")}</button>)}
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )}

            {ReschedulePopOpen && (
                <div className="overlay-ModelBackround">
                    <Dialog open={ReschedulePopOpen}>
                        <DialogTitle>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={() => setReschedulePopOpen(false)}
                                aria-label="close"
                                sx={{
                                    position: "absolute",
                                    top: 6,
                                    right: 13,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <div className="Activities__Payment__Success__Pop">
                                <div className="Payment__Pop__wapper">
                                    <img src={MobileCalenderIcon} alt="" />
                                    <div className="text">{t("Confirm your reschedule slot to Add booking to your calendar?")}</div>
                                </div>
                                <div className="Payment__bottom__btn">
                                    <button className="backBtn" onClick={() => setReschedulePopOpen(false)}>
                                        {t("Cancel")}
                                    </button>
                                    <button
                                        className="SuccessBtn"
                                        onClick={() => handleNavigateReschedule()}
                                    >
                                        {t("Okay")}
                                    </button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )}

        </>
    )
}

export default ActivitiesCalendarView;