import React, { useEffect, useState } from 'react';
import {
    GoogleMap, LoadScript, MarkerF, Circle, Marker, InfoWindow, InfoBox, MarkerClusterer,
    CircleF, Autocomplete, DirectionsService, DirectionsRenderer, useJsApiLoader
} from "@react-google-maps/api";
import "./ActivitiesMaps.scss";
import { MapProps } from '../../Models/ActivitiesModel';
import tutor_img from "../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../Assets/Activities/share_activity.svg";
import LikeIcon from "../../Assets/Activities/like_activity.svg";
import StarIcon from '@mui/icons-material/Star';
import { GetSessionStorageData, SetSessionStorageData, encryptPassData, nullUndefinedEmptyCheck } from '../../Utilities/Helpers/Helper';
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES, TUTORMAPCONSTANT } from '../../Constants/Constants';
import campIcon from "../../Assets/Activities/campMapIcon.svg";
import personalIcon from "../../Assets/Activities/personalMapIcon.svg";
import EducationIconActivities from "../../Assets/Activities/EducationIconActivities.svg";
import { setLocation, setLocationName, setLoginSignUpOpen } from '../../Redux/Reducer/Reducer';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance } from '../../Utilities/Interceptor/Interceptor';
import toast from 'react-hot-toast';
import ActivityFill from "../../Assets/HeartFill/ActivityFill.svg";
import WebShareComponent from '../WebShare/WebShare';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import campIdentityIcon from "../../Assets/Activities/camp_icon.svg";
import ptIcon from "../../Assets/Activities/cap.svg";


const ActivitiesMaps = ({ zoom, radius, containerStyle, disable, zoomControl, draggable, clickableIcons, ActivitiesList, getOneTutor, lat, lang, searchValue, filter, initFilter, initSort }: MapProps) => {

    const [currentLocation, setCurrentLocation] = useState<any>(null);
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const [userProfileImg, setUserProfileImg] = useState<any>();
    const [List, setList] = useState<any>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [libraries] = useState([TUTORMAPCONSTANT.LIBRARIES]);

    useEffect(() => {
        const location = GetSessionStorageData(AUTHENTICATION.SHARED.ACTIVITIES_LOCATION)
        if (location) {
        const parsedlocation = JSON.parse(location)
        setCurrentLocation(parsedlocation)
        dispatch(setLocation(parsedlocation));
        }
        setCurrentLocation({ lat: parseFloat(lat), lng: parseFloat(lang) });
        setUserProfileImg(GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE));
        if (lat && lang) {
            ActivityListApi();
        }
    }, [lat, lang, radius, searchValue, filter]);

    const timeFormat = (value: number) => {
        if (value === 24) {
            return `23:59`;
        }
        const hours = Math.floor(value);
        const minutes = (value % 1) * 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    /**Header search location*/
    const HeaderLoction: any = useSelector((state: any) => {
        return state.locationReducer.location;
    });

    const payload = () => {
        var payload;
        var priceValue: any = [];
        var experienceValue: any = [];
        var ageValue: any = [];
        var timeValue: any = [];
        if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
            priceValue = []
        } else {
            priceValue = {
                min: filter.price[0],
                max: filter.price[1],
            }
        }
        if (initFilter.experience[0] === filter.experience[0] && initFilter.experience[1] === filter.experience[1]) {
            experienceValue = []
        } else {
            experienceValue = {
                min: filter.experience[0],
                max: filter.experience[1],
            }
        }
        if (initFilter.age[0] === filter.age[0] && initFilter.age[1] === filter.age[1]) {
            ageValue = []
        } else {
            ageValue = {
                min: filter.age[0],
                max: filter.age[1],
            }
        }
        if (initFilter.time[0] === filter.time[0] && initFilter.time[1] === filter.time[1]) {
            timeValue = []
        } else {
            timeValue = {
                min: timeFormat(filter.time[0]),
                max: timeFormat(filter.time[1]),
            }
        }
        return payload = {
            latitude: lat?.toString(),
            longitude: lang?.toString(),
            radius: filter.radius,
            search: searchValue ? searchValue : "",
            price: priceValue,
            experience: experienceValue,
            age: ageValue,
            time: timeValue,
            activities: filter.activities,
            gender: filter.gender,
            available: filter.available,
            rating: filter.rating,
        }
    }

    /**Activity get List Api*/
    const ActivityListApi = () => {
        var pay = payload();
        axiosInstance
            .post(`${API_END_POINTS.ACTIVITIESLIST}`, pay)
            .then((Response: any) => {
                setList(Response?.data?.result?.data)
            })
            .catch(() => { });
    };

    const navigateDetailedView = (Detailes: any) => {
        navigate(ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(Detailes?.id) + '&type=' + encryptPassData(Detailes?.is_service_type), { state: { Activies_Detailes: Detailes } });
    }

    useEffect(() => {
        if (initSort?.price || initSort?.rating) {
            handleProceedSort(initSort?.price, initSort?.rating)
        }
    }, [initSort])

    const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
        if (checkedPrice == 'high') {
            const campData: any = [...List?.camp].sort((a: any, b: any) => b?.least_price - a?.least_price);
            const personalData: any = [...List?.personal].sort((a: any, b: any) => b?.least_price - a?.least_price);
            setList({
                camp: campData,
                personal: personalData
            });
        } else if (checkedPrice == 'low') {
            const campData: any = [...List?.camp].sort((a: any, b: any) => a?.least_price - b?.least_price);
            const personalData: any = [...List?.personal].sort((a: any, b: any) => a?.least_price - b?.least_price);
            setList({
                camp: campData,
                personal: personalData
            });
        } else if (checkedRating == 'high') {
            const campData: any = [...List?.camp].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
            const personalData: any = [...List?.personal].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
            setList({
                camp: campData,
                personal: personalData
            });
        } else if (checkedRating == 'low') {
            const campData: any = [...List?.camp].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
            const personalData: any = [...List?.personal].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
            setList({
                camp: campData,
                personal: personalData
            });
        }
    }

    const mapOptions = {
        disableDefaultUI: disable, // This will remove all default UI controls
        zoomControl: zoomControl, // This will specifically remove the zoom control
        draggable: draggable, // Disable dragging the map
        clickableIcons: clickableIcons, // Disable clicking on map icons
        disableDoubleClickZoom: disable,
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [
                    { visibility: 'off' },
                ],
            },
        ],
    };

    const handleChangeFavourites = (val: any, id: any, type: any, campId: any) => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            var payload: any

            if (type == "camp") {
                payload = {
                    service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.THREE_INT,
                    service_provider: id,
                    is_favourite: !val,
                    camp_session: campId
                }
            } else {

                payload = {
                    service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT,
                    service_provider: id,
                    is_favourite: !val
                }
            }
            axiosInstance
                .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
                .then((Response: any) => {
                    if (Response?.data?.status) {
                        toast.success(Response?.data?.message, {
                            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                        });
                        ActivityListApi();
                    }
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message, {
                        position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
                    });
                });
        } else {
            dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
        }
    }


    const { isLoaded } = useJsApiLoader({
        id: TUTORMAPCONSTANT.MAP__ID || "",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
        libraries,
    });

    /**Header search location getFormattedAddress Function */
    const geocoder: any = isLoaded ? new window.google.maps.Geocoder() : '';

    const getFormattedAddress = (lat: any, lng: any) => {
        geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {
            if (status) {
                var addressComponents = results[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT]?.address_components;
                if (results[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT]?.address_components?.length > 0) {
                    const regionComponent = addressComponents?.find((component: any) => component?.types.includes("locality"));
                    const countryComponent = addressComponents?.find((component: any) => component?.types.includes("country"));
                    const countryName = countryComponent ? countryComponent.long_name : null;
                    const regionName = regionComponent ? regionComponent.long_name : null;
                    SetSessionStorageData(AUTHENTICATION.SHARED.REGION_NAME, regionName);
                    SetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME, countryName);
                    dispatch(setLocationName(countryName));
                }
            }
        });
    }

    return isLoaded ? (
        <>

            {/* <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ""}
                libraries={["places"]}
            > */}
            <GoogleMap
                id={TUTORMAPCONSTANT.MAP__ID}
                mapContainerStyle={containerStyle}
                zoom={zoom}
                center={currentLocation}
                options={mapOptions}
                onClick={() => setSelectedLocation(null)}
                onMouseOut={() => setSelectedLocation(null)}
            >
                <CircleF
                    center={HeaderLoction}
                    radius={radius}
                    options={{
                        fillColor: '#178FFF', // Fill color with opacity
                        strokeColor: 'rgba(79, 139, 255, 0.60)', // Border color
                        strokeOpacity: 0.8, // Border opacity
                        strokeWeight: 2, // Border width
                        fillOpacity: 0.1, // Fill opacity
                    }}
                />
                {HeaderLoction && (
                    <MarkerF
                        position={HeaderLoction}
                        draggable={true}
                        zIndex={999}
                        shape={{ coords: [10, 14, 19], type: "circle" }}
                        icon={{
                            scaledSize: new google.maps.Size(40, 40),
                            url: userProfileImg,
                        }}
                        onDragEnd={
                            (e: any) => {
                                const locationData: any = {
                                    lat: e.latLng.lat(),
                                    lng: e.latLng.lng()
                                };
                                setCurrentLocation(locationData);
                                dispatch(setLocation(locationData));
                                SetSessionStorageData(AUTHENTICATION.SHARED.ACTIVITIES_LOCATION, JSON.stringify(locationData));
                                getFormattedAddress(e?.latLng?.lat(), e?.latLng?.lng());
                            }}
                    />
                )}
                {List?.personal?.map((data: any) => {
                    let object = {
                        lat: parseFloat(data.latitude),
                        lng: parseFloat(data.longitude)
                    }
                    return (
                        <Marker position={object}
                            icon={{
                                scaledSize: lat == data?.latitude && lang == data?.longitude ? new google.maps.Size(65, 65) : new google.maps.Size(40, 40),
                                url: personalIcon,
                            }}
                            // onMouseOver={() => setSelectedLocation(data)}
                            // onMouseOut={() => setSelectedLocation(null)}
                            onMouseOver={() => setSelectedLocation(data)}
                            animation={lat == data?.latitude && lang == data?.longitude ? window.google.maps.Animation.DROP : undefined}
                        >
                            {selectedLocation === data && (
                                <InfoWindow onCloseClick={() => setSelectedLocation(null)} options={{
                                    maxWidth: 372,
                                }} >
                                    <div className="" onDoubleClick={() => navigateDetailedView(data)}
                                        onMouseEnter={() => setSelectedLocation(data)}
                                        onMouseLeave={() => setSelectedLocation(null)}
                                    // onMouseOver={() => setSelectedLocation(data)} onMouseOut={() => setSelectedLocation(null)}
                                    >
                                        <div className='Hover__Card' onClick={() => {
                                            if (clickableIcons) {
                                                getOneTutor(data)
                                            } else {
                                                navigateDetailedView(data)
                                            }
                                        }}>
                                            {/* <div className='profile_image__container__Activities'>
                                                <img src={ptIcon} className='EducationIcon' alt="EducationIcon" />
                                                <img src={data?.profile_image ? data?.profile_image : tutor_img} alt="profile_image"
                                                    onError={(e: any) => { e.target.src = tutor_img; }} className='profile_image' />
                                            </div> */}
                                            <div className='rightside_contant'>
                                                <div className="w-100">
                                                    <div className="user__Detailes">
                                                        <div className="user__name">{data?.profile_details?.firstname}{" "}{data?.profile_details?.lastname}</div>
                                                        <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                                                            <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(data?.id) + '&type=' + encryptPassData(data?.is_service_type)}>
                                                                <img src={shareIcon} alt="shareIcon" />
                                                            </WebShareComponent>
                                                            <img src={data?.is_favourite ? ActivityFill : LikeIcon} alt="LikeIcon"
                                                                onClick={() => handleChangeFavourites(data?.is_favourite, data?.user, data?.is_service_type, data?.id)} />
                                                        </div>
                                                    </div>

                                                    <div className="user__Detailes__Slots_activities">
                                                        <div className="lable"><span>{data?.trainer_activity?.length > 0 ? data?.trainer_activity[0]?.activites : ""}</span></div>
                                                        {/* <div className='Slots'>Slots:{data?.is_slots_available && (<span className='SlotsYes'>Yes</span>)} </div> */}

                                                    </div>

                                                    <div className="user__Detailes__rating_Activities">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <div></div>
                                                            <h1 className="title">{data?.trainer_activity?.length > 0 ? "AED " + data?.trainer_activity[0]?.expected_fee : ""}</h1>
                                                        </div>
                                                        <div className="d-flex gap-2 align-items-center">
                                                            {data?.rating ? (<StarIcon className="StarIcon" />) : ('')}
                                                            <div className="card__title">{data?.rating}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>
                    )
                })}
                {List?.camp?.map((data: any) => {
                    let object = {
                        lat: parseFloat(data.latitude),
                        lng: parseFloat(data.longitude)
                    }
                    return (
                        <Marker position={object}
                            icon={{
                                scaledSize: lat == data?.latitude && lang == data?.longitude ? new google.maps.Size(65, 65) : new google.maps.Size(40, 40),
                                url: campIcon,
                            }}
                            onMouseOver={() => setSelectedLocation(data)}
                        >
                            {selectedLocation === data && (
                                <InfoWindow onCloseClick={() => setSelectedLocation(null)} options={{
                                    maxWidth: 372,
                                }} >
                                    <div className="" onDoubleClick={() => navigateDetailedView(data)}

                                        onMouseEnter={() => setSelectedLocation(data)}
                                        onMouseLeave={() => setSelectedLocation(null)}
                                    >
                                        <div className='Hover__Card' onClick={() => {
                                            if (clickableIcons) {
                                                getOneTutor(data)
                                            } else {
                                                navigateDetailedView(data)
                                            }
                                        }}>
                                            {/* <div className='profile_image__container__Activities'>
                                                <img src={campIdentityIcon} className='EducationIcon' alt="EducationIcon" />
                                                <img src={data?.camp_images?.length > 0 ? data?.camp_images[0] : tutor_img} alt="profile_image"
                                                    onError={(e: any) => { e.target.src = tutor_img; }} className='profile_image' />
                                            </div> */}
                                            <div className='rightside_contant'>
                                                <div className="w-100">
                                                    <div className="user__Detailes">
                                                        <div className="user__name">{data?.camp_session_title}</div>
                                                        <div className="user__icon__wrapper" onClick={(e) => e.stopPropagation()}>
                                                            <WebShareComponent params={ROUTES.ACTIVITY_DETAILE_VIEW + "?id=" + encryptPassData(data?.id) + '&type=' + encryptPassData(data?.is_service_type)}>
                                                                <img src={shareIcon} alt="shareIcon" />
                                                            </WebShareComponent>
                                                            <img src={data?.is_favourite ? ActivityFill : LikeIcon} alt="LikeIcon"
                                                                onClick={() => handleChangeFavourites(data?.is_favourite, data?.user, data?.is_service_type, data?.id)} />
                                                        </div>
                                                    </div>
                                                    <div className="user__Detailes__rating_Activities">
                                                        <div className="card__title">By {data?.camp_name}</div>
                                                    </div>

                                                    <div className="user__Detailes__rating_Activities">
                                                        <div className="card__title">{moment(new Date(data?.start_date)).format("MMM DD")} - {moment(new Date(data?.end_date)).format("MMM DD")}</div>
                                                    </div>

                                                    <div className="user__Detailes__rating_Activities">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <div className="title">{"AED " + data?.total_class_fee}</div>
                                                            <div className="card__title">for {data?.total_class} classes</div>
                                                        </div>
                                                        <div className="d-flex gap-2 align-items-center">
                                                            {data?.rating ? (<StarIcon className="StarIcon" />) : ('')}
                                                            <div className="card__title">{data?.rating}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>
                    )
                })}
            </GoogleMap>
            {/* </LoadScript > */}

        </>
    ) : (<></>)
}

export default ActivitiesMaps