import ActivitiesMaps from "../../../Components/ActivitiesMaps/ActivitiesMaps";
import { Autocomplete, TextField } from "@mui/material";
import activitiesIcon from "../../../Assets/Activities/activitiesIcon.svg";
import search from "../../../Assets/Tutor/searchIconGray.svg";
import FilterIcon from "../../../Assets/Tutor/FilterIcon.svg";
import "./ActivitiesMapsView.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES, FILTER_CONSTANT, RadiusMapKm } from "../../../Constants/Constants";
import { GetSessionStorageData, SetSessionStorageData, isObjectEmptyValue, nullUndefinedEmptyCheck } from "../../../Utilities/Helpers/Helper";
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Rating from "@mui/material/Rating";
import { store } from "../../../Redux/Store/Store";
import { setActivitiesFilter, setInitActivitiesFilter, setLoader } from "../../../Redux/Reducer/Reducer";
import axios from "axios";
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "../Dropdown";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ActivitesModelDilogView from "../ActivitesModelDilogView/ActivitesModelDilogView";
import ActivityMapRadiusIcon from "../../../Assets/Activities/ActivityMapRadiusIcon.svg"
import { useSelector } from "react-redux";
import AppFilter from "../../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";

// Render the  Map view Page size
const containerStyle = {
    width: '100vw',
    height: '100vh'
};


const ActivitiesMapsView = () => {
    const activity_search = GetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.ACTIVITY);
    const route = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState(activity_search ? activity_search : '');
    const [triggerVal, setTriggerVal] = useState(false);
    const [dailogActivitiesData, setDailogActivitiesData] = useState([]);
    const [activitiesList, setActivitiesList] = useState(route?.state?.activities_list);
    const [searchTextList, setSearchTextList] = useState([]);
    const [currentLat, setCurrentLat] = useState<any>('');
    const [currentLang, setCurrentLang] = useState<any>('');
    const [FilterData, setFilterData] = useState<any>([]);
    const [ResetBoolean, setResetBoolean] = useState(false);
    const [MapZoom, setMapZoom] = useState(12);

    const getActivityFilter: any = useSelector((state: any) => {
        return state.filterReducer?.activityfilter
    });

    const init_ActivityFilter: any = useSelector((state: any) => {
        return state.initFilterReducer?.initActivityfilter
    });

    // Filter
    const init_filter = {
        activities: [],
        gender: [],
        price: [0, 0],
        age: [0, 0],
        experience: [0, 0],
        rating: 0,
        available: [],
        time: [0, 24],
        radius: 10
    }
    const [FilterPopOpen, setFilterPopOpen] = useState(false);
    const [initFilter, setInitFilter] = useState<any>(isObjectEmptyValue(init_ActivityFilter) ? init_ActivityFilter : init_filter);
    const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
    const [initSort, setSort] = useState<any>({
        price: '',
        rating: ''
    });

    const onOpenFilterPop = () => {
        setFilterPopOpen(true);
    };

    const onCloseFilterPop = () => {
        setFilterPopOpen(false);
    };

    const getOneTutor = (data: any) => {
        setDailogActivitiesData(data);
        setTriggerVal(true);
    }

    /**Header search location*/
    const HeaderLoction: any = useSelector((state: any) => {
        return state.locationReducer.location;
    });

    useEffect(() => {
        if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng)) {
            setCurrentLat(HeaderLoction?.lat);
            setCurrentLang(HeaderLoction?.lng)
            if (getActivityFilter && isObjectEmptyValue(getActivityFilter)) {
                ActivitiesListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, getActivityFilter);
            } else {
                ActivitiesListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
            }
        }
    }, [HeaderLoction, getActivityFilter])

    const timeFormat = (value: number) => {
        if (value === 24) {
            return `23:59`;
        }
        const hours = Math.floor(value);
        const minutes = (value % 1) * 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }



    useEffect(() => {
        getFilterData();
    }, [])

    /**Activities List Api Payload*/
    const payload = (latitude: any, longitude: any, value: string, filter: any) => {
        var payload;
        var priceValue: any = [];
        var experienceValue: any = [];
        var ageValue: any = [];
        var timeValue: any = [];
        if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
            priceValue = []
        } else {
            priceValue = {
                min: filter.price[0],
                max: filter.price[1],
            }
        }
        if (initFilter.experience[0] === filter.experience[0] && initFilter.experience[1] === filter.experience[1]) {
            experienceValue = []
        } else {
            experienceValue = {
                min: filter.experience[0],
                max: filter.experience[1],
            }
        }
        if (initFilter.age[0] === filter.age[0] && initFilter.age[1] === filter.age[1]) {
            ageValue = []
        } else {
            ageValue = {
                min: filter.age[0],
                max: filter.age[1],
            }
        }
        if (initFilter.time[0] === filter.time[0] && initFilter.time[1] === filter.time[1]) {
            timeValue = []
        } else {
            timeValue = {
                min: timeFormat(filter.time[0]),
                max: timeFormat(filter.time[1]),
            }
        }
        return payload = {
            latitude: latitude?.toString(),
            longitude: longitude?.toString(),
            radius: filter.radius,
            search: value ? value : "",
            price: priceValue,
            experience: experienceValue,
            age: ageValue,
            time: timeValue,
            activities: filter.activities,
            gender: filter.gender,
            available: filter.available,
            rating: filter.rating,
        }
    }

    /**Activities get List Api*/
    const ActivitiesListApi = (latitude: any, longitude: any, value: string, filter: any) => {
        var pay = payload(latitude, longitude, value, filter);
        axiosInstance
            .post(`${API_END_POINTS.ACTIVITIESLIST}`, pay)
            .then((Response: any) => {
                setActivitiesList(Response?.data?.result?.data);
            })
            .catch(() => { });
    };

    /**Get Filter Categories List*/
    const getFilterData = () => {
        axiosInstance
            .get(`${API_END_POINTS.ACTIVITIES_FILTERLIST}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    let data = Response?.data?.result?.data;
                    setFilterData(data);
                    if (data) {
                        let init = {
                            activities: [],
                            price: [data.price?.min, data.price?.max],
                            experience: [data.experience?.min, data.experience?.max],
                            gender: [],
                            rating: 0,
                            age: [data.age?.min, data.age?.max],
                            available: [],
                            time: [0, 0],
                            radius: 10,
                        }
                        setInitFilter(init);
                        SetSessionStorageData(FILTER_CONSTANT.INIT_FILTER.ACTIVITY, JSON.stringify(init));
                        store.dispatch(setInitActivitiesFilter(init));
                        if (getActivityFilter && isObjectEmptyValue(getActivityFilter)) {
                            setFilterApplyData(getActivityFilter);
                        } else {
                            setFilterApplyData(init);
                        }
                    }
                }
            })
            .catch(() => { });
    };

    /**Activities Search Keyword Api*/
    const searchActivitiesKeyword = (search: any) => {
        const accessToken = GetSessionStorageData(
            AUTHENTICATION.SHARED.ACCESS_TOKEN
        );
        var payload = {
            search_text: search.trim()
        };
        axios
            .post(`${process.env.REACT_APP_BASE_BACKEND_API_URL + API_END_POINTS.ACTIVITIES_KEY_WORD_SEARCH}`, payload, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((Response: any) => {
                setSearchTextList(Response?.data.result?.data);
            })
            .catch(() => { });
    };

    /**Filter Apply api call Function*/
    const FilterApply = (filter: any) => {
        setFilterApplyData(filter);
        SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.ACTIVITY, JSON.stringify(filter));
        store.dispatch(setActivitiesFilter(filter));
        ActivitiesListApi(currentLat, currentLang, searchValue, filter);
    }

    /**Filter Reset Function*/
    const FilterReset = () => {
        store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));

        setTimeout(() => {
            setResetBoolean(!ResetBoolean);
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
        }, 500)
    }

    /**AutoComplete Onchange Function*/
    const onInputChange = (value: any) => {
        store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
        if (nullUndefinedEmptyCheck(value)) {
            setSearchValue(value);
            searchActivitiesKeyword(value);
            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.ACTIVITY, value);
        } else {
            ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData);
            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.ACTIVITY, '');
        }
    }

    /**AutoComplete submit Function*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        //  prevent page refresh
        event.preventDefault();
        ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData)
    };


    const handleChangeRadiusMapvalue = (value: any) => {
        if (value === 15) {
            setMapZoom(12);
        } else if (value === 50) {
            setMapZoom(10);
        } else if (value === 100) {
            setMapZoom(9);
        } else {
            setMapZoom(12);
        }
        setFilterApplyData({ ...FilterApplyData, radius: FilterApplyData.radius == value ? 10 : value })
    };


    // Route to the Dashboard view Page
    const handleRoutedashboard = () => {
        navigate(ROUTES.ACTIVITYLAYOUT,
            {
                state: {
                    activities_list: activitiesList, search_text: searchValue,
                    redius: FilterApplyData.radius, lat: currentLat, lang: currentLang, filter: FilterApplyData
                }
            });
    }

    const getLatAndLong = (lat: string, lang: string) => {
        setCurrentLat(lat);
        setCurrentLang(lang);
    }

    const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
        if (checkedPrice == 'high') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => b?.least_price - a?.least_price);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => b?.least_price - a?.least_price);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        } else if (checkedPrice == 'low') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => a?.least_price - b?.least_price);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => a?.least_price - b?.least_price);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        } else if (checkedRating == 'high') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        } else if (checkedRating == 'low') {
            const campData: any = [...activitiesList?.camp].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
            const personalData: any = [...activitiesList?.personal].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
            setActivitiesList({
                camp: campData,
                personal: personalData
            });
            setSort({
                price: checkedPrice,
                rating: checkedRating
            })
        }
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });

    /*Below class name based on English Arabic*/
    const Search_icon_top = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_button_eng" : "search_button_arb";


    return (
        <>
            <div className="ActivitiesMaps__view">
                <div className="d-none d-md-block">
                    <ActivitiesMaps zoom={MapZoom} radius={FilterApplyData.radius * 1000} lat={currentLat} lang={currentLang} containerStyle={containerStyle} disable={false} zoomControl={true} initSort={initSort}
                        clickableIcons={true} draggable={true} ActivitiesList={activitiesList} getOneTutor={getOneTutor} filter={isObjectEmptyValue(getActivityFilter) ? getActivityFilter : FilterApplyData} searchValue={searchValue} initFilter={initFilter} />
                </div>
                <div className="d-block d-md-none">
                    <ActivitiesMaps zoom={MapZoom} radius={FilterApplyData.radius * 1000} lat={currentLat} lang={currentLang} containerStyle={containerStyle} disable={false} zoomControl={true} initSort={initSort}
                        clickableIcons={false} draggable={true} ActivitiesList={activitiesList} getOneTutor={getOneTutor} filter={isObjectEmptyValue(getActivityFilter) ? getActivityFilter : FilterApplyData} searchValue={searchValue} initFilter={initFilter} />
                </div>
                <div className="Activities__card__Position">
                    <div className="activities_Img_wapper" onClick={() => handleRoutedashboard()}>
                        <img
                            draggable="false"
                            src={activitiesIcon}
                            alt="activitiesImg"
                            className=""
                        />
                        <div className="activities__text">{t("Activities")}</div>
                    </div>
                    <div className="search_field_wrapper mt-3">
                        <div className="search__autocomplete">
                            <form onSubmit={handleSubmit}>
                                <Autocomplete
                                    freeSolo
                                    popupIcon
                                    id="free-solo-2-demo"
                                    disableClearable={false}
                                    value={searchValue}
                                    clearIcon={false}
                                    onChange={(e, value) => {
                                        setSearchValue(value);
                                        ActivitiesListApi(currentLat, currentLang, value, FilterApplyData);
                                        SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.ACTIVITY, value);
                                    }}
                                    options={searchTextList?.map((option: any) => option?.tag)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t("Search for Activities")}
                                            onChange={(inputChangeEvent: any) => {
                                                onInputChange(inputChangeEvent.target.value.trim())
                                            }}
                                            onKeyDown={(e: any) => {
                                                if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                                                    if (searchValue.trim() === '') {
                                                        e.preventDefault();
                                                    } else {
                                                        ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData);
                                                        e.preventDefault();
                                                    }
                                                }
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'text',
                                            }}
                                        />
                                    )}
                                />
                                <img
                                    draggable="false"
                                    src={search}
                                    alt="search"
                                    className={Search_icon_top}
                                    onClick={() => ActivitiesListApi(currentLat, currentLang, searchValue, FilterApplyData)}
                                />
                            </form>
                        </div>

                        <AppFilter
                            FilterPopOpen={FilterPopOpen}
                            onOpenPop={onOpenFilterPop}
                            onClosePop={onCloseFilterPop}
                            FilterData={FilterData}
                            FilterApply={FilterApply}
                            FilterApplyData={FilterApplyData}
                            initFilter={initFilter}
                            initSort={initSort}
                            handleApplySort={handleProceedSort}
                            type="activity"
                            color="#F0962C" />

                    </div>
                    <div className="Activity__RadiusMapKm">
                        {RadiusMapKm.map((item: any, i) => {
                            return (
                                <>
                                    <div className={FilterApplyData.radius === item.value ? "RadiusMapKm__wapper__active" : "RadiusMapKm__wapper"} key={i} onClick={() => handleChangeRadiusMapvalue(item.value)}>
                                        <img src={ActivityMapRadiusIcon} alt="" />
                                        <span>{item.lable}</span>
                                    </div >
                                </>
                            )
                        })}
                    </div>
                </div>

                <ActivitesModelDilogView triggerModel={triggerVal} Detailes={dailogActivitiesData} activitiesList={activitiesList} close={() => setTriggerVal(false)}
                    getLatAndLong={getLatAndLong} />
            </div>
        </>
    )
}

export default ActivitiesMapsView;