import "./TutorCategoryPopup.scss";
import { Box, Dialog, DialogContent, Rating, Slider } from "@mui/material";
import { useEffect, useState } from "react";
import { nullUndefinedEmptyCheck, SetSessionStorageData } from "../../../Utilities/Helpers/Helper";
import { FILTER_CONSTANT, ROUTES } from "../../../Constants/Constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Chemistry from "../../../Assets/Tutor_Subjects/Chemistry.svg";

const TutorCategoryPopup = ({ PopOpen, onClosePop, CourseData, FilterRadiusvalue, currentLat, currentLang, FilterApplyData }: any) => {
    const navigate = useNavigate();
    const { t } = useTranslation();


    /**Coursecategory Data Navigation*/
    const handlePassCoursecategory = (value: any) => {
        if (nullUndefinedEmptyCheck(value)) {
            SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.TUTOR, value);
            navigate(ROUTES.TUTORS_LIST_VIEW, {
                state: {
                    tutor_list: "",
                    search_text: value,
                    redius: FilterRadiusvalue[0],
                    lat: currentLat,
                    lang: currentLang,
                    map_show: false,
                    filter: FilterApplyData,
                },
            });
            onClosePop();
        }
    };

    return (
        <>
            <Dialog open={PopOpen}>
                <DialogContent>
                    <div className="TutorFilterPop_conatiner">
                        <div className="Filter_header__wapper">
                            <div className="header_title"> {t("Course Category List Data")}</div>
                            <span className="close__text" onClick={onClosePop}> {t("close")}</span>
                        </div>
                        <div className="Filter__body__contant">
                            <div className="Category_bobules__wapper">
                                {CourseData?.map((data: any, i: any) => {
                                    return (
                                        <>
                                            <div
                                                className="TutorCourse_Category_Img_wapper"
                                                key={i}
                                                onClick={() =>
                                                    handlePassCoursecategory(data?.course_category_name)
                                                }
                                            >
                                                <img
                                                    draggable="false"
                                                    src={
                                                        nullUndefinedEmptyCheck(data?.display_image)
                                                            ? data?.display_image
                                                            : Chemistry
                                                    }
                                                    alt="TutorImg"
                                                    onError={(e: any) => { e.target.src = Chemistry; }}
                                                    className=""
                                                />
                                                <span>{data?.course_category_name}</span>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default TutorCategoryPopup;