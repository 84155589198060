import "./AuthScreenCarousel.scss";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import { AUTHENTICATION, GLOBAL_CONSTANTS } from "../../Constants/Constants";
import AliceCarousel from "react-alice-carousel";
import { useTranslation } from "react-i18next";
import carouselFirst from "../../Assets/Authentication/carouselFirst.png";
import carouselSecond from "../../Assets/Authentication/carouselSecond.png";
import carouselThird from "../../Assets/Authentication/carouselThird.png";
import carouselFourth from "../../Assets/Authentication/carouselFourth.png";

const AuthScreenCarousel = () => {
  const { t } = useTranslation();

  const CAROUSEL_ITEMS = [
    {
      image: carouselFirst,
      heading: t("splashHeading1"),
      subHeading: t("splashSubHeading1")
    },
    {
      image: carouselSecond,
      heading: t("splashHeading2"),
      subHeading: t("splashSubHeading2")
    },
    {
      image: carouselThird,
      heading: t("splashHeading3"),
      subHeading: t("splashSubHeading3")
    },
    {
      image: carouselFourth,
      heading: t("splashHeading4"),
      subHeading: t("splashSubHeading4")
    }
  ];

  const responsive = {
    0: {
      items: 1,
    },
  };

  const handleSlideChange = ({ item }: { item: number }) => {
    // Optionally handle slide changes if needed
  };

  return (
    <div className="carousel_image_wrapper">
      <AliceCarousel
        infinite
        autoPlay={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
        autoPlayInterval={AUTHENTICATION.CAROUSEL_CONSTANTS.AUTO_PLAY_INTERVAL}
        mouseTracking
        responsive={responsive}
        items={CAROUSEL_ITEMS.map((item, index) => (
          <div key={index} className="my_carousel_item">
            <img
              src={item.image}
              alt={`Slide ${index + 1}`}
              className={`LoginCarousel__img LoginCarousel__img_bg${index + 1}`}
            />
            <div className="carousel_content">
              <div className="carousel_content_title">{item.heading}</div>
              <div className="carousel_content_description">{item.subHeading}</div>
            </div>
          </div>
        ))}
        onSlideChanged={handleSlideChange}
      />
    </div>
  );
};

export default AuthScreenCarousel;










// import "./AuthScreenCarousel.scss";
// import "react-alice-carousel/lib/scss/alice-carousel.scss";
// import { AUTHENTICATION, GLOBAL_CONSTANTS } from "../../Constants/Constants";
// import AliceCarousel from "react-alice-carousel";
// import { useTranslation } from "react-i18next";
// import { useState, useEffect } from "react";
// import carouselFirst from "../../Assets/Authentication/carouselFirst.png";
// import carouselSecond from "../../Assets/Authentication/carouselSecond.png";
// import carouselThird from "../../Assets/Authentication/carouselThird.png";
// import carouselFourth from "../../Assets/Authentication/carouselFourth.png";

// const AuthScreenCarousel = () => {
//   const { t } = useTranslation();
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const responsive = {
//     0: {
//       items: 1,
//     },
//   };

//   const CAROUSEL_IMAGES = [
//     <img
//       key="1"
//       src={carouselFirst}
//       alt="Learn and Play"
//       className="LoginCarousel__img LoginCarousel__img_bg1"
//     />,
//     <img
//       key="2"
//       src={carouselSecond}
//       alt="Learn and Play"
//       className="LoginCarousel__img LoginCarousel__img_bg2"
//     />,
//     <img
//       key="3"
//       src={carouselThird}
//       alt="Learn and Play"
//       className="LoginCarousel__img LoginCarousel__img_bg3"
//     />,
//     <img
//       key="4"
//       src={carouselFourth}
//       alt="Learn and Play"
//       className="LoginCarousel__img LoginCarousel__img_bg4"
//     />,
//   ];

//   const CAROUSEL_HEADING = [
//     t("splashHeading1"),
//     t("splashHeading2"),
//     t("splashHeading3"),
//     t("splashHeading4"),
//   ];

//   const CAROUSEL_SUB_HEADING = [
//     t("splashSubHeading1"),
//     t("splashSubHeading2"),
//     t("splashSubHeading3"),
//     t("splashSubHeading4"),
//   ];

//   const handleSlideChange = ({ item }: { item: number }) => {
//     setCurrentIndex(item);
//   };

//   // Debugging to ensure currentIndex is set correctly
//   useEffect(() => {
//   }, [currentIndex]);

//   console.log(CAROUSEL_HEADING,"CAROSAL");
  

//   return (
//     <>
//       <div className="carousel_image_wrapper">
//         <AliceCarousel
//           infinite
//           autoPlay={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
//           autoPlayInterval={
//             AUTHENTICATION.CAROUSEL_CONSTANTS.AUTO_PLAY_INTERVAL
//           }
//           mouseTracking
//           responsive={responsive}
//           items={CAROUSEL_IMAGES}
//           onSlideChanged={handleSlideChange}
//         />
//         <div className="carousel_contents_wrapper">
//           <div className="carousel_content_title">
//             {CAROUSEL_HEADING[currentIndex]}
//           </div>
//           <div className="carousel_content_description"> 
//             {CAROUSEL_SUB_HEADING[currentIndex]}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AuthScreenCarousel;
