import "./EditProfile.scss";
import { Button, Dialog, Divider, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { ACCOUNTS, API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES, S3DataGetApi, } from "../../Constants/Constants";
import { useEffect, useRef, useState } from "react";

/**Assets */
import defaultProfile from "../../Assets/Authentication/defaultProfile.svg";
import uploadArrow from "../../Assets/Authentication/uploadArrow.svg";
import edit from "../../Assets/Header/edit.svg";
import { GetSessionStorageData, SetSessionStorageData, nullUndefinedEmptyCheck, uploadFileToS3, } from "../../Utilities/Helpers/Helper";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";
import ProfileAvatarsDialog from "../ProfileAvatarsDialog/ProfileAvatarsDialog";
import moment from "moment";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import CancelIcon from '@mui/icons-material/Cancel';
import OtpVerficationArrowIcon from "../../Assets/ThemePark/OtpVerficationArrowIcon.svg";
import OtpInput from 'react-otp-input';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const EditProfile = () => {
    const navigate = useNavigate();
    const DataKeys: any = useSelector((state: any) => state.s3DataKeyReducer.datakey);
    /**Initial states */
    const [mobileEmailTab, setMobileEmailTab] = useState(
        GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT
    );
    const { t } = useTranslation();
    const [getprofileData, setGetprofileData] = useState<any>("");
    const [profilePicture, setProfilePicture] = useState("");
    const profileInput = useRef<HTMLInputElement>(null);
    const personalDetailsRef = useRef<any>();
    const [openUserLoginOtp, setOpenUserLoginOtp] = useState(false);
    const [newEmailOpen, setNewEmailOpen] = useState(false);
    const [newMobileNumberOpen, setNewMobileNumberOpen] = useState(false);
    const [Otpvalue, setOtpvalue] = useState<any>("");
    const [changeEmail, setChangeEmail] = useState<any>("");
    const [changeMobile, setChangeMobile] = useState<any>("");
    const [showResend, setShowResend] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    const [timer, setTimer] = useState(AUTHENTICATION.OTP_VERIFICATION_TIMER.TIMER_INITIAL_VALUE);
    const [submitted, setSubmitted] = useState(false);
    const [screenType, setScreenType] = useState(1);
    const [LoginType, setLoginType] = useState('');
    const [userId, setUserId] = useState<any>(GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID));
    const [openProfileAvatars, setOpenProfileAvatars] = useState(
        GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
    );
    const [isSubmitted, setIsSubmitted] = useState(
        GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
    );
    const [validNumber, setValidNumber] = useState(
        GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
    );
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobileNumberTouched, setMobileNumberTouched] = useState(
        GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
    );
    const [changeValidNumber, setChangeValidNumber] = useState(
        GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
    );
    const [changemobileNumberTouched, setChangeMobileNumberTouched] = useState(
        GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
    );
    /**Personal details form initial values */
    const personalDetailsValue = {
        [ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME]: getprofileData?.name ? getprofileData?.name?.split(" ")[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT] : "",
        [ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME]: getprofileData?.name ? getprofileData?.name?.split(" ")[GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT] : "",
        [ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL]: getprofileData?.email ? getprofileData?.email : "",
        [AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE]: getprofileData?.phone_number ? getprofileData?.phone_number : "",
        [ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]: getprofileData?.dob ? getprofileData?.dob : "",
    };

    const childDetailsValue = {
        [ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME]: getprofileData?.name ? getprofileData?.name?.split(" ")[GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT] : "",
        [ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME]: getprofileData?.name ? getprofileData?.name?.split(" ")[GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT] : "",
        [ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]: getprofileData?.dob ? getprofileData?.dob : "",
    };
    /**Personal details validation schema */
    const getPersonalDetailsValidationSchema: any = Yup.object().shape<any>({
        [ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME]: Yup.string()
            .trim(AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.SPACE)
            .strict(true)
            .required(
                ACCOUNTS.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.FIRST_NAME_REQUIRED
            ),
        [ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME]: Yup.string()
            .trim(AUTHENTICATION.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.SPACE)
            .strict(true)
            .required(
                ACCOUNTS.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.LAST_NAME_REQUIRED
            ),
        [ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]: Yup.string().required(
            ACCOUNTS.PERSONAL_DETAILS_FIELDS_ERROR_MESSAGES.DATE_OF_BIRTH_REQUIRED
        ),
    });

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required'),
    });

    const validationSchemaMobileNumber = Yup.object().shape({
        mobile: Yup.string().required('Mobile number is required'),
    });

    /**Handle tab change */
    const handleProfileSwitch = (
        event: React.SyntheticEvent,
        tabIndex: number
    ) => {
        if (tabIndex === GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT) {
            // setOpenProfileAvatars(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
        }
        setMobileEmailTab(tabIndex);
    };

    /**Handle upload profile picture on clicking other buttons */
    const handleUploadProfilePicture = () => {
        if (profileInput.current) {
            profileInput.current.value = "";
            profileInput.current?.click();
        }
    };

    // useEffect(() => {
    //     if (isSubmitted) {
    //         profilePictureCheck();
    //     }
    // }, [profilePicture]);

    useEffect(() => {
        if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
            getUserProfile();
        } else {
            getChildProfile();
        }
    }, []);

    /**Get user Api*/
    const getUserProfile = () => {
        axiosInstance
            .get(`${API_END_POINTS.EDIT_PROFILE}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    setGetprofileData(Response?.data?.result)
                    setMobileNumber(Response?.data?.result?.phone_number)
                    setProfilePicture(Response?.data?.result?.profile_image)
                }
            })
            .catch(() => { });
    };

    /**Get child Api*/
    const getChildProfile = () => {
        axiosInstance
            .get(`${API_END_POINTS.CHILD_EDIT_PROFILE}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    setGetprofileData(Response?.data?.result)
                    // setMobileNumber(Response?.data?.result?.phone_number)
                    setProfilePicture(Response?.data?.result?.profile_image)
                }
            })
            .catch(() => { });
    };


    /**Check for empty profile picture */
    const profilePictureCheck = () => {
        profilePicture === ""
            ? setIsSubmitted(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE)
            : setIsSubmitted(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    };

    /**Handle profile change */
    const handleChange = async (event: any) => {
        if (event.target.files[0].type.split("/")[0] === "image") {
            try {
                const uploadedImage: any = await S3DataGetApi(event);
                setProfilePicture(uploadedImage);
            } catch (error) {
            }
        } else {
            toast.error('Please choose file Image format', {
                position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
            });
        }
    };

    useEffect(() => {
        personalDetailsRef.current.setFieldError(
            ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL,
            ""
        );
    }, [personalDetailsRef]);

    /**Handle close profile avatar dialog */
    const handleCloseProfileAvatarDialog = (avatarImage: any) => {
        setOpenProfileAvatars(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
        if (nullUndefinedEmptyCheck(avatarImage)) {
            setMobileEmailTab(GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT);
            setProfilePicture(avatarImage);
        } else {
            setMobileEmailTab(GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT);
        }
    };

    /**Update profile */
    const UpdateProfile = (values: any, actions: any) => {

        var payload = {
            first_name: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME],
            last_name: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME],
            profile_image: profilePicture ? profilePicture : null,
            email: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.EMAIL],
            dob: moment(
                values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]
            ).format(AUTHENTICATION.DATE_FORMAT.DATE_OF_BIRTH),
        }

        axiosInstance
            .put(`${API_END_POINTS.EDIT_PROFILE}`, payload)
            .then((Response: any) => {
                toast.success(Response.data.message);
                SetSessionStorageData(
                    AUTHENTICATION.SHARED.NAME,
                    Response.data.result?.data?.user_info?.name
                );
                SetSessionStorageData(
                    AUTHENTICATION.SHARED.PARENT_DOB,
                    payload.dob
                );
                if (profilePicture) {
                    SetSessionStorageData(
                        AUTHENTICATION.SHARED.PROFILE_PICTURE,
                        Response?.data?.result?.data?.user_info?.image
                    );
                } else {
                    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE, "");
                }
                getUserProfile();
                navigate(ROUTES.HOMEPAGE);
            })
            .catch(() => { });
    };

    /**Update child profile */
    const UpdateChildProfile = (values: any, actions: any) => {

        var payload = {
            first_name: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.FIRST_NAME],
            last_name: values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.LAST_NAME],
            profile_image: profilePicture ? profilePicture : null,
            dob: moment(
                values[AUTHENTICATION.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH]
            ).format(AUTHENTICATION.DATE_FORMAT.DATE_OF_BIRTH),
        }

        axiosInstance
            .put(`${API_END_POINTS.CHILD_EDIT_PROFILE}`, payload)
            .then((Response: any) => {
                toast.success(Response.data.message);
                SetSessionStorageData(
                    AUTHENTICATION.SHARED.NAME,
                    Response.data.result?.data?.user_info?.name
                );
                if (profilePicture) {
                    SetSessionStorageData(
                        AUTHENTICATION.SHARED.PROFILE_PICTURE,
                        Response?.data?.result?.data?.user_info?.image
                    );
                } else {
                    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE, "");
                }
                getChildProfile();
                navigate(ROUTES.HOMEPAGE);
            })
            .catch(() => { });
    };

    useEffect(() => {
        if (openUserLoginOtp) {
            updateTimer();
        }
    }, [timer, openUserLoginOtp]);

    /**Update timer */
    const updateTimer = () => {
        setTimeout(() => {
            if (timer > GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT) {
                setTimer(timer - GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT);
            } else {
                setShowResend(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
            }
        }, AUTHENTICATION.OTP_VERIFICATION_TIMER.OTP_VERIFICATION_TIMEOUT);
    };

    const renderInput = (inputProps: any, index: number) => {
        return (
            <input
                key={index}
                type="number"
                {...inputProps}
                className='changeEmail-usert-otp-input'
            />
        );
    };

    const handleChangeOtp = (otp: string) => {
        setOtpvalue(otp);
    };

    /**Resend Otp */
    const resendOtp = () => {
        setShowResend(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
        verifyChangeEmail(changeEmail, changeMobile, LoginType)
    };

    const handleOpenExitEmailOtp = (value: any) => {
        setChangeEmail(value);
        setChangeMobile("")
        verifyChangeEmail(value, "", 1)

    }
    const handleOpenExitMobileOtp = (value: any) => {
        setChangeEmail("");
        setChangeMobile(value)
        verifyChangeEmail("", value, 0)

    }
    const verifyChangeEmail = (email: any, mobile: any, loginType: any) => {
        setLoginType(loginType)
        let payload = {
            email: email,
            phone_number: mobile,
            screen: screenType,
            user_id: userId,
        }

        axiosInstance
            .put(API_END_POINTS.CHANGE_EMAIL_UPDATE + loginType, payload)
            .then((res: any) => {
                if (
                    res.data[AUTHENTICATION.SHARED.STATUS] ===
                    AUTHENTICATION.SHARED.TRUE
                ) {
                    setTimer(AUTHENTICATION.OTP_VERIFICATION_TIMER.TIMER_INITIAL_VALUE);
                    updateTimer();
                    setOtpvalue("");
                    setSubmitted(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
                    setOpenUserLoginOtp(true);
                    toast.success(res.data.message, {
                        position: "top-right"
                    });
                }
            })
            .catch(() => {
                handleCloseAll();
            });
    };
    const verifyOtp = () => {
        setSubmitted(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);

        let payload = {
            email: changeEmail,
            otp: Otpvalue,
            phone_number: changeMobile,
            screen: screenType,
            user_id: userId,
        }

        if (Otpvalue) {
            axiosInstance
                .put(API_END_POINTS.CHANGE_EMAIL_UPDATE_OTP + LoginType, payload)
                .then((res: any) => {
                    if (
                        res.data[AUTHENTICATION.SHARED.STATUS] ===
                        AUTHENTICATION.SHARED.TRUE
                    ) {

                        if (screenType == 1) {
                            setOpenUserLoginOtp(false);
                            setOtpvalue("");
                            setScreenType(2);
                            if (changeEmail) {
                                setNewEmailOpen(true)
                            } else {
                                setNewMobileNumberOpen(true)
                            }
                        } else {
                            if (changeEmail) {
                                SetSessionStorageData(AUTHENTICATION.SHARED.EMAIL, changeEmail);
                            } else {
                                SetSessionStorageData(AUTHENTICATION.SHARED.MOBILE, changeMobile);
                            }
                            setOpenUserLoginOtp(false);
                            navigate(ROUTES.HOMEPAGE);
                        }
                        toast.success(res.data.message, {
                            position: "top-right"
                        });
                    }
                })
                .catch(() => {
                    handleCloseAll();
                });
        }

    };

    const handleCloseAll = () => {
        setScreenType(1);
        setOpenUserLoginOtp(false);
        setNewEmailOpen(false);
        setNewMobileNumberOpen(false)
        setChangeEmail("");
        setChangeMobile("");
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });
    /*Below class name based on English Arabic*/
    const ExitEmailIconLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ExitEmailIconLang_Eng" : "ExitEmailIconLang_Arab";
    const EditeProfilePhoneNumLan = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "EditeProfilePhoneNumLan_Eng" : "EditeProfilePhoneNumLan_Arab";

    return (
        <>
            <div className="profile_pic_fields_wrapper">
                <div className="profile_pic_tabs_container">
                    <div className="profile_pic_upload_container">
                        <input
                            ref={profileInput}
                            className="file_input"
                            type="file"
                            accept="image/png,image/jpg,image/jpeg,image/svg"
                            onChange={handleChange}
                        />
                        <div
                            onClick={() => {
                                mobileEmailTab === GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT
                                    ? handleUploadProfilePicture()
                                    : setOpenProfileAvatars(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
                            }}
                            className="profile_upload_container"
                        >
                            <img
                                src={profilePicture ? profilePicture : defaultProfile}
                                alt="Learn and Play"
                                className="profile_pic"
                            />
                            <div className="upload_arrow_wrapper" title={profilePicture ? "Edit" : "Upload"}>
                                <div className="upload_arrow_container">
                                    <img
                                        className="upload_arrow"
                                        src={profilePicture ? edit : uploadArrow}
                                        alt="Learn and Play"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {profilePicture && (
                        <div className="remove_arrow_wrapper" title="Remove Profile" onClick={() => setProfilePicture("")}>
                            <div className="remove_arrow_text">{t("Remove Profile Image")}</div>
                            {/* <CancelIcon className="remove_arrow_icon" /> */}
                        </div>
                    )}
                    {isSubmitted && (
                        <div className="errorMessage">
                            {t("Please Upload a profile picture or select an avatar")}
                        </div>
                    )}
                    <Tabs value={mobileEmailTab} onChange={handleProfileSwitch}>
                        <Tab label="Profile Image" />
                        <Tab label="Avatar" />
                    </Tabs>
                    <ProfileAvatarsDialog
                        openProfileAvatars={openProfileAvatars}
                        profilePicture={profilePicture}
                        handleCloseProfileAvatarDialog={handleCloseProfileAvatarDialog}
                    />
                </div>
                <div className="profile_fields_wrapper">
                    <Formik
                        initialValues={GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ?
                            personalDetailsValue : childDetailsValue}
                        validationSchema={getPersonalDetailsValidationSchema}
                        onSubmit={(values, actions) => {
                            if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
                                UpdateProfile(values, actions);
                            } else {
                                UpdateChildProfile(values, actions);
                            }
                        }}
                        enableReinitialize={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                        innerRef={personalDetailsRef}
                    >
                        {(emailFormikProps: any) => {
                            return (
                                <Form className="email_login_form_wrapper">
                                    <div className="accounts_label_field_error_messages_container label_field_error_messages_container">
                                        <label className="field_label">{t("First name")}</label>
                                        <Field
                                            placeholder={t("Enter your first name")}
                                            name={ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME}
                                            disabled={GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? false : true}
                                        />
                                        {emailFormikProps.touched[
                                            ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME
                                        ] &&
                                            emailFormikProps.errors[
                                            ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME
                                            ] ? (
                                            <div className="errorMessage">
                                                {
                                                    emailFormikProps?.errors[
                                                    ACCOUNTS.PERSONAL_DETAILS_FIELDS.FIRST_NAME
                                                    ]
                                                }
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="accounts_label_field_error_messages_container label_field_error_messages_container">
                                        <label className="field_label">{t("Last name")}</label>
                                        <Field
                                            placeholder={t("Enter your last name")}
                                            name={ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME}
                                            disabled={GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? false : true}
                                        />
                                        {emailFormikProps.touched[
                                            ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME
                                        ] &&
                                            emailFormikProps.errors[
                                            ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME
                                            ] ? (
                                            <div className="errorMessage">
                                                {
                                                    emailFormikProps.errors[
                                                    ACCOUNTS.PERSONAL_DETAILS_FIELDS.LAST_NAME
                                                    ]
                                                }
                                            </div>
                                        ) : null}
                                    </div>

                                    {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                                        <>
                                            <div className="accounts_label_field_error_messages_container label_field_error_messages_container ExitEmail__wapper_ email-edite-icon">
                                                <label className="field_label">{t("Email")}</label>
                                                <Field disabled
                                                    placeholder={t("Enter your Email")}
                                                    name={ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL}
                                                />
                                                {emailFormikProps.touched[
                                                    ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL
                                                ] &&
                                                    emailFormikProps.errors[
                                                    ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL
                                                    ] ? (
                                                    <div className="errorMessage">
                                                        {
                                                            emailFormikProps.errors[
                                                            ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL
                                                            ]
                                                        }
                                                    </div>
                                                ) : null}
                                                <EditIcon onClick={() => handleOpenExitEmailOtp(emailFormikProps?.values[ACCOUNTS.PERSONAL_DETAILS_FIELDS.EMAIL])} className={`${ExitEmailIconLang} ExitEmail_icon`} />
                                            </div>
                                            {mobileNumber && (
                                                <div className={`accounts_label_field_error_messages_container phone_input_wrapper label_field_error_messages_container ExitEmail__wapper_ ${EditeProfilePhoneNumLan} edite_profile_phone_num pnumber-edite-icon`}>
                                                    <label className="field_label">{t("Phone number")}</label>
                                                    {/* <div className="accounts_label_field_error_messages_container phone_input_wrapper label_field_error_messages_container ExitEmail__wapper_">
                                                    <label className="field_label">{t("Phone number")}</label> */}
                                                    <PhoneInput
                                                        disabled
                                                        value={mobileNumber}
                                                        inputProps={{
                                                            className: "",
                                                            name: `${AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE}`,
                                                        }}
                                                        country={"ae"}
                                                        countryCodeEditable={
                                                            GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
                                                        }
                                                        enableSearch={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                                                        placeholder={t("Enter your Phone number")}
                                                        onBlur={() => {
                                                            setMobileNumberTouched(
                                                                GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
                                                            );
                                                        }}
                                                        onChange={(e) => {
                                                            emailFormikProps.setFieldValue(
                                                                AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE,
                                                                `+${e}`
                                                            );
                                                            setTimeout(() => {
                                                                emailFormikProps.validateField(
                                                                    AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                                                                );
                                                                setValidNumber(isValidPhoneNumber(`+${e}`));
                                                            }, GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT);
                                                        }}
                                                    />
                                                    {validNumber === GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE &&
                                                        emailFormikProps.touched[
                                                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                                                        ] &&
                                                        emailFormikProps.values[
                                                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                                                        ] !== "" ? (
                                                        <div className="errorMessage">
                                                            {
                                                                AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES
                                                                    .INVALID_PHONE_NUMBER
                                                            }
                                                        </div>
                                                    ) : null}
                                                    {mobileNumberTouched &&
                                                        validNumber === GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE ? (
                                                        <div className="errorMessage">
                                                            {
                                                                emailFormikProps.errors[
                                                                AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                                                                ]
                                                            }
                                                        </div>
                                                    ) : null}
                                                    <EditIcon onClick={() => handleOpenExitMobileOtp(mobileNumber)} className={`${ExitEmailIconLang} ExitEmail_icon`} />
                                                </div>
                                            )}
                                        </>
                                    ) : ("")}

                                    <div className="label_date_picker_field_error_messages_container accounts_label_date_picker_field_error_messages_container">
                                        <label className="field_label">{t("Date of birth")}</label>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                className=""
                                                disableFuture
                                                format="dd-MM-yyyy"
                                                value={
                                                    new Date(
                                                        emailFormikProps.values[
                                                        ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                                                        ]
                                                    )
                                                }
                                                disabled={GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? false : true}
                                                onChange={(date) => {
                                                    const formattedDate = date
                                                        ? format(date, "yyyy-MM-dd")
                                                        : "";
                                                    emailFormikProps.setFieldValue(
                                                        ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH,
                                                        new Date(formattedDate)
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                        {emailFormikProps.touched[
                                            ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                                        ] &&
                                            emailFormikProps.errors[
                                            ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                                            ] ? (
                                            <div className="errorMessage">
                                                {
                                                    emailFormikProps.errors[
                                                    ACCOUNTS.PERSONAL_DETAILS_FIELDS.DATE_OF_BIRTH
                                                    ]
                                                }
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="action_buttons_wrapper">
                                        <button type="button" className="cancel_button" onClick={() => navigate(-1)}>
                                            {t("Cancel")}
                                        </button>
                                        <button
                                            type="submit"
                                            // onClick={profilePictureCheck}
                                            className="add_profile_sign_up_button"
                                        >
                                            {t("Apply")}
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            {openUserLoginOtp && (
                <div className="overlay-ModelBackround">
                    <Dialog
                        className='guest-user-login-otp-model'
                        open={openUserLoginOtp}
                        keepMounted
                        onClose={handleCloseAll}
                        aria-describedby="alert-dialog-slide-description"
                    >

                        <div className='user-content-container'>
                            <div className='guest-user-otp-head'>
                                <img src={OtpVerficationArrowIcon} alt="arrowIcon" style={{ cursor: 'pointer' }} onClick={handleCloseAll} />
                                <p>{t("OTP Verification")}</p>
                            </div>

                            <Divider />

                            <div className='guest-user-otp-body'>
                                <div>
                                    {t("OTP send to your")}<br />
                                    <span> {changeEmail ? changeEmail : changeMobile}</span>
                                </div>
                                <OtpInput
                                    value={Otpvalue}
                                    onChange={handleChangeOtp}
                                    numInputs={4}
                                    renderInput={renderInput}
                                />
                                {submitted && Otpvalue === "" && (
                                    <div className="errorMessageOtp">
                                        {AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES.OTP_REQUIRED}
                                    </div>
                                )}

                                {!showResend && (
                                    <p className='changeEmail-user-model-timing'>{t("Time Remaining:")}  {timer} {t("secs")}</p>
                                )}
                            </div>

                            <div className='changeEmail-user-verify-button'>
                                <Button variant="contained" color="primary" onClick={verifyOtp}>
                                    {t("Verify & Proceed")}
                                </Button>
                            </div>
                            {showResend && (
                                <div className="resend_otp_button mt-3">
                                    {t("Didn’t receive the OTP?")}{" "}
                                    <span className="changeEmail-resend_otp" onClick={resendOtp}>
                                        {t("Resend OTP")}
                                    </span>
                                </div>
                            )}

                        </div>

                    </Dialog>
                </div>
            )}
            {newEmailOpen && (
                <div className="overlay-ModelBackround">
                    <Dialog
                        className='guest-user-login-otp-model'
                        open={newEmailOpen}
                        keepMounted
                        onClose={handleCloseAll}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <div
                            onClick={handleCloseAll}
                            className="skip_button" style={{ top: '15px' }}
                        >
                            <CloseIcon className="close__icon" />
                        </div>

                        <div className='user-content-container'>
                            <div className='guest-user-otp-head'>
                                <p>{t("New Email ID")}</p>
                            </div>
                            <Divider />
                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values, actions) => {
                                    verifyChangeEmail(values.email, "", 1)
                                    setChangeEmail(values.email);
                                    setNewEmailOpen(false)
                                    // resetForm();
                                }}
                            >
                                {({ isSubmitting, resetForm }) => (
                                    <Form>

                                        <div className="custom-new-email-wapper">
                                            <div className="new-email-text_contant">
                                                {t("Please enter your new email address")}
                                            </div>
                                            <label>{t("Email")}</label>
                                            <Field
                                                className="email-input-field"
                                                name='email'
                                                as="input"
                                                type="text"
                                                placeholder={t("Email")}
                                            />
                                            <ErrorMessage
                                                name='email'
                                                component="div"
                                                className="error-message"
                                            />
                                            <button className='submit-button' type="submit" disabled={isSubmitting}>
                                                {t("Send OTP")}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    </Dialog>
                </div>
            )}
            {newMobileNumberOpen && (
                <div className="overlay-ModelBackround">
                    <Dialog
                        className='guest-user-login-otp-model'
                        open={newMobileNumberOpen}
                        keepMounted
                        onClose={handleCloseAll}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <div
                            onClick={handleCloseAll}
                            className="skip_button" style={{ top: '15px' }}
                        >
                            <CloseIcon className="close__icon" />
                        </div>

                        <div className='user-content-container'>
                            <div className='guest-user-otp-head'>
                                <p>{t("Update new mobile number")}</p>
                            </div>
                            <Divider />
                            <Formik
                                initialValues={{
                                    mobile: '',
                                }}
                                validationSchema={validationSchemaMobileNumber}
                                onSubmit={(values, actions) => {
                                    if (changeValidNumber) {
                                        verifyChangeEmail("", values.mobile, 0)
                                        setNewMobileNumberOpen(false)
                                        setChangeMobile(values.mobile);
                                    } else {
                                        setChangeMobileNumberTouched(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
                                    }
                                }}
                            >
                                {(isSubmitting: any) => (
                                    <Form>

                                        <div className="custom-new-email-wapper">
                                            <div className="new-email-text_contant">
                                                {t("Please enter your new Phone number")}
                                            </div>
                                            <label>{t("Phone number")}</label>
                                            <PhoneInput
                                                inputProps={{
                                                    className: "phone-input-field",
                                                    name: `${AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE}`,
                                                }}
                                                country={"ae"}
                                                countryCodeEditable={
                                                    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
                                                }
                                                enableSearch={GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE}
                                                placeholder={t("Enter your Phone number")}
                                                onBlur={() => {
                                                    setChangeMobileNumberTouched(
                                                        GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
                                                    );
                                                }}
                                                onChange={(e) => {
                                                    isSubmitting.setFieldValue(
                                                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE,
                                                        `+${e}`
                                                    );
                                                    setTimeout(() => {
                                                        isSubmitting.validateField(
                                                            AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                                                        );
                                                        setChangeValidNumber(isValidPhoneNumber(`+${e}`));
                                                    }, GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT);
                                                }}
                                            />
                                            {changeValidNumber === GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE &&
                                                isSubmitting.touched[
                                                AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                                                ] &&
                                                isSubmitting.values[
                                                AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                                                ] !== "" ? (
                                                <div className="errorMessage">
                                                    {
                                                        AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES
                                                            .INVALID_PHONE_NUMBER
                                                    }
                                                </div>
                                            ) : null}
                                            {changemobileNumberTouched &&
                                                changeValidNumber === GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE ? (
                                                <div className="errorMessage">
                                                    {
                                                        isSubmitting.errors[
                                                        AUTHENTICATION.PERSONAL_DETAILS_FIELDS.MOBILE
                                                        ]
                                                    }
                                                </div>
                                            ) : null}
                                            <button className='submit-button mt-5' type="submit">
                                                {t("Send OTP")}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    </Dialog>
                </div>
            )}
        </>
    )
}

export default EditProfile;