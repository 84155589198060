import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from "react";
import pdf_icon from "../Assets/Global/pdf_icon.svg";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import toast from 'react-hot-toast';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const PdfDownloadFile = ({ open, handleClose, data }: any) => {

    const PdffileName = (name: any) => {
        const urlParts = name?.split('/');
        return urlParts[urlParts?.length - 1];
    }

    const DownloadPdf = (data: any) => {

        fetch(data, {
            method: "GET",
        })
            .then((resp) => resp.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                const urlParts = data?.split('/');
                const fileName = urlParts[urlParts?.length - 1];
                a.style.display = "none";
                a.href = url;
                a.download = fileName; // the filename you want
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setTimeout(() => {
                    toast.success("Download Pdf Successfully", {
                        position: "top-right",
                    });
                }, 500);
            })
            .catch(() => { });

    };
    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="UniversityChatRequest__dailog__box"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <div className='Pdf__title'>Download Files</div>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <DialogContent>

                    <div className='PdfDownloadFile__container'>
                        {data?.map((item: any) => {
                            return (
                                <div className='contant__wapper' onClick={() => DownloadPdf(item)}>
                                    <img src={pdf_icon} alt="" />
                                    <div className='name'>{PdffileName(item)}</div>
                                </div>
                            )
                        })}
                    </div>

                </DialogContent>


            </BootstrapDialog>
        </>
    )
}

export default PdfDownloadFile;