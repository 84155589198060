import React, { useState } from "react";
import { nullUndefinedEmptyCheck } from "../Utilities/Helpers/Helper";
import { useTranslation } from "react-i18next";

const ViewMoreLess = ({ text, maxLength }: any) => {
  const [showFullText, setShowFullText] = useState(false);
  const { t } = useTranslation();

  const toggleShowMore = () => {
    setShowFullText(!showFullText);
  };

  const displayText = showFullText ? text : text?.slice(0, maxLength);
  return (
    <div className="ViewMoreLess_description">
      <div className="description__contant">
        {nullUndefinedEmptyCheck(text) ? displayText : "N/A"}
        {text?.length > maxLength && nullUndefinedEmptyCheck(text) && (
          <b className={showFullText ? "description__Btn description__Btn_viewless" : "description__Btn"} onClick={toggleShowMore}>
            {showFullText ? t("view less") : "..." + t("view more")}
          </b>
        )}
      </div>
    </div>
  );
};

export default ViewMoreLess;
