import "./HomePage.scss";
import HomeOffersCarousel from "./../../Components/HomeOffersCarousel/HomeOffersCarousel";
import { useEffect, useRef, useState } from "react";
import HomeServiceCards from "../../Components/HomeServiceCards/HomeServiceCards";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  HOMEPAGE,
  ROUTES,
} from "../../Constants/Constants";
import TopServiceCards from "../../Components/TopServiceCards/TopServiceCards";

/**Assets */
import search from "../../Assets/home/searchPink.svg";
import knowMoreArrow from "../../Assets/home/knowMoreArrow.svg";
import school from "../../Assets/home/school.svg";
import university from "../../Assets/home/university.svg";
import themePark from "../../Assets/home/themeParks.svg";
import playStore from "../../Assets/home/playStore.svg";
import appStore from "../../Assets/home/appStore.svg";
import tutor from "../../Assets/home/tutor.svg";
import activities from "../../Assets/home/activities.svg";
import navigation_arrows from "../../Assets/home/nav_arrow.svg";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { useNavigate, useLocation } from "react-router-dom";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { GetSessionStorageData, SetSessionStorageData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import axios from "axios";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
// import required modules
import { Autoplay, Pagination, Navigation, Mousewheel, Keyboard, Grid } from 'swiper/modules';
import { useTranslation } from "react-i18next";

const HomePage = () => {
  /**Initial states */
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [headerData, setHeaderData] = useState<any>([]);
  const [schoolData, setSchoolData] = useState<any>("");
  const [universityData, setUniversityData] = useState<any>("");
  const [activitiesData, setActivitiesData] = useState<any>("");
  const [themeParksData, setThemeParksData] = useState<any>("");
  const [tutorsData, setTutorsdata] = useState<any>("");
  const [trainersData, setTrainersData] = useState<any>("");
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [isTutorContentOverlapping, setIsTutorContentOverlapping] =
    useState(false);
  const [isActivitiesContentOverlapping, setIsActivitiesContentOverlapping] =
    useState(false);
  const topServiceCursor = useRef<HTMLDivElement>(null);
  const filter = createFilterOptions();
  const navigate = useNavigate();
  const route = useLocation();
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });
  /**get selected or current locationName from redux */
  const HeaderLocationName: any = useSelector((state: any) => {
    return state.locationNameReducer.locationName;
  });

  useEffect(() => { }, [searchValue]);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const token = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
    if (token && GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
      axiosInstance
        .get(`${API_END_POINTS.PREFERENCE_SELECTED_LIST}`)
        .then((Response: any) => {
          var data = Response?.data?.result?.data[0]
          getHomePageData(data);
        })
        .catch(() => { });
    } else {
      getHomePageData("");
    }
  }, [HeaderLoction, HeaderLocationName, route?.state?.Preference]);

  const [searchTextList, setSearchTextList] = useState<any>([]);

  /**Get access token from session storage */
  const token = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);

  /**Get avatar images */
  const getHomePageData = (data: any) => {
    var payload = {
      tutor: data?.tutor ?? [],
      recreationalpersonal: data?.recreationalpersonal ?? [],
      recreationalcamp: data?.recreationalcamp ?? [],
      school: data?.school ?? [],
      university: data?.university ?? [],
      lat: HeaderLoction?.lat?.toString(),
      lng: HeaderLoction?.lng?.toString(),
    };

    axiosInstance
      .post(
        `${API_END_POINTS.HOMEPAGE}`,
        route?.state?.Preference
          ? route?.state?.Preference
          : payload
      )
      .then((homePageResponse: any) => {
        if (
          homePageResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          setHeaderData(homePageResponse?.data?.result?.data[0]?.header[0]);
          setSchoolData(homePageResponse.data.result.data[0].school_section);
          setUniversityData(
            homePageResponse.data.result.data[0].university_section
          );
          setActivitiesData(
            homePageResponse.data.result.data[0].activities_section
          );
          setThemeParksData(
            homePageResponse.data.result.data[0].themepark_section
          );
          setTutorsdata(homePageResponse.data.result.data[0].tutor_section);
          setTrainersData(homePageResponse.data.result.data[0].trainer_section);
        }
      })
      .catch(() => { });
  };

  /**Handle pointer navigations */
  const positionElement = (e: any) => {
    setMousePosition({ x: e.pageX, y: e.pageY });
  };

  /**Handle pointer navigation mouse leave */
  const handleMouseLeave = () => {
    setMousePosition({ x: null, y: null });
  };

  /**Handle cursor visibility for tutors */
  const handleTopServiceCursor = () => {
    if (topServiceCursor && topServiceCursor.current) {
      topServiceCursor.current.style.display = "flex";
      topServiceCursor.current.style.opacity = "1";
      topServiceCursor.current.style.transition =
        "opacity 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0.2s,background 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s";
    }
  };
  /**Handle cursor invisibility for tutors */
  const handleTopServiceCursorLeave = () => {
    if (topServiceCursor && topServiceCursor.current) {
      topServiceCursor.current.style.display = "none";
      topServiceCursor.current.style.opacity = "0";
      topServiceCursor.current.style.transition =
        "opacity 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0.2s,background 0.2s cubic-bezier(0.28, 0.44, 0.49, 1) 0s";
    }
  };

  // useEffect(() => {
  //   searchHomeKeyword("")
  // }, [])

  /**Home page Search Keyword Api*/
  const searchHomeKeyword = (search: any) => {
    var payload = {
      search_text: search.trim(),
    };
    axios
      .get(
        `${process.env.REACT_APP_BASE_BACKEND_API_URL +
        API_END_POINTS.HOME_SEARCH_USER +
        search.trim()
        }`
      )
      .then((Response: any) => {
        setSearchTextList(Response?.data.result?.data);
      })
      .catch(() => { });
  };

  /**AutoComplete Onchange Function*/
  const onInputChange = (value: any) => {
    if (nullUndefinedEmptyCheck(value)) {
      setSearchValue(value);
      searchHomeKeyword(value);
      setHighlightedIndex(-1);
    } else {
      setSearchTextList([]);
      setSearchValue("");
    }
  };


  /** Onchange  select navigte Function*/
  const handleSelectValue = (val: any) => {
    if (val?.provider_id === GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT) {
      navigate(ROUTES.TUTORS_LIST_VIEW, {
        state: {
          tutor_list: null,
          search_text: val?.tag,
          map_show: val?.tag?.includes(GLOBAL_CONSTANTS.INITIAL_VALUES.NEAR_ME)
            ? true
            : false,
          redius: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
          lat: HeaderLoction?.lat,
          lang: HeaderLoction?.lng,
        },
      });
    } else if (val?.provider_id === GLOBAL_CONSTANTS.INITIAL_VALUES.TWO_INT) {
      navigate(ROUTES.ACTIVITY_LIST_VIEW, {
        state: {
          activities_list: null,
          search_text: val?.tag,
          redius: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
          map_show: val?.tag?.includes(GLOBAL_CONSTANTS.INITIAL_VALUES.NEAR_ME)
            ? true
            : false,
        },
      });
    } else if (val?.provider_id === GLOBAL_CONSTANTS.INITIAL_VALUES.THREE_INT) {
      navigate(ROUTES.ACTIVITY_LIST_VIEW, {
        state: {
          activities_list: null,
          search_text: val?.tag,
          redius: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
          map_show: val?.tag?.includes(GLOBAL_CONSTANTS.INITIAL_VALUES.NEAR_ME)
            ? true
            : false,
        },
      });
    } else if (val?.provider_id === GLOBAL_CONSTANTS.INITIAL_VALUES.FOUR_INT) {
      navigate(ROUTES.SCHOOL_LIST_VIEW, {
        state: {
          school_list: null,
          search_text: val?.tag,
          redius: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
          map_show: val?.tag?.includes(GLOBAL_CONSTANTS.INITIAL_VALUES.NEAR_ME)
            ? true
            : false,
        },
      });
    } else if (val?.provider_id === GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT) {
      navigate(ROUTES.UNIVERSITY_LIST_VIEW, {
        state: {
          university_list: null,
          search_text: val?.tag,
          redius: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
          map_show: val?.tag?.includes(GLOBAL_CONSTANTS.INITIAL_VALUES.NEAR_ME)
            ? true
            : false,
        },
      });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.DOWN_ARROW) {
      // Move to the next option
      setHighlightedIndex((prevIndex) =>
        prevIndex < searchTextList.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.UP_ARROW) {
      // Move to the previous option
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  // Create a new Date object
  var currentDate = new Date();

  // Get the current year
  var currentYear = currentDate.getFullYear();

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const wrapperClassName = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top_activities_service_cards_wrapper" : "top_activities_service_cards_wrapper-arab";


  const top_service_card_title_container_wrap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top_service_card_title_container_eng" : "top_service_card_title_container-arab";

  const top_service_title_wrap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top_service_title_wrapper" : "top_service_title_wrapper-arab";

  const service_title_container_wrap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "service_card_title_container" : "service_card_title_container-arab";

  const theme_park_background_circle_wrap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "theme_park_background_circle_container" : "theme_park_background_circle_container-arab";

  const school_background_circle_wrap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "school_background_circle_container" : "school_background_circle_container-arab";

  const activities_background_circle_wrap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "activities_background_circle_container" : "activities_background_circle_container-arab";

  const university_background_circle_wrap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "university_background_circle_container" : "university_background_circle_container-arab";

  const search_button_lang_wrap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_button" : "search_button-arab";

  const search_field_lang_wrap = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_field" : "search_field-arab";


  return (
    <>
      <div className="home_page_wrapper">
        {/* <div className="search_banner_container">
          <img
            draggable="false"
            src={headerData.banner}
            alt="Learn and Play"
            className="banner_image"
          />
          <div className="search_field_wrapper">
            <div className="top__banner__contant_whiteText">
              we’re more than an app -
            </div>
            <div className="top__banner__contant_blackText">
              {" "}
              a platform that empowers, a movement that inspires
            </div>

            <div className="search_field_container">
              <input
                placeholder="Search anything here..."
                type="text"
                className="search_field"
                // value={searchValue}
                value={
                  highlightedIndex !== -1
                    ? searchTextList[highlightedIndex].tag
                    : searchValue
                }
                onChange={(inputChangeEvent: any) => {
                  onInputChange(inputChangeEvent.target.value);
                }}
                onKeyDown={handleKeyDown}
                onKeyPress={(e: any) => {
                  if (e.code === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                    if (searchValue.trim() === "") {
                      e.preventDefault();
                    } else {
                      handleSelectValue(
                        highlightedIndex !== -1
                          ? searchTextList[highlightedIndex]
                          : searchTextList[0]
                      );
                      e.preventDefault();
                    }
                  }
                }}
              />
              <img
                draggable="false"
                src={search}
                alt="Learn and Play"
                className="search_button"
                onClick={() =>
                  handleSelectValue(
                    highlightedIndex !== -1
                      ? searchTextList[highlightedIndex]
                      : searchTextList[0]
                  )
                }
              />
            </div>
            {nullUndefinedEmptyCheck(searchValue) && (
              <div className="Autocompleted__data_wapper">
                {searchTextList?.map((item: any, i: any) => {
                  return (
                    <>
                      <div
                        className={
                          highlightedIndex === i
                            ? "data_wapper__box data_wapper__box_hilight"
                            : "data_wapper__box"
                        }
                        key={i}
                        onClick={() => handleSelectValue(item)}
                      >
                        <div className="box">
                          <span className="tag">{item?.tag}</span>
                          <span className="addon">in {item.addon_name}</span>
                        </div>
                        <div className="provider">{item.provider}</div>
                      </div>
                    </>
                  );
                })}
                {searchTextList?.length === 0 && (
                  <>
                    <div className="no__result_wapper__box">
                      <div className="no__result">No result Found</div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div> */}


        <div className="search_banner_container">
          <Swiper
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            keyboard={true}
            modules={[Autoplay, Mousewheel, Keyboard]}
            className="mySwiper"
          >
            {headerData?.banner?.map((imageSrc: any, index: any) => {
              return (
                <SwiperSlide key={index}>
                  <img src={imageSrc} alt="Learn and Play" className="banner_image" />
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div className="search_field_wrapper">
            <div className="top__banner__contant_whiteText">

              {t("we’re more than an app -")}
            </div>
            <div className="top__banner__contant_blackText">
              {" "}
              {t("a platform that empowers, a movement that inspires")}
            </div>

            <div className="search_field_container">
              <input
                placeholder={t("Search anything here...")}
                type="text"
                className={search_field_lang_wrap}
                // value={searchValue}
                value={
                  highlightedIndex !== -1
                    ? searchTextList[highlightedIndex].tag
                    : searchValue
                }
                onChange={(inputChangeEvent: any) => {
                  onInputChange(inputChangeEvent.target.value);
                }}
                onKeyDown={handleKeyDown}
                onKeyPress={(e: any) => {
                  if (e.code === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                    if (searchValue.trim() === "") {
                      e.preventDefault();
                    } else {
                      handleSelectValue(
                        highlightedIndex !== -1
                          ? searchTextList[highlightedIndex]
                          : searchTextList[0]
                      );
                      e.preventDefault();
                    }
                  }
                }}
              />
              <img
                draggable="false"
                src={search}
                alt="Learn and Play"
                className={search_button_lang_wrap}
                onClick={() =>
                  handleSelectValue(
                    highlightedIndex !== -1
                      ? searchTextList[highlightedIndex]
                      : searchTextList[0]
                  )
                }
              />
            </div>
            {nullUndefinedEmptyCheck(searchValue) && (
              <div className="Autocompleted__data_wapper">
                {searchTextList?.map((item: any, i: any) => {
                  return (
                    <>
                      <div
                        className={
                          highlightedIndex === i
                            ? "data_wapper__box data_wapper__box_hilight"
                            : "data_wapper__box"
                        }
                        key={i}
                        onClick={() => handleSelectValue(item)}
                      >
                        <div className="box">
                          <span className="tag">{item?.tag}</span>
                          <span className="addon">in {item.addon_name}</span>
                        </div>
                        <div className="provider">{item.provider}</div>
                      </div>
                    </>
                  );
                })}
                {searchTextList?.length === 0 && (
                  <>
                    <div className="no__result_wapper__box">
                      <div className="no__result">{t("No result Found")}</div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>


        <div className="service_navigation_card_wrapper">
          {headerData?.card?.map((serviceNavElements: any, index: any) => {
            return (
              <div className="service_navigation_card_container" key={index}>
                {serviceNavElements.name === HOMEPAGE.SERVICE_TYPE.SCHOOL && (
                  <>
                    <div
                      className="school_nav_icon_wrapper"
                      onClick={() => {
                        navigate(ROUTES.SCHOOLLAYOUT);
                      }}
                    >
                      <img
                        draggable="false"
                        src={serviceNavElements.icon}
                        alt="Learn and Play"
                        className="service_nav_icon"
                      />
                    </div>
                    <div
                      className="service_nav_title_descriptions_container"
                      onClick={() => {
                        navigate(ROUTES.SCHOOLLAYOUT);
                      }}
                    >
                      <div className="service_nav_title">
                        {serviceNavElements.name}
                      </div>
                      <div className="service_nav_description">
                        {t("World of Schools")}
                      </div>
                    </div>
                  </>
                )}
                {serviceNavElements.name === HOMEPAGE.SERVICE_TYPE.TUTOR && (
                  <>
                    <div
                      className="tutor_nav_icon_wrapper"
                      onClick={() => {
                        navigate(ROUTES.TUTORS);
                      }}
                    >
                      <img
                        draggable="false"
                        src={serviceNavElements.icon}
                        alt="Learn and Play"
                        className="service_nav_icon"
                      />
                    </div>
                    <div
                      className="service_nav_title_descriptions_container"
                      onClick={() => {
                        navigate(ROUTES.TUTORS);
                      }}
                    >
                      <div className="service_nav_title">
                        {serviceNavElements.name}
                      </div>
                      <div className="service_nav_description">
                        {t("World of Tutors")}
                      </div>
                    </div>
                  </>
                )}
                {serviceNavElements.name ===
                  HOMEPAGE.SERVICE_TYPE.ACTIVITIES && (
                    <>
                      <div
                        className="activities_nav_icon_wrapper"
                        onClick={() => {
                          navigate(ROUTES.ACTIVITYLAYOUT);
                        }}
                      >
                        <img
                          draggable="false"
                          src={serviceNavElements.icon}
                          alt="Learn and Play"
                          className="service_nav_icon"
                        />
                      </div>
                      <div
                        className="service_nav_title_descriptions_container"
                        onClick={() => {
                          navigate(ROUTES.ACTIVITYLAYOUT);
                        }}
                      >
                        <div className="service_nav_title">
                          {serviceNavElements.name}
                        </div>
                        <div className="service_nav_description">
                          {t("World of Activities")}
                        </div>
                      </div>
                    </>
                  )}
                {serviceNavElements.name === HOMEPAGE.SERVICE_TYPE.PARKS && (
                  <>
                    <div
                      className="theme_park_nav_icon_wrapper"
                      onClick={() => {
                        navigate(ROUTES.THEMEPARK__HOMEPAGE);
                      }}
                    >
                      <img
                        draggable="false"
                        src={serviceNavElements.icon}
                        alt="Learn and Play"
                        className="service_nav_icon"
                      />
                    </div>
                    <div
                      className="service_nav_title_descriptions_container"
                      onClick={() => {
                        navigate(ROUTES.THEMEPARK__HOMEPAGE);
                      }}
                    >
                      <div className="service_nav_title">
                        {serviceNavElements.name}
                      </div>
                      <div className="service_nav_description">
                        {t("World of Theme parks")}
                      </div>
                    </div>
                  </>
                )}
                {serviceNavElements.name ===
                  HOMEPAGE.SERVICE_TYPE.UNIVERSITY && (
                    <>
                      <div
                        className="university_nav_icon_wrapper"
                        onClick={() => {
                          navigate(ROUTES.UNIVERSITYLAYOUT);
                        }}
                      >
                        <img
                          draggable="false"
                          src={serviceNavElements?.icon}
                          alt="Learn and Play"
                          className="service_nav_icon"
                        />
                      </div>
                      <div
                        className="service_nav_title_descriptions_container"
                        onClick={() => {
                          navigate(ROUTES.UNIVERSITYLAYOUT);
                        }}
                      >
                        <div className="service_nav_title">
                          {serviceNavElements.name}
                        </div>
                        <div className="service_nav_description">
                          {t("World of Universities")}
                        </div>
                      </div>
                    </>
                  )}
              </div>
            );
          })}
        </div>
        <HomeOffersCarousel offersData={headerData.carousel} />
        {/* <div className="know_more_contents_wrapper">
          <div className="know_more_contents">
            we’re more than an app- a platform that empowers, a movement that
            inspires
          </div>
          <div className="know_more_button_container">
            <button className="know_more" onClick={() => {
              if (token) {
                SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, "About Us");
                navigate(ROUTES.ABOUTUS);
              } else {
                navigate(ROUTES.ABOUTUS_WITHOUT_LOGIN);
              }
            }}>Know more</button>
            <img
              draggable="false"
              src={knowMoreArrow}
              alt="Learn and Play"
              className="know_more_arrow"
            />
          </div>
          <div className="know_more_description">the new way to parenting</div>
        </div> */}
        <div
          className="school_service_cards_wrapper"
          onMouseMove={(event: any) => {
            positionElement(event);
          }}
          onMouseLeave={handleMouseLeave}
        >
          {/* {mousePosition.x !== null && mousePosition.y !== null && (
            <div
              className="custom_cursor"
              style={{ top: mousePosition.y, left: mousePosition.x }}
            >
              <img src={navigation_arrows} alt="Learn and Play" />
            </div>
          )} */}
        </div>
        {schoolData?.data?.length !== 0 && (
          <div className="service_card_title_wrapper">
            {/* <div className="school_background_circle_container"></div> */}
            <div className={school_background_circle_wrap}></div>
            {/* <div className="service_card_title_container"> */}
            <div className={service_title_container_wrap}>
              <div
                className="service_chip_container"
                onClick={() => navigate(ROUTES.SCHOOLLAYOUT)}
              >
                <img
                  draggable="false"
                  src={school}
                  alt="Learn and Play"
                  className="service_logo"
                />
                <div className="service_chip_label">
                  {t("Schools")}
                </div>
              </div>
              <div className="service_title">{language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? schoolData.heading : schoolData.heading_ar}</div>
              <div
                className="school_service_cards_wrapper"
                onMouseMove={(event: any) => {
                  positionElement(event);
                }}
                onMouseLeave={handleMouseLeave}
              >
                <HomeServiceCards
                  cardType={HOMEPAGE.SERVICE_TYPE.SCHOOL}
                  serviceData={schoolData.data}
                />
                {/* {mousePosition.x !== null && mousePosition.y !== null && (
                  <div
                    className="custom_cursor"
                    style={{ top: mousePosition.y, left: mousePosition.x }}
                  >
                    <img src={navigation_arrows} alt="Learn and Play" />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        )}
        {tutorsData?.data?.length !== 0 && (
          <div className="top_service_card_title_wrapper">
            <div className="tutor_background_circle_container"></div>
            <div className={`${top_service_card_title_container_wrap} top_service_card_title_container`}>
              {/* <div className={top_service_card_title_container_wrap}> */}
              <div
                className={`${top_service_title_wrap} ${isTutorContentOverlapping ? "image_content_overlap" : ""}`}
              >
                <div
                  className="service_chip_container"
                  onClick={() => navigate(ROUTES.TUTORS)}
                >
                  <img
                    draggable="false"
                    src={tutor}
                    alt="Learn and Play"
                    className="service_logo"
                  />
                  <div className="service_chip_label">
                    {t("Tutors")}
                  </div>
                </div>
                <div className="top_serive_title">{language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? tutorsData?.heading : tutorsData?.heading_ar}</div>
                <div className="top_serive_description">
                  {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? tutorsData?.description : tutorsData?.description_ar}
                </div>
              </div>
              <div
                className={`${wrapperClassName} ${isTutorContentOverlapping ? "tutor_content_overlap" : ""}`}
                onMouseMove={(event: any) => {
                  positionElement(event);
                }}
                onMouseOver={handleTopServiceCursor}
                onMouseLeave={(e: any) => {
                  handleMouseLeave();
                  handleTopServiceCursorLeave();
                }}
              >
                <TopServiceCards
                  isTutorContentOverlapping={isTutorContentOverlapping}
                  setIsTutorContentOverlapping={setIsTutorContentOverlapping}
                  serviceType={HOMEPAGE.SERVICE_TYPE.TUTOR}
                  serviceData={tutorsData.data}
                />
              </div>
            </div>
            {/* {mousePosition.x !== null && mousePosition.y !== null && (
              <div
                ref={topServiceCursor}
                className="custom_cursor"
                style={{ top: mousePosition.y, left: mousePosition.x }}
              >
                <img src={navigation_arrows} alt="Learn and Play" />
              </div>
            )} */}
          </div>
        )}
        {activitiesData?.data?.length !== 0 && (
          <div className="service_card_title_wrapper">
            {/* <div className="activities_background_circle_container"></div> */}
            <div className={activities_background_circle_wrap}></div>
            {/* <div className="service_card_title_container"> */}
            <div className={service_title_container_wrap}>
              <div className="service_title">{language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? activitiesData?.heading : activitiesData?.heading_ar}</div>
              <div
                className="activities_service_cards_wrapper"
                onMouseMove={(event: any) => {
                  positionElement(event);
                }}
                onMouseLeave={handleMouseLeave}
              >
                <HomeServiceCards
                  cardType={HOMEPAGE.SERVICE_TYPE.ACTIVITIES}
                  serviceData={activitiesData.data}
                />
                {/* {mousePosition.x !== null && mousePosition.y !== null && (
                  <div
                    className="custom_cursor"
                    style={{ top: mousePosition.y, left: mousePosition.x }}
                  >
                    <img src={navigation_arrows} alt="Learn and Play" />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        )}
        {trainersData?.data?.length !== 0 && (
          <div className="top_service_card_title_wrapper">
            <div className="tutor_background_circle_container"></div>
            {/* <div className={top_service_card_title_container_wrap}> */}
            <div className={`${top_service_card_title_container_wrap} top_service_card_title_container`}>
              <div
                className={`${top_service_title_wrap} ${isActivitiesContentOverlapping ? "image_content_overlap" : ""
                  }`}
              >
                <div
                  className="service_chip_container"
                  onClick={() => navigate(ROUTES.ACTIVITYLAYOUT)}
                >
                  <img
                    draggable="false"
                    src={activities}
                    alt="Learn and Play"
                    className="service_logo"
                  />
                  <div className="service_chip_label">
                    {t("Activities")}
                  </div>
                </div>
                <div className="top_serive_title">{language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? trainersData?.heading : trainersData?.heading_ar}</div>
                <div className="top_serive_description">
                  {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? trainersData?.description : trainersData?.description_ar}
                </div>
              </div>
              <div
                // className={`top_activities_service_cards_wrapper ${isActivitiesContentOverlapping ? "tutor_content_overlap" : ""
                className={`${wrapperClassName} ${isActivitiesContentOverlapping ? "tutor_content_overlap" : ""
                  }`}
                onMouseMove={(event: any) => {
                  positionElement(event);
                }}
                onMouseOver={handleTopServiceCursor}
                onMouseLeave={(e: any) => {
                  handleMouseLeave();
                  handleTopServiceCursorLeave();
                }}
              >
                <TopServiceCards
                  isTutorContentOverlapping={isActivitiesContentOverlapping}
                  setIsTutorContentOverlapping={
                    setIsActivitiesContentOverlapping
                  }
                  serviceType={HOMEPAGE.SERVICE_TYPE.ACTIVITIES}
                  serviceData={trainersData.data}
                />
              </div>
            </div>
            {/* {mousePosition.x !== null && mousePosition.y !== null && (
              <div
                ref={topServiceCursor}
                className="custom_cursor"
                style={{ top: mousePosition.y, left: mousePosition.x }}
              >
                <img src={navigation_arrows} alt="Learn and Play" />
              </div>
            )} */}
          </div>
        )}
        {themeParksData?.data?.length !== 0 && (
          <div className="service_card_title_wrapper">
            <div className={theme_park_background_circle_wrap}></div>
            {/* <div className="service_card_title_container"> */}
            <div className={service_title_container_wrap}>
              <div
                className="service_chip_container"
                onClick={() => navigate(ROUTES.THEMEPARK__HOMEPAGE)}
              >
                <img
                  draggable="false"
                  src={themePark}
                  alt="Learn and Play"
                  className="service_logo"
                />
                <div className="service_chip_label">
                  {t("Parks & Events")}
                </div>
              </div>
              <div className="service_title">{language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? themeParksData?.heading : themeParksData?.heading_ar}</div>
              <div
                className="theme_park_service_cards_wrapper"
                onMouseMove={(event: any) => {
                  positionElement(event);
                }}
                onMouseLeave={handleMouseLeave}
              >
                <HomeServiceCards
                  cardType={HOMEPAGE.SERVICE_TYPE.THEME_PARK}
                  serviceData={themeParksData.data}
                />
                {/* {mousePosition.x !== null && mousePosition.y !== null && (
                  <div
                    className="custom_cursor"
                    style={{ top: mousePosition.y, left: mousePosition.x }}
                  >
                    <img src={navigation_arrows} alt="Learn and Play" />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        )}
        {universityData?.data?.length !== 0 && (
          <div className="service_card_title_wrapper">
            {/* <div className="university_background_circle_container"></div> */}
            <div className={university_background_circle_wrap}></div>
            {/* <div className="service_card_title_container"> */}
            <div className={service_title_container_wrap}>
              <div
                className="service_chip_container"
                onClick={() => navigate(ROUTES.UNIVERSITYLAYOUT)}
              >
                <img
                  draggable="false"
                  src={university}
                  alt="Learn and Play"
                  className="service_logo"
                />
                <div className="service_chip_label">
                  {t("Universities")}
                </div>
              </div>
              <div className="service_title">{language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? universityData?.heading : universityData?.heading_ar}</div>
              <div
                className="university_service_cards_wrapper"
                onMouseMove={(event: any) => {
                  positionElement(event);
                }}
                onMouseLeave={handleMouseLeave}
              >
                <HomeServiceCards
                  cardType={HOMEPAGE.SERVICE_TYPE.UNIVERSITY}
                  serviceData={universityData.data}
                />
                {/* {mousePosition.x !== null && mousePosition.y !== null && (
                  <div
                    className="custom_cursor"
                    style={{ top: mousePosition.y, left: mousePosition.x }}
                  >
                    <img src={navigation_arrows} alt="Learn and Play" />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        )}
        <div className="mobile_app_ad_wrapp">
          <img
            draggable="false"
            src={headerData.footer}
            alt="Learn and Play"
            className="mobile_view_img"
          />
          <div className="mobile_app_links_wrapper">
            <button className="download_app">
              {t("Download app")}
            </button>
            <div className="mobile_app_ad_title">
              {t("App-solutely incredible!")}
            </div>
            <div className="mobie_app_ad_description">
              {t("Download our application, and let the magic happen")}
            </div>
            <div className="mobile_app_download_links">
              <img
                draggable="false"
                src={playStore}
                alt="Learn and Play"
                className="playstore_link"
              />
              <img
                draggable="false"
                src={appStore}
                alt="Learn and Play"
                className="app_store_link"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
