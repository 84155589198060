import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { GetSessionStorageData } from "../Helpers/Helper";
import {
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
} from "../../Constants/Constants";
import { setLoginSignUpOpen } from "../../Redux/Reducer/Reducer";
import { useDispatch } from "react-redux";

const PrivateRoutesCheck = () => {
  const dispatch = useDispatch();

  /**Get access token from session storage */
  const token = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);

  const tokenCheck = (token: any) => {
    if (token) {
      return (
        <>
          <Outlet />
        </>
      );
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      return (
        <>
          <Navigate to={ROUTES.HOMEPAGE} />
          <Outlet />
        </>
      );
    }
  };

  return tokenCheck(token);
};

export default PrivateRoutesCheck;
