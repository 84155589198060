import "./TutorHubChat.scss";
import TutorHubChat__Img from "../../Assets/TutorHub/TutorHubChat.svg";
import chatSendIcon from "../../Assets/TutorHub/chatSendIcon.svg";
import { useEffect, useRef, useState } from "react";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, S3DataGetApi, SOCKET } from "../../Constants/Constants";
import { GetSessionStorageData, nullUndefinedEmptyCheck, uploadFileToS3 } from "../../Utilities/Helpers/Helper";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import moment from "moment";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Pdf_Img from "../../Assets/Global/drive.svg";
import DownloadImg from "../../Assets/TutorHub/DownloadImg.svg";
import { setLoader } from "../../Redux/Reducer/Reducer";
import { store } from "../../Redux/Store/Store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const TutorHubChat = ({ type, roomData, chatName, session_receiver_id }: any) => {
    const DataKeys: any = useSelector((state: any) => state.s3DataKeyReducer.datakey);
    const { t } = useTranslation();

    const [textMessage, setTextMessage] = useState("");
    const [activeIndex, setActiveIndex] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT);
    const [chatHistory, setChatHistory] = useState<any>([]);
    const [userId, setUserId] = useState<any>(GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID));
    const [socketInstance, setSocketInstance] = useState<any>();
    // const [currentPage, setCurrentPage] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT);
    // const [totalPages, setTotalPages] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT);
    const ImageInputRef = useRef<HTMLInputElement>(null);
    const [currentPage, setCurrentPage] = useState(1);
    // const [totalPages, setTotalPages] = useState<number | null>(null); 
    const [totalPages, setTotalPages] = useState<any>(0);
    //
    const [newMessage, setNewMessage] = useState(false);

    const chatContainerRef: any = useRef(null);
    useEffect(() => {
        setUserId(GetSessionStorageData(AUTHENTICATION.SHARED.USER_ID));
        getChatHistory();
    }, []);

    useEffect(() => {
        // Scroll to the bottom of the chat container when new messages arrive
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [textMessage]);

    useEffect(() => {
        if (socketInstance) {
            socketInstance.close();
        };
        let socket = new WebSocket(`${process.env.REACT_APP_CHAT_WEB_SOCKET + roomData?.room_name}/`);
        setSocketInstance(socket)
    }, [roomData]);

    // useEffect(() => {
    //     if (chatContainerRef.current) {
    //         chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    //     }
    // }, [chatHistory]);

    useEffect(() => {
        if (chatContainerRef?.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
            const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 200;

            if (isScrolledToBottom || newMessage || currentPage === 1) {
                chatContainerRef.current.scrollTop = scrollHeight - 200;
                setNewMessage(false);
            } else {
                chatContainerRef.current.scrollTop = 100;
            }
        }
    }, [chatHistory]);

    useEffect(() => {
        if (socketInstance) {
            socketInstance.onopen = (event: any) => {
            };

            /* Listen to events for socket server */
            socketInstance.onmessage = (event: any) => {
                setChatHistory((prevChatHistory: any) => [...prevChatHistory, JSON.parse(event.data)]);
            };

            /* Closes socket on killing component */
            return () => {
                socketInstance.close();
            }
        }

    }, [socketInstance, chatContainerRef]);


    /* Sending message using socket */
    const onSendMessage = () => {
        setNewMessage(true)
        socketInstance.send(JSON.stringify({
            sender: userId,
            receiver: session_receiver_id,
            message: textMessage,
            room_id: roomData?.id,
            image: null,
            document: null,
        }));
        setTextMessage("");
    };

    const onSendMessageDoc = (imgMsg: any, dataType: any) => {
        setNewMessage(true)
        if (imgMsg.trim() !== '') {
            socketInstance.send(JSON.stringify({
                sender: userId,
                receiver: session_receiver_id,
                message: null,
                image: dataType === 'image' ? imgMsg : null,
                document: dataType === 'document' ? imgMsg : null,
                room_id: roomData?.id
            }));
            setTextMessage("");
        }
    };

    // /** Below for pagination when scrolling **/
    // useEffect(() => {
    //     /* Add event listener to detect scroll */
    //     chatContainerRef.current.addEventListener("scroll", handleScroll);
    //     return () => {
    //     /* Remove event listener when component unmounts */
    //     chatContainerRef.current?.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    useEffect(() => {
        // if (currentPage > 1) {
        getChatHistory();
        // }
    }, [currentPage]);

    // const handleScroll = () => {
    //     const chatContainer = chatContainerRef.current;
    //     if (chatContainer && chatContainer.scrollTop === 0) {
    //         if (currentPage < totalPages! || totalPages === null) {
    //             setCurrentPage(prevPage => prevPage + 1);
    //         }
    //     }
    // };

    useEffect(() => {
        /** Add event listener to detect scroll **/
        const handleScroll = () => {
            const chatContainer = chatContainerRef.current;
            if (chatContainer.scrollTop === 0 && currentPage < totalPages) {
                /* Trigger pagination when user scrolls to the top and there are more pages to load */
                setCurrentPage(prevPage => prevPage + 1);
            }
        };

        chatContainerRef.current.addEventListener("scroll", handleScroll);

        return () => {
            /* Remove event listener when component unmounts */
            chatContainerRef.current?.removeEventListener("scroll", handleScroll);
        };
    }, [currentPage, totalPages]);


    const getChatHistory = () => {
        var payload = {
            page: currentPage,
            // limit: 500
            limit: 15
        }
        axiosInstance.post(`${API_END_POINTS.CHAT_HISTORY + roomData?.id}`, payload)
            // .then((response) => {
            //     if (response.data.status) {
            //         setChatHistory(response?.data?.result?.data?.reverse());
            //     }
            // })


            // .then((response) => {
            //     if (response.data.status) {
            //         setChatHistory((prevChatHistory: any[]) => [
            //             ...response?.data?.result?.data?.reverse()!,
            //             ...prevChatHistory
            //         ]);
            //         if (response?.data?.result?.total_pages) {
            //             setTotalPages(response.data.result.total_pages);
            //         }
            //     }
            // });

            .then((response) => {
                if (response.data.status) {
                    setChatHistory((prevChatHistory: any[]) => {
                        const newData = response?.data?.result?.data?.reverse() || [];
                        const filteredData = newData.filter((item: any) => !prevChatHistory.some(prevItem => prevItem.id === item.id));
                        return [...filteredData, ...prevChatHistory];
                    });
                    setTotalPages(response.data.result.total_page);
                }
            });
    }

    // const textAreaRef: any = useRef<HTMLTextAreaElement>(null);
    // useEffect(() => {
    //     if (textAreaRef) {
    //         textAreaRef.style.height = "0px";
    //         const scrollHeight = textAreaRef.scrollHeight;
    //         textAreaRef.style.height = scrollHeight + "px";
    //     }
    // }, [textAreaRef.current])

    const onUploadEventImage = async (event: any) => {
        setNewMessage(true)
        try {
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
            const uploadedImage = await S3DataGetApi(event);
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
            if (event.target.files[0].type.split("/")[0] === "image") {
                onSendMessageDoc(uploadedImage, 'image');
            } else {
                onSendMessageDoc(uploadedImage, 'document');
            }
        } catch (error) { }
    };

    //To extract file name from url
    const checkString = (url: string) => {
        const urlParts = url.split('/');
        const fileNameWithExtension = urlParts[urlParts.length - 1];
        const lastDotIndex = fileNameWithExtension.lastIndexOf('.');

        const fileNameWithoutExtension = lastDotIndex !== -1 ? fileNameWithExtension?.slice(0, lastDotIndex) : fileNameWithExtension;

        return fileNameWithoutExtension;
    }

    //To extract file type from url
    const checkTypeString = (url: string) => {
        const lastDotIndex = url.lastIndexOf('.');
        const fileFormat = url.slice(lastDotIndex);

        return fileFormat.replace(/\./g, '');
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });
    /*Below class name based on English Arabic*/
    const TypeBoxContainer = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "type_box_container_eng" : "type__box__container_arab";
    const AttachFileIconLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "AttachFileIcon_eng" : "AttachFileIcon_arab";
    const SendIcon_Disable = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "sendIcon_disable_eng" : "sendIcon_disable_arab";
    const TypeBoxInput = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "TypeBoxInput_eng" : "TypeBoxInput_arab";
    const TutorsityUserChat = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "chat_end_english" : "chat_end_arab";
    const UserChatLanguage = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "user_chat_english" : "user_chat_arab";
    const TypeBoxContainerLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "TypeBoxContainerLang_english" : "TypeBoxContainerLang_arab";

    return (
        <>

            {type == 1 && (
                <>
                    <div className="TutorHubChat__container">
                        <div className="Header__wapper">
                            <img src={TutorHubChat__Img} alt="" />
                            <div className="title">{t("Chat with")} {chatName}</div>
                        </div>

                        <div className="chat__container" ref={chatContainerRef}>

                            {chatHistory?.map((item: any, i: any) => {
                                return (
                                    <>
                                        {/* <div className="chat__center">
                                            <div className="show__time">
                                                May 16, 2023
                                            </div>
                                        </div> */}

                                        {item?.sender != userId ? (
                                            <>
                                                {nullUndefinedEmptyCheck(item?.message) && (
                                                    <div className="chat__start">
                                                        <div className="tutor__chat">
                                                            {item?.message}
                                                        </div>
                                                        <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                    </div>
                                                )}

                                                {item.image && <div className="chat__start">
                                                    <img className="chat-image" src={item.image} onClick={() => {
                                                        window.open(item.image, "_blank");
                                                    }} />
                                                    <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                </div>}

                                                {/* {item.document && <div className="chat__start">
                                                    <img className="chat-document" src={Pdf_Img} onClick={() => {
                                                        window.open(item.document, "_blank");
                                                    }} />
                                                    <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                </div>} */}
                                                {item.document && (
                                                    <div className="chat__start">
                                                        <div className="Pdf__container">
                                                            <div className="left__contant">
                                                                <div className="tutor__img__wapper">
                                                                    <img src={Pdf_Img} alt="" />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <div className="Black__text">{checkString(item.document)}</div>
                                                                    <div className="name__text">{checkTypeString(item.document)}</div>
                                                                </div>
                                                            </div>
                                                            <img
                                                                src={DownloadImg}
                                                                className="DownloadImg"
                                                                onClick={() => {
                                                                    window.open(item.document, "_blank");
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {nullUndefinedEmptyCheck(item?.message) && (
                                                    // <div className="chat__end">
                                                    <div className={`${TutorsityUserChat} chat__end`}>
                                                        <div className={`${UserChatLanguage} user__chat`}>
                                                            {item?.message}
                                                        </div>
                                                        <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                    </div>
                                                )}

                                                {nullUndefinedEmptyCheck(item.image) && <div className={`${TutorsityUserChat} chat__end`}>
                                                    <img className="chat-image" src={item.image} onClick={() => {
                                                        window.open(item.image, "_blank");
                                                    }} />
                                                    <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                </div>}

                                                {/* {nullUndefinedEmptyCheck(item.document) && <div className="chat__end">
                                                    <img className="chat-document" src={Pdf_Img} onClick={() => {
                                                        window.open(item.document, "_blank");
                                                    }} />
                                                    <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                </div>} */}

                                                {item.document && (
                                                    <div className={`${TutorsityUserChat} chat__end`}>
                                                        <div className="Pdf__container">
                                                            <div className="left__contant">
                                                                <div className="tutor__img__wapper">
                                                                    <img src={Pdf_Img} alt="" />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <div className="Black__text">{checkString(item.document)}</div>
                                                                    <div className="name__text">{checkTypeString(item.document)}</div>
                                                                </div>
                                                            </div>
                                                            <img
                                                                src={DownloadImg}
                                                                className="DownloadImg"
                                                                onClick={() => {
                                                                    window.open(item.document, "_blank");
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )
                            })}
                        </div>
                        {/* <div className="type_box_container_wrap"> */}
                        <div className={`${TypeBoxContainer} type__box__container`}>
                            <div className="type_box_container_wrap">
                                <textarea placeholder={t("Type Your Message")} value={textMessage} onChange={(e: any) => { setTextMessage(e.target.value); }}
                                    onKeyPress={(e: any) => {
                                        if (e.code === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER && !e.shiftKey) {
                                            if (textMessage.trim() === '') {
                                                e.preventDefault();
                                            } else {
                                                onSendMessage();
                                                e.preventDefault();
                                            }
                                        }
                                    }}
                                    // className="type__box__input" />
                                    className={`${TypeBoxInput} type__box__input`} />
                                <input
                                    ref={ImageInputRef}
                                    className="file_input"
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg, image/svg+xml, application/pdf, application/msword, 
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                 application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={onUploadEventImage}
                                />
                                <AttachFileIcon className={`${AttachFileIconLang} AttachFileIcon`} onClick={() => ImageInputRef.current?.click()} />
                                {nullUndefinedEmptyCheck(textMessage) && textMessage.trim() !== '' ?
                                    (<img src={chatSendIcon} alt="" onClick={onSendMessage} className={`${SendIcon_Disable} sendIcon`} />) :
                                    (<img src={chatSendIcon} alt="" className={`${SendIcon_Disable} sendIcon__disable`} />)}
                            </div>
                        </div>
                        {/* </div> */}

                    </div>
                </>
            )}
            {type == 2 && (
                <>
                    <div className="TutorHubChat__container">
                        <div className="Header__wapper">
                            <img src={TutorHubChat__Img} alt="" />
                            <div title={chatName} className="title">{t("Chat with")} {chatName}</div>
                        </div>

                        <div className="chat__container" ref={chatContainerRef}>

                            {chatHistory?.map((item: any, i: any) => {
                                return (
                                    <>
                                        {/* <div className="chat__center">
                                            <div className="show__time">
                                                May 16, 2023
                                            </div>
                                        </div> */}

                                        {item?.sender != userId ? (
                                            <>
                                                {nullUndefinedEmptyCheck(item?.message) && (
                                                    <div className="chat__start">
                                                        <div className="Activity__chat">
                                                            {item?.message}
                                                        </div>
                                                        <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                    </div>
                                                )}
                                                {item?.image && <div className="chat__start">
                                                    <img className="chat-image" src={item.image} onClick={() => {
                                                        window.open(item?.image, "_blank");
                                                    }} />
                                                    <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                </div>}

                                                {item.document && (
                                                    <div className="chat__start">
                                                        <div className="Pdf__container">
                                                            <div className="left__contant">
                                                                <div className="tutor__img__wapper">
                                                                    <img src={Pdf_Img} alt="" />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <div className="Black__text">{checkString(item.document)}</div>
                                                                    <div className="name__text">{checkTypeString(item.document)}</div>
                                                                </div>
                                                            </div>
                                                            <img
                                                                src={DownloadImg}
                                                                className="DownloadImg"
                                                                onClick={() => {
                                                                    window.open(item.document, "_blank");
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {nullUndefinedEmptyCheck(item?.message) && (
                                                    <div className={`${TutorsityUserChat} chat__end`}>
                                                        <div className={`${UserChatLanguage} user__chat`}>
                                                            {item?.message}
                                                        </div>
                                                        <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                    </div>
                                                )}
                                                {nullUndefinedEmptyCheck(item?.image) && <div className={`${TutorsityUserChat} chat__end`}>
                                                    <img className="chat-image" src={item?.image} onClick={() => {
                                                        window.open(item.image, "_blank");
                                                    }} />
                                                    <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                </div>}

                                                {item?.document && (
                                                    <div className={`${TutorsityUserChat} chat__end`}>
                                                        <div className="Pdf__container">
                                                            <div className="left__contant">
                                                                <div className="tutor__img__wapper">
                                                                    <img src={Pdf_Img} alt="" />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <div className="Black__text">{checkString(item.document)}</div>
                                                                    <div className="name__text">{checkTypeString(item.document)}</div>
                                                                </div>
                                                            </div>
                                                            <img
                                                                src={DownloadImg}
                                                                className="DownloadImg"
                                                                onClick={() => {
                                                                    window.open(item.document, "_blank");
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="time">{moment(item?.created_on).format('h:mm a')}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )
                            })}
                        </div>

                        <div className="type__box__container">
                            <div className="type_box_container_wrap">
                                <textarea placeholder={t("Type Your Message")} value={textMessage} onChange={(e: any) => { setTextMessage(e.target.value); }}
                                    onKeyPress={(e: any) => {
                                        if (e.code === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER && !e.shiftKey) {
                                            if (textMessage.trim() === '') {
                                                e.preventDefault();
                                            } else {
                                                onSendMessage();
                                                e.preventDefault();
                                            }
                                        }
                                    }}
                                    //  className="type__box__input" />
                                    className={`${TypeBoxInput} type__box__input`} />
                                <input
                                    ref={ImageInputRef}
                                    className="file_input"
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg, image/svg+xml, application/pdf, application/msword, 
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                 application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={onUploadEventImage}
                                />
                                <AttachFileIcon className={`${AttachFileIconLang} AttachFileIcon`} onClick={() => ImageInputRef.current?.click()} />

                                {nullUndefinedEmptyCheck(textMessage) && textMessage.trim() !== '' ?
                                    (<img src={chatSendIcon} alt="" onClick={onSendMessage} className={`${SendIcon_Disable} sendIcon`} />) :
                                    (<img src={chatSendIcon} alt="" className={`${SendIcon_Disable} sendIcon__disable`} />)}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default TutorHubChat;