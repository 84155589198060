import { useEffect, useState } from "react";
import { API_END_POINTS } from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { useParams } from "react-router";
import borderBottom from "../../Assets/Global/ticket-border-bottom.svg";
import "./MyTicketsDetail.scss";
import moment from "moment";
import DownloadIcon from '@mui/icons-material/Download';
import toast from 'react-hot-toast';
import { nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const MyTicketsDetail = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [ ticketDetail, setTicketDetail ] = useState<any>([]);

    useEffect(()=>{
        getTicketsDetail();
    },[])


    const getTicketsDetail = () =>{
        axiosInstance.post(API_END_POINTS.MYTICKETS_LIST, {"booking_id":id})
        .then((response: any) => {
            if (response?.data?.status) {
                console.log(response?.data?.result?.data,'ticket detail list')
                // setTicketDetail(response?.data?.result?.data);
                setTicketDetail(response?.data?.result?.data);
                /* setTotalCount(Response?.data?.result?.total_wallet_amount)
                setPageCount(Response?.data?.result?.total_count) */
            }
        })
        .catch(() => { });
    };

    const onDownLoadTicket = (link:any) => {
        if(nullUndefinedEmptyCheck(link)){
            const downloadAnchor = document.createElement('a');
            downloadAnchor.href = link;
            downloadAnchor.download = 'file.pdf'; 
            downloadAnchor.click();
            toast.success("Ticket Downloaded Successfully", {
                position: "top-right",
            });
        }
       
    };

    return(
        <div className="tickets-detail-wrapper">
            
            <div className="tickets-wrap">
            <div id="card-wrap" /* onScroll={handleScroll} */>
          {ticketDetail?.length > 0 && (
            <div className="row">
              {ticketDetail?.map((val: any, i: any) => {
                return (
                  <div className="col-sm-6 col-md-4">
                    {/*  <div className="row">
                    <div className="col-md-12"> */}
                    <div className="ticket-wrap">
                      
                      <div className="ticket-top-view">
       <div className="top-ticket">
                        <div className="title-download">
                        <h5>{val.ticket_type}</h5>
                        <Tooltip
                         title={"Download Ticket"}
                         placement="bottom"
                         onClick={()=>onDownLoadTicket(val.download_link)}
                        >
                          <DownloadIcon className="download-icon" />
                        </Tooltip>
                        
                        </div>
                     
                        <div className="label-data-wrap">
                          <div className="row row-overiding-style">
                            <div className="col-md-6 theme-title" >
                                {t('Expiry on')} {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
                            </div>
                            <p className="col-md-6 theme-value" id="ticket-values">{moment(new Date(val.expiry_on)).format("DD/MM/YYYY")}</p>

                          </div>
                        </div>

                        <div className="label-data-wrap">
                           <div className="row row-overiding-style">
                            <div className="col-md-6 theme-title">
                                {t('Ticket Code')} {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
                            </div >
                            <p className="col-md-6 theme-value" id="ticket-values" >{val.ticket_code}</p>
                           </div>
                        </div>

                        <div className="label-data-wrap">
                           <div className="row row-overiding-style">
                            <label className="col-md-6 theme-title" >
                                {t('Booked Date')} 
                            </label>
                            <p id="ticket-values" className="col-md-6 theme-value" >{`${val.booked_date}`}</p>
                           </div>
                        </div>
            </div>

            <div className="top-ticket">
                <span className="border-span">
                    <img className="border-image" src={borderBottom} />
                </span>
                <div className="ticket-image-wrap">
                    <img className="ticket-image" src={val.image} />
                </div>
            </div>

                        {/* <span className="border-span"><img className="border-image" src={borderBottom} /></span> */}

                        <div className="left-hole"></div>
                        <div className="right-hole"></div>

                        {/* <div className="ticket-image-wrap">
                          <img className="ticket-image" src={val.image} />
                        </div> */}
                      </div>


                    </div>
                    {/*  </div>
                    </div> */}

                  </div>

                );
              })}
            </div>
          )}
          {/* {data?.length === 0 && (
            <NoProducts />
          )} */}
        </div>
            </div>
        </div>
    )
};

export default MyTicketsDetail;


/////