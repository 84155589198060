import "./ThemeParkListView.scss";
import locationIcon from "../../../Assets/Tutor/location.svg";
import StarIcon from '@mui/icons-material/Star';
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import spacemen from "../../../Assets/Global/spacemen.svg";
import LikeIcon from "../../../Assets/ThemePark/LikeIcon.svg";
import shareIcon from "../../../Assets/ThemePark/shareIcon.svg";
import themeParkImg from "../../../Assets/ThemePark/themeParkImg-svg.png";
import { useEffect, useState } from "react";
import search from "../../../Assets/Tutor/searchIconGray.svg";
import themeParkIcon from "../../../Assets/SideBar/themeParkActive.svg"
import { useLocation, useNavigate } from "react-router-dom";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ROUTES, onInputChangeGoogleApi } from "../../../Constants/Constants";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullSharpIcon from '@mui/icons-material/OpenInFullSharp';
import NavigateNextSharpIcon from '@mui/icons-material/NavigateNextSharp';
import NavigateBeforeSharpIcon from '@mui/icons-material/NavigateBeforeSharp';
import { Rating, Tooltip } from '@mui/material';
import JamBam from "../../../Assets/ThemePark/Jam-Bam.svg";
import PriceRange from "../../../Assets/ThemePark/PriceRange.svg";
import themePark_star from "../../../Assets/ThemePark/themePark_star.svg";
import AliceCarousel from "react-alice-carousel";
import { GetSessionStorageData, nullUndefinedEmptyCheck } from "../../../Utilities/Helpers/Helper";
import { setGuestSignupOpen, setLoginSignUpOpen } from "../../../Redux/Reducer/Reducer";



const ThemeParkListView = () => {
    const route = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const [themeParkList, setThemeParkList] = useState<any>([]);
    const [themeParkDetaile, setThemeParkDetaile] = useState<any>([]);
    const [selectEvent, setSelectEvent] = useState<any>('');

    /**Sync card index on slide */
    const syncActiveIndex = ({ item }: any) => {
        setActiveIndex(item);
    };

    /** navigate to themPark DetailedView */
    const navigateDetailedView = (value: any) => {
        navigate(ROUTES.THEMEPARK_DETAILE_VIEW + "?id=" + value?.id,);
    }

    const navigateCheckOut = () => {
        const accessToken = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN) || GetSessionStorageData(AUTHENTICATION.SHARED.GUEST_ACCESS_TOKEN);
        if (nullUndefinedEmptyCheck(accessToken)) {
            navigate(ROUTES.THEMEPARK_CHECKOUT + "?id=" + selectEvent + '&event=' + themeParkDetaile?.id, { state: { event: themeParkDetaile?.id, theme_park_id: selectEvent } });
        } else {
            // dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
            dispatch(setGuestSignupOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));

        }
    }


    useEffect(() => {
        setThemeParkDetaile(route?.state?.themePark);
        setThemeParkList([route?.state?.themePark])
        setSelectEvent(route?.state?.themePark?.main_events[0]?.id);
    }, [])


    /**Get Theme Park List*/
    const getThemeParkList = () => {
        axiosInstance
            .get(`${API_END_POINTS.THEMEPARKLIST}`)
            .then((Response: any) => {
                if (Response?.data) {
                    setThemeParkList(Response?.data);
                }
            })
            .catch(() => { });
    };

    const CoverImgEmpty = themeParkDetaile?.main_events?.map((item: any) => {
        return (
            <>
                <div className={selectEvent === item?.id ? "Event__curosal__wapper__active" : "Event__curosal__wapper"}
                    onClick={() => setSelectEvent(item?.id)}>
                    <img src={item?.event_image} alt="card_bg" />
                    <span>{item?.event_name}</span>
                </div>
            </>
        )
    })

    return (
        <>
            <div className="ThemePark__dashboard__container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="ThemeParkCard_Img_wapper" onClick={() => navigate(-1)}>
                            <img
                                draggable="false"
                                src={themeParkIcon}
                                alt="themeParkIcon"
                                className=""
                            />
                            <div className="TutorImg__text">Theme Parks</div>
                        </div>
                        <div className="search_field_wrapper mt-2">
                            <div className="search__autocomplete">
                                <form>
                                    <Autocomplete
                                        freeSolo
                                        popupIcon
                                        id="free-solo-2-demo"
                                        disableClearable={false}
                                        clearIcon={false}
                                        onChange={(e, value) => {
                                            // setSearchValue(value);
                                            // handlePassListPage(value)
                                        }}
                                        options={[]}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Search for Theme Park"
                                                onChange={(inputChangeEvent: any) => {
                                                    // onInputChange(inputChangeEvent.target.value.trim())
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'text',
                                                }}
                                            />
                                        )}
                                    />
                                    <img
                                        draggable="false"
                                        src={search}
                                        alt="search"
                                        className="search_button"
                                    />
                                </form>
                            </div>
                        </div>
                        <div className="mt-4 school__listView__container">
                            {themeParkList?.map((item: any, i: any) => {
                                return (
                                    <>
                                        <div className="ThemePark__list__wapper" key={i} onDoubleClick={() => navigateDetailedView(item)}>
                                            <div className="ThemePark__leftside" >
                                                <img src={themeParkImg} alt="" />
                                            </div>
                                            <div className="ThemePark__rightside">
                                                <div className="ThemePark__name">
                                                    <span className="name">{item?.themepark_name}</span>
                                                    <div className="imageIcon">
                                                        <img src={shareIcon} alt="" className="mb-1" />
                                                        <img src={LikeIcon} alt="" />
                                                    </div>
                                                </div>

                                                <div className="ThemePark__location">
                                                    <img src={locationIcon} alt="" />
                                                    <span className="location">{item?.address}</span>
                                                </div>

                                                <div className="ThemePark__description">
                                                    <span className="text__black">{item?.description}</span>
                                                </div>

                                                <div className="ThemePark__rating">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div className="ticket_text">Tickets : </div>
                                                        {item?.available_ticket !== 0 ? (
                                                            <div className="available_text ms-2">Available</div>
                                                        ) : (
                                                            <div className="sold_text ms-2">Sold</div>
                                                        )}
                                                    </div>
                                                    <div className="d-flex flex-row align-items-center">
                                                        <StarIcon className="StarIcon" />
                                                        <span className="points ms-2">{item?.rating}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5">
                        <div className="schoolListView__rightSide__Pop">
                            <div className='top__contant__wapper__ThemePark'>
                                <div className='top__PageBtn__icons'>
                                    <NavigateBeforeSharpIcon className='prev__icons' />
                                    <div className='prev__text'>Prev</div>
                                    <div className='prev__text'>Next</div>
                                    <NavigateNextSharpIcon className='next__icons' />
                                </div>
                                <div className='top__close__icons'>
                                    <Tooltip title="Expanded">
                                        <OpenInFullSharpIcon className='expanded__icons' onClick={() => navigateDetailedView(themeParkDetaile)} />
                                    </Tooltip>
                                    <Tooltip title="close">
                                        <CloseIcon className='close__icons' />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="Profile__contant__wapper">
                                <img src={themeParkImg} alt="" className="profile__img" />
                                <div className="Profile__rightside">
                                    <div className="Profile_name">{themeParkDetaile?.themepark_name}</div>
                                    <div className="Profile__box__wapper">
                                        <div className="box__contant">
                                            <img src={themeParkIcon} alt="" />
                                            <span>Theme Park</span>
                                        </div>
                                    </div>
                                    <div className="Profile__priceRange__wapper">
                                        <div className="priceRange__contant">
                                            <img src={PriceRange} alt="" />
                                            <span>Price per Ticket</span>
                                        </div>
                                        <div className="Price_text">AED {themeParkDetaile?.max_price}</div>
                                    </div>
                                    <div className="Profile__priceRange__wapper">
                                        <div className="priceRange__contant">
                                            <img src={themePark_star} alt="" />
                                            <span>Rating {themeParkDetaile?.rating}/5</span>
                                        </div>
                                        <Rating name="half-rating-read" className="StarIcon" value={parseInt(themeParkDetaile?.rating)} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="Description__wapper">
                                <div className="title">Description</div>
                                <div className="description__contant">{themeParkDetaile?.description}</div>
                            </div>
                            <div className="Description__wapper">
                                <div className="title">Main Events</div>
                                <AliceCarousel
                                    mouseTracking
                                    autoWidth
                                    disableButtonsControls
                                    items={CoverImgEmpty}
                                    disableDotsControls
                                    activeIndex={activeIndex}
                                    onSlideChanged={syncActiveIndex}
                                />
                            </div>
                            <div className="Description__wapper">
                                <div className="title">Highlights</div>
                                <div className="Highlight__wapper">
                                    {themeParkDetaile?.highlights?.map((data: any, i: any) => {
                                        return (
                                            <>
                                                <div className="contant">
                                                    <span className="text">{i + 1}</span>
                                                    <div className="text">{data}</div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="bookTicket__wapper">
                                <button className="bookTicketBtn" onClick={navigateCheckOut}>Book Tickets</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ThemeParkListView;