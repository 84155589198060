import "./AppFilter.scss";
import { Box, Dialog, DialogContent, Rating, Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, FILTER_CONSTANT, GLOBAL_CONSTANTS, GradeViewArray } from "../../Constants/Constants";
import { store } from "../../Redux/Store/Store";
import { setActivitiesFilter, setLoader, setSchoolFilter, setTutorFilter, setUniversityFilter } from "../../Redux/Reducer/Reducer";
import { nullUndefinedEmptyCheck, SetSessionStorageData } from "../../Utilities/Helpers/Helper";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FilterPopup from "../FilterPopup/FilterPopup";
import FilterIcon from "../../Assets/Tutor/FilterIcon.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const school_ratings = ["Outstanding", "Good", "Excellent", "Acceptable", "Unsatisfactory"];



const teaching_mode = ["Online", "Offline", "Both"];

const AppFilter = ({ onOpenPop, FilterPopOpen, onClosePop, FilterData, FilterApply, type, color, FilterApplyData, initFilter, initSort, handleApplySort }: any) => {

    const { t } = useTranslation();
    const [items, setItems] = useState<any>(FilterApplyData);
    const [marks, setMarks] = useState<any>({});
    const [rangeSets, setRangeSets] = useState<any>({});
    const [keys, setKeys] = useState<any>([]);
    const [displayCount, setDisplayCount] = useState<any>(4);

    // New filter

    // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const [anchorEl, setAnchorEl] = useState<{ [key: string]: null | HTMLElement }>({
        rating: null,
        curriculum: null,
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
        setAnchorEl((prevAnchorEl) => ({
            ...prevAnchorEl,
            [key]: event.currentTarget,
        }));
    };

    const handleClose = (key: string) => {
        setAnchorEl((prevAnchorEl) => ({
            ...prevAnchorEl,
            [key]: null,
        }));
    };

    // View for the Grade name
    const viewGradeName = (val: number) => {
        const result = GradeViewArray.find((item) => item.id === val);
        return result ? result.label : null; // Return the label if found, otherwise null
    };

    useEffect(() => {
        getFilterData();

    }, [FilterApplyData, FilterData, initFilter]);

    // useEffect(() => {
    //     getFilterData();
    //     setSelectedCheckExperience(Experience ? Experience : [])
    //     setSelectedCheckCourse(CourseData ? CourseData : []);
    //     setSelectedCheckGender(Gender ? Gender : [])
    //     setSelectedCheckGrade(Grade ? Grade : [])
    //     setSelectedCheckModOfTech(ModOfTech ? ModOfTech : [])
    //     setStartRating(Ratingvalue ? Ratingvalue : 0)
    //     setPriceRangeValue(PriceRange ? PriceRange : [0, 0])
    //     setFilterRadiusvalue(Radiusvalue ? Radiusvalue : [10])
    // }, [Experience, CourseData, Radiusvalue, PriceRange, Grade, Gender, ModOfTech, Ratingvalue])

    /**Get Filter Categories List*/
    const getFilterData = () => {

        let keys = Object.keys(initFilter);

        setKeys(keys);
        setDisplayCount(keys.length > 5 ? 3 : 4)  // Mention Index value here (like, if count 4, then 3)

        setItems(FilterApplyData);

        let data = FilterData;

        if (data.experience) {
            let min = 0;
            let max = data.experience.max;
            const markingDataExperience = getMarkingData(min, max, "Year");
            const rangeSetExperience = generateRangeSet(min, max, 5, "Year");

            setMarks((prevMarks: any) => ({
                ...prevMarks,
                "experience": markingDataExperience
            }));

            setRangeSets((prevRangeSets: any) => ({
                ...prevRangeSets,
                "experience": rangeSetExperience
            }));
        }

        if (data.price) {
            let min = 0;
            let max = data.price.max;
            const markingDataPrice = getMarkingData(min, max, "AED");
            const rangeSetPrice = generateRangeSet(min, max, 5, "AED");

            setMarks((prevMarks: any) => ({
                ...prevMarks,
                "price": markingDataPrice
            }));

            setRangeSets((prevRangeSets: any) => ({
                ...prevRangeSets,
                "price": rangeSetPrice
            }));
        }


    };



    const generateRangeSet = (min: number, max: number, numSegments: number, unit: string) => {
        const segmentSize = (max - min) / numSegments;
        const rangeSet = [];

        for (let i = 0; i < numSegments; i++) {
            const start = min + i * segmentSize;
            const end = min + (i + 1) * segmentSize;
            rangeSet.push({
                name: `${start}-${end} ${unit}`,
                value: [start, end]
            });
        }

        return rangeSet;
    };

    const getMarkingData = (min: number, max: number, unit: string) => {
        return [
            {
                value: min,
                label: `${min} ${unit}`,
            },
            {
                value: max,
                label: `${max} ${unit}`,
            }
        ];
    }



    const handleBubbleSelect = (name: string, option: any) => {
        if (items[name]) {
            if (items[name].includes(option)) {
                setItems({
                    ...items,
                    [name]: items[name].filter((item: any) => item !== option)
                });
            } else {
                setItems({
                    ...items,
                    [name]: [...items[name], option]
                });
            }
        } else {
            setItems({
                ...items,
                [name]: [option]
            });
        }
    };

    const handleSingleBubbleSelect = (name: string, value: any) => {
        if (value === "all") {

            setItems({
                ...items,
                [name]: []
            });
        } else {
            setItems({
                ...items,
                [name]: [value]
            });

        }

    }


    const handleRangeValue = (name: string, value: number | number[]) => {
        setItems({
            ...items,
            [name]: value as number[]
        });
    };

    const handleSingleSliderValue = (name: string, value: number | number[]) => {
        setItems({
            ...items,
            [name]: value as number
        });
    };

    const handleOnChange = (e: any) => {
        const { name, value } = e.target
        setItems({
            ...items,
            [name]: value
        });
    };


    const ApplyBtn = (name: string) => {
        FilterApply(items);
        onClosePop();
        handleClose(name);
    }

    /**Filter Reset Function*/
    const FilterReset = (name: string) => {
        store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));

        if (name === 'all') {
            FilterApply(initFilter);
            setItems(initFilter);
            ResetFilterRedux(initFilter);
        } else {
            let filter = { ...items, [name]: initFilter[name] };
            FilterApply(filter);
            setItems(filter);
            ResetFilterRedux(filter);
        }

        handleClose(name)
        onClosePop();
        setTimeout(() => {
            store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
        }, 500)

    }

    /**Reset Function from redux*/

    const ResetFilterRedux = (data: any) => {
        if (type === "school") {
            store.dispatch(setSchoolFilter(data));
            SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.SCHOOL, JSON.stringify(data));
        } else if (type === "university") {
            store.dispatch(setUniversityFilter(data));
            SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.UNIVERSITY, JSON.stringify(data));
        } else if (type === "tutor") {
            store.dispatch(setTutorFilter(data));
            SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.TUTOR, JSON.stringify(data));
        } else if (type === "activity") {
            store.dispatch(setActivitiesFilter(data));
            SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.ACTIVITY, JSON.stringify(data));
        }
    }

    const active_contant_text = {
        background: color,
        color: "#FFF",
        border: `1px solid ${color}`
    }

    const range_slider = {
        color: { color },
        '& .MuiSlider-thumb': {
            backgroundColor: { color },
        },
        '& .MuiSlider-track': {
            backgroundColor: { color },
        },
        width: '100%',
        margin: "0px 30px 0px 20px",
        '& .MuiSlider-markLabel': {
            color: '#333333', // Change color as needed
            fontSize: '12px', // Change font size as needed
            whiteSpace: "pre-wrap",
            textAlign: "center",
            fontFamily: "Manrope500",
            // width: "30px",
        },
        marginBottom: "20px",
    }


    const bobules__wapper = {
        display: "flex",
        flexDirection: "row" as const,
        flexWrap: "wrap" as const,
        gap: "10px",
        alignItems: "center",

    }

    const title_text = {
        color: "#333",
        fontFamily: "Inter500",
        fontSize: "14px",
    }

    const contant__wapper = {
        display: "flex",
        flexDirection: "column" as const,
        gap: "10px",
        width: "100%",
        maxHeight: "340px",
        overflowY: "auto" as const,
        overflowX: "hidden" as const,
    }
    const contant_text = {
        display: "inline-flex",
        height: "40px",
        padding: "10px 14px",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        borderRadius: "29px",
        cursor: "pointer",
        fontFamily: "Manrope500",
        fontSize: "12px",
        border: "1px solid #CBDCE1",
        background: "#FBFBFB",
        color: "#333",
        minWidth: "65px",

    }

    const menu_item = {
        maxWidth: "350px",
        minWidth: "300px"
    }

    const action_container = {

    }

    const applyBtn = {
        width: "95px",
        height: "40px",
        flexShrink: "0",
        borderRadius: "26px",
        background: color,
        color: "#FFF",
        fontFamily: "Inter500",
        fontSize: "14px",
    }

    const resetBtn = {
        width: "95px",
        height: "40px",
        flexShrink: "0",
        borderRadius: "26px",
        border: "1px solid #CBDCE1",
        color: "#333",
        fontFamily: "Inter500",
        fontSize: "14px",
    }

    const price_range = {
        color: "#333",
        fontFamily: "Manrope500",
        fontSize: "12px"
    }

    const selected_item = {
        display: "block",
        color: color,
        fontSize: "10px",
        fontFamily: "Inter400",
        textTransform: "capitalize" as const,
    }

    const checkArrayEqual = (arr1: any, arr2: any) => {
        return (arr1[0] === arr2[0]) && (arr1[1] === arr2[1])
    }

    const handleSortValue = (price: any, rating: any) => {
        handleApplySort(price, rating);
        onClosePop();
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });
    /*Below class name based on English Arabic*/
    const MorFillterIcon = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "MorFillterIcon_eng" : "MorFillterIcon_arab";
    const ItemButtonLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ItemButtonLang_eng" : "ItemButtonLang_arab";

    return (
        <React.Fragment>
            {FilterPopOpen && (
                <FilterPopup
                    FilterPopOpen={FilterPopOpen}
                    onClosePop={onClosePop}
                    items={items}
                    initFilter={initFilter}
                    initSort={initSort}
                    handleSortValue={handleSortValue}
                    FilterData={FilterData}
                    marks={marks}
                    rangeSets={rangeSets}
                    handleSingleSliderValue={handleSingleSliderValue}
                    handleRangeValue={handleRangeValue}
                    handleSingleBubbleSelect={handleSingleBubbleSelect}
                    FilterReset={FilterReset}
                    ApplyBtn={ApplyBtn}
                    checkArrayEqual={checkArrayEqual}
                    handleOnChange={handleOnChange}
                    type={type}
                    color={color}
                />
            )}

            <div className="filter_container" >
                {type === "school" && keys.indexOf('rating') !== -1 && keys.indexOf('rating') <= displayCount ?

                    <div className="filter_item">
                        <Button
                            id="basic-button-rating"
                            aria-controls={anchorEl.rating ? 'basic-menu-rating' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.rating ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'rating')}
                            endIcon={anchorEl.rating ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}
                        >
                            <div> {t("Ratings")} <span style={selected_item}> {items?.rating?.length ? items.rating[0] : t("Select")}</span> </div>
                        </Button>
                        <Menu
                            id="basic-menu-rating"
                            anchorEl={anchorEl.rating}
                            open={Boolean(anchorEl.rating)}
                            onClose={() => handleClose('rating')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-rating',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>
                                <div className="contant__wapper mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text} >{t("Ratings")}</div>
                                    <div className="bobules__wapper" style={bobules__wapper} >
                                        {(FilterData?.rating ? FilterData?.rating : school_ratings)?.map((item: any) => {
                                            if (item) {
                                                return (

                                                    <div style={items?.rating?.includes(item) ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("rating", item)}>{item}</div>

                                                )
                                            }
                                        })}
                                        <div style={items?.rating?.length === 0 ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("rating", "all")}>{t("All")} </div>

                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn('rating')}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset('rating')}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}

                {keys.indexOf('curriculum') !== -1 && keys.indexOf('curriculum') <= displayCount ?

                    <div className="filter_item">
                        <Button
                            id="basic-button-curriculum"
                            aria-controls={anchorEl.curriculum ? 'basic-menu-curriculum' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.curriculum ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'curriculum')}
                            endIcon={anchorEl.curriculum ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >

                            <div>{t("Curriculum")} <span style={selected_item}> {items?.curriculum?.length ? items.curriculum[0] : t("Select")}</span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-curriculum"
                            anchorEl={anchorEl.curriculum}
                            open={Boolean(anchorEl.curriculum)}
                            onClose={() => handleClose('curriculum')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-curriculum',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("Curriculum")}</div>
                                    <div className="bobules__wapper" style={bobules__wapper}>
                                        {FilterData.curriculum?.map((item: any, index: number) => {
                                            return (
                                                <div
                                                    key={index}
                                                    style={items?.curriculum?.includes(item) ? { ...contant_text, ...active_contant_text } : contant_text}
                                                    onClick={() => handleSingleBubbleSelect("curriculum", item)}>
                                                    {item}
                                                </div>
                                            )
                                        })}
                                        <div style={items?.curriculum?.length === 0 ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("curriculum", "all")}>{t("All")} </div>

                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("curriculum")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("curriculum")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}

                {keys.indexOf('programs') !== -1 && keys.indexOf('programs') <= displayCount ?

                    <div className="filter_item">
                        <Button
                            id="basic-button-programs"
                            aria-controls={anchorEl.programs ? 'basic-menu-programs' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.programs ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'programs')}
                            endIcon={anchorEl.programs ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >

                            <div>{t("Programs")} <span style={selected_item}> {items?.programs?.length ? items.programs[0] : t("Select")}</span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-programs"
                            anchorEl={anchorEl.programs}
                            open={Boolean(anchorEl.programs)}
                            onClose={() => handleClose('programs')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-programs',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("Programs")}</div>
                                    <div className="bobules__wapper" style={bobules__wapper}>
                                        {FilterData.programs?.map((item: any, index: number) => {
                                            return (

                                                <div key={index} style={items?.programs?.includes(item) ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("programs", item)}>{item}</div>

                                            )
                                        })}
                                        <div style={items?.programs?.length === 0 ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("programs", "all")}>{t("All")} </div>

                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("programs")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("programs")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}

                {keys.indexOf('language_instruction') !== -1 && keys.indexOf('language_instruction') <= displayCount ?

                    <div className="filter_item">
                        <Button
                            id="basic-button-language_instruction"
                            aria-controls={anchorEl.language_instruction ? 'basic-menu-language_instruction' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.language_instruction ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'language_instruction')}
                            endIcon={anchorEl.language_instruction ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >

                            <div>{t("Language of Instruction")} <span style={selected_item}> {items?.language_instruction?.length ? items.language_instruction[0] : t("Select")}</span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-language_instruction"
                            anchorEl={anchorEl.language_instruction}
                            open={Boolean(anchorEl.language_instruction)}
                            onClose={() => handleClose('language_instruction')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-language_instruction',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("Language of Instruction")}</div>
                                    <div className="bobules__wapper" style={bobules__wapper}>
                                        {FilterData.language_instruction?.map((item: any, index: number) => {
                                            return (

                                                <div key={index} style={items?.language_instruction?.includes(item) ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("language_instruction", item)}>{item}</div>

                                            )
                                        })}
                                        <div style={items?.language_instruction?.length === 0 ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("language_instruction", "all")}>{t("All")} </div>

                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("language_instruction")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("language_instruction")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}

                {keys.indexOf('courses') !== -1 && keys.indexOf('courses') <= displayCount ?

                    <div className="filter_item">
                        <Button
                            id="basic-button-courses"
                            aria-controls={anchorEl.courses ? 'basic-menu-courses' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.courses ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'courses')}
                            endIcon={anchorEl.courses ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >

                            <div>{t("Courses")} <span style={selected_item}> {items?.courses?.length ? items.courses[0] : t("Select")}</span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-courses"
                            anchorEl={anchorEl.courses}
                            open={Boolean(anchorEl.courses)}
                            onClose={() => handleClose('courses')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-courses',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("Courses")}</div>
                                    <div className="bobules__wapper" style={bobules__wapper}>
                                        {FilterData.courses?.map((item: any, index: number) => {
                                            return (

                                                <div key={index} style={items?.courses?.includes(item) ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("courses", item)}>{item}</div>
                                            )
                                        })}
                                        <div style={items?.courses?.length === 0 ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("courses", "all")}>{t("All")} </div>

                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("courses")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("courses")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}


                {keys.indexOf('mode_of_teaching') !== -1 && keys.indexOf('mode_of_teaching') <= displayCount ?

                    <div className="filter_item">
                        <Button
                            id="basic-button-mode_of_teaching"
                            aria-controls={anchorEl.mode_of_teaching ? 'basic-menu-mode_of_teaching' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.mode_of_teaching ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'mode_of_teaching')}
                            endIcon={anchorEl.mode_of_teaching ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >

                            <div>{t("Mode of Teaching")} <span style={selected_item}> {items?.mode_of_teaching?.length ? items.mode_of_teaching[0] == 'Offline' ? t("On-premises") : items.mode_of_teaching[0] : t("Select")}</span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-mode_of_teaching"
                            anchorEl={anchorEl.mode_of_teaching}
                            open={Boolean(anchorEl.mode_of_teaching)}
                            onClose={() => handleClose('mode_of_teaching')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-mode_of_teaching',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("Mode Of Teaching")}</div>
                                    <div className="bobules__wapper" style={bobules__wapper}>
                                        {FilterData.mode_of_teaching?.map((item: any, index: number) => {
                                            return (

                                                <div key={index} style={items?.mode_of_teaching?.includes(item) ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("mode_of_teaching", item)}>{item == 'Offline' ? t("On-premises") : item}</div>

                                            )
                                        })}
                                        <div style={items?.mode_of_teaching?.length === 0 ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("mode_of_teaching", "all")}>{t("All")} </div>

                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("mode_of_teaching")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("mode_of_teaching")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}

                {keys.indexOf('activities') !== -1 && keys.indexOf('activities') <= displayCount ?

                    <div className="filter_item">
                        <Button
                            id="basic-button-activities"
                            aria-controls={anchorEl.activities ? 'basic-menu-activities' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.activities ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'activities')}
                            endIcon={anchorEl.activities ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >

                            <div>{t("Activities")}<span style={selected_item}> {items?.activities?.length ? items.activities[0] : t("Select")}</span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-activities"
                            anchorEl={anchorEl.activities}
                            open={Boolean(anchorEl.activities)}
                            onClose={() => handleClose('activities')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-activities',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("Activities")}</div>
                                    <div className="bobules__wapper" style={bobules__wapper}>
                                        {FilterData.activities?.map((item: any, index: number) => {
                                            return (

                                                <div key={index} style={items?.activities?.includes(item) ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("activities", item)}>{item}</div>

                                            )
                                        })}
                                        <div style={items?.activities?.length === 0 ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("activities", "all")}>{t("All")} </div>

                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("activities")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("activities")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}

                {keys.indexOf('gender') !== -1 && keys.indexOf('gender') <= displayCount ?

                    <div className="filter_item">
                        <Button
                            id="basic-button-gender"
                            aria-controls={anchorEl.gender ? 'basic-menu-gender' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.gender ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'gender')}
                            endIcon={anchorEl.gender ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >

                            <div>{t("Gender")} <span style={selected_item}> {items?.gender?.length ? items.gender[0] : t("Select")}</span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-gender"
                            anchorEl={anchorEl.gender}
                            open={Boolean(anchorEl.gender)}
                            onClose={() => handleClose('gender')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-gender',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("Mode Of Teaching")}</div>
                                    <div className="bobules__wapper" style={bobules__wapper}>
                                        {FilterData.gender?.map((item: any, index: number) => {
                                            return (

                                                <div key={index} style={items?.gender?.includes(item) ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("gender", item)}>{item}</div>

                                            )
                                        })}
                                        <div style={items?.gender?.length === 0 ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleSingleBubbleSelect("gender", "all")}>{t("All")} </div>

                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("gender")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("gender")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}


                {keys.indexOf('price') !== -1 && keys.indexOf('price') <= displayCount ?


                    <div className="filter_item">
                        <Button
                            id="basic-button-price"
                            aria-controls={anchorEl.price ? 'basic-menu-price' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.price ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'price')}
                            endIcon={anchorEl.price ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >
                            <div>{t("Avg Fees")} <span style={selected_item}> {items?.price[0]} <span style={{ color: "#333333" }}>{t("AED")}</span>  - {items?.price[1]}<span style={{ color: "#333333" }}>{t("AED")}</span></span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-price"
                            anchorEl={anchorEl.price}
                            open={Boolean(anchorEl.price)}
                            onClose={() => handleClose('price')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-price',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("Avg Fees")}</div>

                                    <div style={price_range}>
                                        {t("AED")}.<span style={{ color: color }}>{items.price[0]}</span>- {t("AED")}.<span style={{ color: color }}>{items.price[1]}</span>
                                    </div>

                                    <div style={bobules__wapper}>
                                        {rangeSets.price?.map((item: any, index: number) => {
                                            return (

                                                <div key={index} style={checkArrayEqual(item.value, items.price) ? { ...contant_text, ...active_contant_text } : contant_text} onClick={() => handleRangeValue("price", item.value)}>{item.name}</div>

                                            )
                                        })}
                                    </div>
                                    <div style={bobules__wapper}>
                                        <Slider
                                            value={items.price}
                                            onChange={(event, newValue) => handleRangeValue("price", newValue)}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={FilterData.price?.max}
                                            step={100}
                                            marks={marks.price}
                                            sx={range_slider}
                                        />
                                    </div>
                                </div>


                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("price")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("price")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}



                {keys.indexOf('grade') !== -1 && keys.indexOf('grade') <= displayCount ?


                    <div className="filter_item">
                        <Button
                            id="basic-button-grade"
                            aria-controls={anchorEl.grade ? 'basic-menu-grade' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.grade ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'grade')}
                            endIcon={anchorEl.grade ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >
                            <div>{t("Grade/Year")} <span style={selected_item}> {viewGradeName(items?.grade[0])} <span style={{ color: "#333333" }}></span>  - {viewGradeName(items?.grade[1])}<span style={{ color: "#333333" }}></span></span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-grade"
                            anchorEl={anchorEl.grade}
                            open={Boolean(anchorEl.grade)}
                            onClose={() => handleClose('grade')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-grade',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("Grade/Year")}</div>

                                    <div style={price_range}>
                                        <span style={{ color: color }}>{viewGradeName(items?.grade[0])}</span> - <span style={{ color: color }}>{viewGradeName(items?.grade[1])}</span>
                                    </div>


                                    <div style={bobules__wapper}>
                                        <Slider
                                            value={items.grade}
                                            onChange={(event, newValue) => handleRangeValue("grade", newValue)}
                                            valueLabelDisplay="auto"
                                            min={FilterData.grade?.min}
                                            max={FilterData.grade?.max}
                                            step={1}
                                            marks={marks.grade}
                                            sx={range_slider}
                                            valueLabelFormat={(value) => viewGradeName(value)}
                                        />
                                    </div>
                                </div>


                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("grade")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("grade")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}

                {keys.indexOf('age') !== -1 && keys.indexOf('age') <= displayCount ?


                    <div className="filter_item">
                        <Button
                            id="basic-button-age"
                            aria-controls={anchorEl.age ? 'basic-menu-age' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.age ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'age')}
                            endIcon={anchorEl.age ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >
                            <div>{t("Age")} <span style={selected_item}> {items?.age[0]} <span style={{ color: "#333333" }}>{t("Year")}</span>  - {items?.age[1]}<span style={{ color: "#333333" }}>{t("Year")}</span></span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-age"
                            anchorEl={anchorEl.age}
                            open={Boolean(anchorEl.age)}
                            onClose={() => handleClose('age')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-age',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("Age")}</div>

                                    <div style={price_range}>
                                        <span style={{ color: color }}>{items.age[0]}</span> - <span style={{ color: color }}>{items.age[1]}</span>
                                    </div>


                                    <div style={bobules__wapper}>
                                        <Slider
                                            value={items.age}
                                            onChange={(event, newValue) => handleRangeValue("age", newValue)}
                                            valueLabelDisplay="auto"
                                            min={FilterData.age?.min}
                                            max={FilterData.age?.max}
                                            step={1}
                                            marks={marks.age}
                                            sx={range_slider}
                                        />
                                    </div>
                                </div>


                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("age")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("age")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}

                {keys.indexOf('qs_ranking') !== -1 && keys.indexOf('qs_ranking') <= displayCount ?


                    <div className="filter_item">
                        <Button
                            id="basic-button-qs_ranking"
                            aria-controls={anchorEl.qs_ranking ? 'basic-menu-qs_ranking' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.qs_ranking ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'qs_ranking')}
                            endIcon={anchorEl.qs_ranking ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >
                            <div>{t("QS Ranking")} <span style={selected_item}> {items?.qs_ranking[0] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items?.qs_ranking[0]} <span style={{ color: "#333333" }}></span>  - {items?.qs_ranking[1] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items?.qs_ranking[1]}<span style={{ color: "#333333" }}></span></span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-qs_ranking"
                            anchorEl={anchorEl.qs_ranking}
                            open={Boolean(anchorEl.qs_ranking)}
                            onClose={() => handleClose('qs_ranking')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-qs_ranking',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("QS Ranking")}</div>

                                    <div style={price_range}>
                                        <span style={{ color: color }}>{items.qs_ranking[0] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.qs_ranking[0]}</span> - <span style={{ color: color }}>{items.qs_ranking[1] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.qs_ranking[1]}</span>
                                    </div>


                                    <div style={bobules__wapper}>
                                        <Slider
                                            value={items.qs_ranking}
                                            onChange={(event, newValue) => handleRangeValue("qs_ranking", newValue)}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            // min={FilterData.qs_ranking?.min}
                                            max={FilterData.qs_ranking?.max}
                                            step={1}
                                            marks={marks.qs_ranking}
                                            sx={range_slider}
                                        />
                                    </div>
                                </div>


                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("qs_ranking")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("qs_ranking")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}



                {keys.indexOf('sh_ranking') !== -1 && keys.indexOf('sh_ranking') <= displayCount ?


                    <div className="filter_item">
                        <Button
                            id="basic-button-sh_ranking"
                            aria-controls={anchorEl.sh_ranking ? 'basic-menu-sh_ranking' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.sh_ranking ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'sh_ranking')}
                            endIcon={anchorEl.sh_ranking ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >
                            <div>{t("SH Ranking")} <span style={selected_item}> {items?.sh_ranking[0] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items?.sh_ranking[0]} <span style={{ color: "#333333" }}></span>  - {items?.sh_ranking[1] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items?.sh_ranking[1]}<span style={{ color: "#333333" }}></span></span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-sh_ranking"
                            anchorEl={anchorEl.sh_ranking}
                            open={Boolean(anchorEl.sh_ranking)}
                            onClose={() => handleClose('sh_ranking')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-sh_ranking',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("SH Ranking")}</div>

                                    <div style={price_range}>
                                        <span style={{ color: color }}>{items.sh_ranking[0] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.sh_ranking[0]}</span> - <span style={{ color: color }}>{items.sh_ranking[1] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.sh_ranking[1]}</span>
                                    </div>


                                    <div style={bobules__wapper}>
                                        <Slider
                                            value={items.sh_ranking}
                                            onChange={(event, newValue) => handleRangeValue("sh_ranking", newValue)}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            // min={FilterData.sh_ranking?.min}
                                            max={FilterData.sh_ranking?.max}
                                            step={1}
                                            marks={marks.sh_ranking}
                                            sx={range_slider}
                                        />
                                    </div>
                                </div>


                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("sh_ranking")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("sh_ranking")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}

                {keys.indexOf('the_ranking') !== -1 && keys.indexOf('the_ranking') <= displayCount ?


                    <div className="filter_item">
                        <Button
                            id="basic-button-the_ranking"
                            aria-controls={anchorEl.the_ranking ? 'basic-menu-the_ranking' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.the_ranking ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'the_ranking')}
                            endIcon={anchorEl.the_ranking ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}

                        >
                            <div> {t("THE Ranking")} <span style={selected_item}> {items?.the_ranking[0] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items?.the_ranking[0]} <span style={{ color: "#333333" }}></span>  - {items?.the_ranking[1] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items?.the_ranking[1]}<span style={{ color: "#333333" }}></span></span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-the_ranking"
                            anchorEl={anchorEl.the_ranking}
                            open={Boolean(anchorEl.the_ranking)}
                            onClose={() => handleClose('the_ranking')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-the_ranking',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>{t("THE Ranking")}</div>

                                    <div style={price_range}>
                                        <span style={{ color: color }}>{items.the_ranking[0] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.the_ranking[0]}</span> - <span style={{ color: color }}>{items.the_ranking[1] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.the_ranking[1]}</span>
                                    </div>


                                    <div style={bobules__wapper}>
                                        <Slider
                                            value={items.the_ranking}
                                            onChange={(event, newValue) => handleRangeValue("the_ranking", newValue)}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            // min={FilterData.the_ranking?.min}
                                            max={FilterData.the_ranking?.max}
                                            step={1}
                                            marks={marks.the_ranking}
                                            sx={range_slider}
                                        />
                                    </div>
                                </div>


                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("the_ranking")}>{t("Apply")}</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("the_ranking")}>{t("Reset")}</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""}


                {/* {keys.indexOf('radius') !== -1 && keys.indexOf('radius') <= displayCount ?

                    <div className="filter_item">
                        <Button
                            id="basic-button-radius"
                            aria-controls={anchorEl.radius ? 'basic-menu-radius' : undefined}
                            aria-haspopup="true"
                            aria-expanded={anchorEl.radius ? 'true' : undefined}
                            onClick={(event) => handleClick(event, 'radius')}
                            endIcon={anchorEl.radius ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            className="item_button"

                        >
                            <div> Radius <span style={selected_item}> {items.radius} <span style={{ color: "#333333" }}>KM</span></span> </div>

                        </Button>
                        <Menu
                            id="basic-menu-radius"
                            anchorEl={anchorEl.radius}
                            open={Boolean(anchorEl.radius)}
                            onClose={() => handleClose('radius')}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button-radius',
                            }}
                        >
                            <MenuItem className="menu_item" style={menu_item}>

                                <div className="contant__wapper  mb-4" style={contant__wapper}>
                                    <div className="title_text" style={title_text}>Map Location Radius</div>
                                    <div style={price_range}>
                                        <span style={{ color: color }}>{items.radius}</span>KM
                                    </div>

                                    <div style={bobules__wapper}>
                                        <Slider
                                            value={items.radius} // Set the value prop to the value state
                                            onChange={(event, newValue) => handleSingleSliderValue("radius", newValue)}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={1000}
                                            step={1}
                                            sx={{
                                                color: { color },
                                                '& .MuiSlider-thumb': {
                                                    backgroundColor: { color },
                                                },
                                                '& .MuiSlider-track': {
                                                    backgroundColor: { color },
                                                },
                                                width: '100%',
                                                margin: "0px 10px"

                                            }}
                                        />

                                    </div>
                                </div>


                            </MenuItem>
                            <MenuItem >
                                <div style={action_container} >
                                    <button className="applyBtn" style={applyBtn} onClick={() => ApplyBtn("radius")}>Apply</button>
                                    <button className="resetBtn" style={resetBtn} onClick={() => FilterReset("radius")}>Reset</button>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div> : ""} */}


                {keys.length > 4 ?


                    <div className="filter_item">
                        <Button

                            onClick={onOpenPop}
                            // className="item_button"
                            className={`${ItemButtonLang} item_button`}
                        >
                            <div className={`${MorFillterIcon} more-fillter-icon`}>{t("More Filter")} </div>
                            <img
                                draggable="false"
                                src={FilterIcon}
                                alt="FilterIcon"
                                className="FilterIcon"
                            />
                        </Button>
                    </div> : ""}




            </div>
        </React.Fragment >
    )
}

export default AppFilter;