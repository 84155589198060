import "./FilterPopup.scss";
import { Box, Dialog, DialogContent, Rating, Slider, Radio } from "@mui/material";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { API_END_POINTS, GLOBAL_CONSTANTS, GradeViewArray } from "../../Constants/Constants";
import { store } from "../../Redux/Store/Store";
import { setLoader } from "../../Redux/Reducer/Reducer";
import { nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import { useTranslation } from "react-i18next";
import { useTheme, useMediaQuery } from '@mui/material';

const school_ratings = ["Outstanding", "Good", "Excellent", "Acceptable", "Unsatisfactory"];

const FilterPopup = ({ FilterPopOpen, onClosePop, type, color, initFilter, initSort, handleSortValue, handleSingleBubbleSelect, items, FilterData, handleRangeValue, FilterReset, ApplyBtn, marks, rangeSets, handleSingleSliderValue, checkArrayEqual, handleOnChange }: any) => {


    const { t } = useTranslation();
    const [ActiveState, setActiveState] = useState<any>(1);
    const [CheckedPrice, setCheckedPrice] = useState<any>(initSort?.price);
    const [CheckedRating, setCheckedRating] = useState<any>(initSort?.rating);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    // const [marks, setMarks] = useState<any>({});
    // const [rangeSets, setRangeSets] = useState<any>({});

    // console.log(items, "items")

    // useEffect(() => {
    //     getFilterData();
    // }, []);

    // useEffect(() => {
    //     getFilterData();
    //     setSelectedCheckExperience(Experience ? Experience : [])
    //     setSelectedCheckCourse(CourseData ? CourseData : []);
    //     setSelectedCheckGender(Gender ? Gender : [])
    //     setSelectedCheckGrade(Grade ? Grade : [])
    //     setSelectedCheckModOfTech(ModOfTech ? ModOfTech : [])
    //     setStartRating(Ratingvalue ? Ratingvalue : 0)
    //     setPriceRangeValue(PriceRange ? PriceRange : [0, 0])
    //     setFilterRadiusvalue(Radiusvalue ? Radiusvalue : [10])
    // }, [Experience, CourseData, Radiusvalue, PriceRange, Grade, Gender, ModOfTech, Ratingvalue])

    /**Get Filter Categories List*/
    // const getFilterData = () => {
    //     let URI = "";

    //     switch (type) {
    //         case 'school':
    //             URI = API_END_POINTS.SCHOOLFILTERLIST;
    //             break;
    //         case 'tutor':
    //             URI = API_END_POINTS.TUTORFILTERLIST;
    //             break;
    //         case 'university':
    //             URI = API_END_POINTS.UNIVERSITYFILTERLIST;
    //             break;
    //         default:
    //             break;
    //     }

    //     axiosInstance
    //         .get(`${URI}`)
    //         .then((Response: any) => {
    //             if (Response?.data?.status) {
    //                 let data = Response?.data?.result?.data;
    //                 setFilterData(data);

    //                 let price = data.fees || data.price;
    //                 console.log(price, "price")

    //                 if (data.experience?.length) {
    //                     const sortedExperience = data.experience.sort((a: any, b: any) => a - b); // Ensure ascending order
    //                     const min = 0;
    //                     const max = sortedExperience[data.experience.length - 1];
    //                     const markingDataExperience = getMarkingData(min, max, "Year");
    //                     const rangeSetExperience = generateRangeSet(min, max, 5, "Year");

    //                     setMarks((prevMarks: any) => ({
    //                         ...prevMarks,
    //                         "experience": markingDataExperience
    //                     }));

    //                     setRangeSets((prevRangeSets: any) => ({
    //                         ...prevRangeSets,
    //                         "experience": rangeSetExperience
    //                     }));
    //                 }

    //                 if (price) {
    //                     let min = 0;
    //                     let max = price.max;
    //                     const markingDataPrice = getMarkingData(min, max, "AED");
    //                     const rangeSetPrice = generateRangeSet(min, max, 5, "AED");

    //                     setMarks((prevMarks: any) => ({
    //                         ...prevMarks,
    //                         "price": markingDataPrice
    //                     }));

    //                     setRangeSets((prevRangeSets: any) => ({
    //                         ...prevRangeSets,
    //                         "price": rangeSetPrice
    //                     }));
    //                 }

    //             }
    //         })
    //         .catch(() => { });
    // };

    // console.log(marks, rangeSets, "rangeSets")

    // const generateRangeSet = (min: number, max: number, numSegments: number, unit: string) => {
    //     const segmentSize = (max - min) / numSegments;
    //     const rangeSet = [];

    //     for (let i = 0; i < numSegments; i++) {
    //         const start = min + i * segmentSize;
    //         const end = min + (i + 1) * segmentSize;
    //         rangeSet.push({
    //             name: `${start}-${end} ${unit}`,
    //             value: [start, end]
    //         });
    //     }

    //     return rangeSet;
    // };

    // const getMarkingData = (min: number, max: number, unit: string) => {
    //     return [
    //         {
    //             value: min,
    //             label: `${min} ${unit}`,
    //         },
    //         {
    //             value: max,
    //             label: `${max} ${unit}`,
    //         }
    //     ];
    // }



    // const handleBubbleSelect = (name: string, option: any) => {
    //     if (items[name]) {
    //         if (items[name].includes(option)) {
    //             setItems({
    //                 ...items,
    //                 [name]: items[name].filter((item: any) => item !== option)
    //             });
    //         } else {
    //             setItems({
    //                 ...items,
    //                 [name]: [...items[name], option]
    //             });
    //         }
    //     } else {
    //         setItems({
    //             ...items,
    //             [name]: [option]
    //         });
    //     }
    // };
    // console.log(items, "items")

    // // const handleSingleBubbleSelect = (name: string, value: any) => {
    // //     setItems({
    // //         ...items,
    // //         [name]: value
    // //     });
    // // };

    // const handleRangeValue = (name: string, value: number | number[]) => {
    //     setItems({
    //         ...items,
    //         [name]: value as number[]
    //     });
    // };

    // const handleSingleSliderValue = (name: string, value: number | number[]) => {
    //     setItems({
    //         ...items,
    //         [name]: value as number
    //     });
    // };

    // const handleOnChange = (e: any) => {
    //     const { name, value } = e.target
    //     setItems({
    //         ...items,
    //         [name]: value
    //     });
    // };


    // const ApplyBtn = () => {
    //     FilterApply(items);
    //     onClosePop();
    // }

    // /**Filter Reset Function*/
    // const FilterReset = () => {
    //     store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));

    //     FilterApply(initFilter);
    //     setItems(initFilter)
    //     setTimeout(() => {
    //         store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    //     }, 500)

    // }

    const active_contant_text = {
        background: color,
        color: "#FFF",
        border: `1px solid ${color}`
    }

    const range_slider = {
        color: { color },
        '& .MuiSlider-thumb': {
            backgroundColor: { color },
        },
        '& .MuiSlider-track': {
            backgroundColor: { color },
        },
        width: '80%',
        margin: "0px 10px",
        '& .MuiSlider-markLabel': {
            color: '#333333', // Change color as needed
            fontSize: '12px', // Change font size as needed
            whiteSpace: "pre-wrap",
            textAlign: "center",
            fontFamily: "Manrope500",
            width: "30px",
        },
    }

    const timeFormat = (value: number) => {
        if (value === 24) {
            return `23:59`;
        }
        const hours = Math.floor(value);
        const minutes = (value % 1) * 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    // View for the Grade name
    const viewGradeName = (val: number) => {
        const result = GradeViewArray.find((item) => item.id === val);
        return result ? result.label : null; // Return the label if found, otherwise null
    };

    return (
        <>
            <Dialog open={FilterPopOpen} sx={{
                // minWidth: '600px',
                minWidth: matches ? 'auto' : '600px',
            }}>
                <DialogContent>
                    <div className="TutorFilterPop_conatiner">
                        <div className="Filter_header__wapper">
                            <div className="header_title_wapper">
                                <div className={ActiveState == 1 ? "header_title_active" : "header_title"} style={ActiveState == 1 ? active_contant_text : {}} onClick={() => setActiveState(1)} >{t("Filters")}</div>
                                <div className={ActiveState == 2 ? "header_title_active" : "header_title"} style={ActiveState == 2 ? active_contant_text : {}} onClick={() => setActiveState(2)}>{t("Sort")}</div>
                            </div>
                            <span className="close__text" onClick={onClosePop}>{t("close")}</span>
                        </div>

                        {ActiveState == 1 ? (
                            <div className="Filter__body__contant">

                                {type === "school" && initFilter.hasOwnProperty('rating') ?
                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("Ratings")}</div>
                                        <div className="bobules__wapper">
                                            {(FilterData?.rating ? FilterData?.rating : school_ratings)?.map((item: any) => {
                                                if (item) {
                                                    return (
                                                        <>
                                                            <div className="contant_text" style={items?.rating?.includes(item) ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("rating", item)}>{item}</div>
                                                        </>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div> : ""}

                                {initFilter?.hasOwnProperty('curriculum') ?
                                    <div className="contant__wapper  mb-4">
                                        <div className="title_text">{t("Curriculum")}</div>
                                        <div className="bobules__wapper">
                                            {FilterData?.curriculum?.map((item: any) => {
                                                return (
                                                    <>
                                                        <div className="contant_text" style={items?.curriculum?.includes(item) ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("curriculum", item)}>{item}</div>
                                                    </>
                                                )
                                            })}
                                            <div className="contant_text" style={items?.curriculum?.length === 0 ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("curriculum", "all")}> All </div>

                                        </div>
                                    </div>
                                    : ""}

                                {initFilter.hasOwnProperty('activities') ?
                                    <div className="contant__wapper  mb-4">
                                        <div className="title_text">{t("Activities")}</div>
                                        <div className="bobules__wapper">
                                            {FilterData.activities?.map((item: any) => {
                                                return (
                                                    <>
                                                        <div className="contant_text" style={items?.activities?.includes(item) ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("activities", item)}>{item}</div>
                                                    </>
                                                )
                                            })}
                                            <div className="contant_text" style={items?.activities?.length === 0 ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("activities", "all")}>{t("All")} </div>

                                        </div>
                                    </div>
                                    : ""}

                                {initFilter.hasOwnProperty('programs') ?

                                    <div className="contant__wapper  mb-4">
                                        <div className="title_text">{t("Programs")}</div>
                                        <div className="bobules__wapper">
                                            {FilterData.programs?.map((item: any) => {
                                                return (
                                                    <>
                                                        <div className="contant_text" style={items?.programs?.includes(item) ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("programs", item)}>{item}</div>
                                                    </>
                                                )
                                            })}
                                            <div className="contant_text" style={items?.programs?.length === 0 ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("programs", "all")}>{t("All")} </div>

                                        </div>
                                    </div>
                                    : ""}

                                {initFilter.hasOwnProperty('language_instruction') ?

                                    <div className="contant__wapper  mb-4">
                                        <div className="title_text">{t("Language of Instruction")}</div>
                                        <div className="bobules__wapper">
                                            {FilterData.language_instruction?.map((item: any) => {
                                                return (
                                                    <>
                                                        <div className="contant_text" style={items?.language_instruction?.includes(item) ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("language_instruction", item)}>{item}</div>
                                                    </>
                                                )
                                            })}
                                            <div className="contant_text" style={items?.language_instruction?.length === 0 ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("language_instruction", "all")}>{t("All")} </div>


                                        </div>
                                    </div>
                                    : ""}





                                {/* <div className="contant__wapper mb-4">
                 <div className="title_text">Graduation Medium</div>
                 <div className="bobules__wapper">
                     {FilterData.gradution_medium?.map((item: any) => {
                         return (
                             <>
                                 <div className="contant_text" style={items?.univerity_medium?.includes(item) ? active_contant_text : {}} onClick={() => handleBubbleSelect("univerity_medium", item)}>{item}</div>
                             </>
                         )
                     })}
                 </div>
             </div> */}





                                {initFilter.hasOwnProperty('courses') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("Course Category")}</div>
                                        <div className="bobules__wapper">
                                            {FilterData.courses?.map((item: any) => {
                                                return (
                                                    <>
                                                        <div className="contant_text" style={items?.courses?.includes(item) ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("courses", item)}>{item}</div>
                                                    </>
                                                )
                                            })}
                                            <div className="contant_text" style={items?.courses?.length === 0 ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("courses", "all")}>{t("All")} </div>

                                        </div>
                                    </div> : ""}

                                {initFilter.hasOwnProperty('experience') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("Years of Experience")}</div>
                                        <div className="price_range">
                                            <span style={{ color: color }}>{items.experience[0]}</span>{t(" Year - ")}<span style={{ color: color }}>{items.experience[1]}</span> {t("Year")}
                                        </div>
                                        {/* <div className="bobules__wapper">
                 {rangeSets.experience?.map((item: any) => {
                     return (
                         <>
                             <div className="contant_text" style={checkArrayEqual(item.value, items.experience) ? active_contant_text : {}} onClick={() => handleRangeValue("experience", item.value)}>{item.name}</div>
                         </>
                     )
                 })}
             </div> */}
                                        <div className="bobules__wapper" style={{ marginBottom: "35px" }}>
                                            <Slider
                                                value={items.experience}
                                                onChange={(event, newValue) => handleRangeValue("experience", newValue)}
                                                valueLabelDisplay="auto"
                                                min={0}
                                                max={FilterData.experience?.max}
                                                step={1}
                                                marks={marks.experience}
                                                sx={range_slider}
                                            />
                                        </div>
                                    </div> : ""}

                                {initFilter.hasOwnProperty('mode_of_teaching') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("Mode Of Teaching")}</div>
                                        <div className="bobules__wapper">
                                            {FilterData.mode_of_teaching?.map((item: any) => {
                                                return (
                                                    <>
                                                        <div className="contant_text" style={items?.mode_of_teaching?.includes(item) ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("mode_of_teaching", item)}>{item == 'Offline' ? t("On-premises") : item}</div>
                                                    </>
                                                )
                                            })}
                                            <div className="contant_text" style={items?.mode_of_teaching?.length === 0 ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("mode_of_teaching", "all")}>{t("All")} </div>

                                        </div>
                                    </div> : ""}

                                {/* {initFilter.hasOwnProperty('grade') ?

         <div className="contant__wapper mb-4">
             <div className="title_text">Grade</div>
             <div className="bobules__wapper">
                 {FilterData.grade?.map((item: any) => {
                     return (
                         <>
                             <div className="contant_text" style={items?.grade === item ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("grade", item)}>{item}</div>
                         </>
                     )
                 })}
             </div>
         </div> : ""} */}

                                {initFilter.hasOwnProperty('gender') ?


                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("Gender")}</div>
                                        <div className="bobules__wapper">
                                            {FilterData.gender?.map((item: any) => {
                                                return (
                                                    <>
                                                        <div className="contant_text" style={items?.gender?.includes(item) ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("gender", item)}>{item}</div>
                                                    </>
                                                )
                                            })}
                                            <div className="contant_text" style={items?.gender?.length === 0 ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("gender", "all")}>{t("All")} </div>

                                        </div>
                                    </div> : ""}

                                {type === "tutor" || type === "activity" && initFilter.hasOwnProperty('rating') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("Ratings")}</div>
                                        <div className="bobules__wapper">
                                            <Rating
                                                name="rating"
                                                precision={0.5}
                                                value={items.rating}
                                                onChange={handleOnChange}
                                            />
                                            <div className="price_range" style={{ color: color }}>{items.rating}</div>
                                        </div>
                                    </div> : ""}


                                {initFilter.hasOwnProperty('qs_ranking') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("QS Ranking")}</div>
                                        <div className="price_range">
                                            <span style={{ color: color }}>{items.qs_ranking[0] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.qs_ranking[0]}</span> - <span style={{ color: color }}>{items.qs_ranking[1] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.qs_ranking[1]}</span>
                                        </div>
                                        <div className="bobules__wapper" >
                                            <Slider
                                                value={items.qs_ranking}
                                                onChange={(event, newValue) => handleRangeValue("qs_ranking", newValue)}
                                                valueLabelDisplay="auto"
                                                min={0}
                                                // min={FilterData.qs_ranking?.min}
                                                max={FilterData.qs_ranking?.max}
                                                step={1}
                                                sx={range_slider}

                                            />
                                        </div>
                                    </div> : ""}

                                {initFilter.hasOwnProperty('the_ranking') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("THE Ranking")}</div>
                                        <div className="price_range">
                                            <span style={{ color: color }}>{items.the_ranking[0] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.the_ranking[0]}</span> - <span style={{ color: color }}>{items.the_ranking[1] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.the_ranking[1]}</span>
                                        </div>
                                        <div className="bobules__wapper" >
                                            <Slider
                                                value={items.the_ranking}
                                                onChange={(event, newValue) => handleRangeValue("the_ranking", newValue)}
                                                valueLabelDisplay="auto"
                                                min={0}
                                                // min={FilterData.the_ranking?.min}
                                                max={FilterData.the_ranking?.max}
                                                step={1}
                                                sx={range_slider}

                                            />
                                        </div>
                                    </div> : ""}

                                {initFilter.hasOwnProperty('sh_ranking') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("SH Ranking")}</div>
                                        <div className="price_range">
                                            <span style={{ color: color }}>{items.sh_ranking[0] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.sh_ranking[0]}</span> - <span style={{ color: color }}>{items.sh_ranking[1] === 0 ? GLOBAL_CONSTANTS.INITIAL_VALUES.NA : items.sh_ranking[1]}</span>
                                        </div>
                                        <div className="bobules__wapper" >
                                            <Slider
                                                value={items.sh_ranking}
                                                onChange={(event, newValue) => handleRangeValue("sh_ranking", newValue)}
                                                valueLabelDisplay="auto"
                                                min={0}
                                                // min={FilterData.sh_ranking?.min}
                                                max={FilterData.sh_ranking?.max}
                                                step={1}
                                                sx={range_slider}

                                            />
                                        </div>
                                    </div> : ""}

                                {(type === 'tutor' || type === "school") && initFilter.hasOwnProperty('grade') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("Grade")}</div>
                                        <div className="price_range">
                                            <span style={{ color: color }}>{viewGradeName(items.grade[0])}</span> - <span style={{ color: color }}>{viewGradeName(items.grade[1])}</span>
                                        </div>
                                        <div className="bobules__wapper" >
                                            <Slider
                                                value={items.grade}
                                                onChange={(event, newValue) => handleRangeValue("grade", newValue)}
                                                valueLabelDisplay="auto"
                                                min={FilterData.grade?.min}
                                                max={FilterData.grade?.max}
                                                step={1}
                                                sx={range_slider}
                                                valueLabelFormat={(value) => viewGradeName(value)}
                                            />
                                        </div>
                                    </div> : ""}

                                {initFilter.hasOwnProperty('age') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("Age")}</div>
                                        <div className="price_range">
                                            <span style={{ color: color }}>{items.age[0]}</span> - <span style={{ color: color }}>{items.age[1]}</span>
                                        </div>
                                        <div className="bobules__wapper" >
                                            <Slider
                                                value={items.age}
                                                onChange={(event, newValue) => handleRangeValue("age", newValue)}
                                                valueLabelDisplay="auto"
                                                min={FilterData.age?.min}
                                                max={FilterData.age?.max}
                                                step={1}
                                                sx={range_slider}

                                            />
                                        </div>
                                    </div> : ""}

                                {initFilter.hasOwnProperty('available') ?
                                    <div className="contant__wapper  mb-4">
                                        <div className="title_text">{t("Availability")}</div>
                                        <div className="bobules__wapper">
                                            {FilterData.available?.map((item: any) => {
                                                return (
                                                    <>
                                                        <div className="contant_text" style={items?.available?.includes(item) ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("available", item)}>{item}</div>
                                                    </>
                                                )
                                            })}
                                            {/* <div className="contant_text" style={items?.available?.length === 0 ? active_contant_text : {}} onClick={() => handleSingleBubbleSelect("available", "all")}> All </div> */}

                                        </div>
                                        <div className="price_range">
                                            {t("Time:")} <span style={{ color: color }}>{timeFormat(items.time[0])}</span> - <span style={{ color: color }}>{timeFormat(items.time[1])}</span>
                                        </div>
                                        <div className="bobules__wapper" >
                                            <Slider
                                                value={items.time}
                                                onChange={(event, newValue) => handleRangeValue("time", newValue)}
                                                valueLabelDisplay="auto"
                                                valueLabelFormat={(value) => timeFormat(value)}
                                                min={0}
                                                max={24}
                                                step={1}
                                                sx={range_slider}

                                            />
                                        </div>
                                    </div>
                                    : ""}




                                {initFilter.hasOwnProperty('price') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("Avg Fees")}</div>
                                        <div className="price_range">
                                            {t("AED")}.<span style={{ color: color }}>{items?.price[0]}</span>{t("- AED")}.<span style={{ color: color }}>{items?.price[1]}</span>
                                        </div>
                                        <div className="bobules__wapper">
                                            {rangeSets?.price?.map((item: any) => {
                                                return (
                                                    <>
                                                        <div className="contant_text" style={checkArrayEqual(item?.value, items?.price) ? active_contant_text : {}} onClick={() => handleRangeValue("price", item.value)}>{item.name}</div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                        <div className="bobules__wapper" style={{ marginBottom: "35px" }}>
                                            <Slider
                                                value={items?.price}
                                                onChange={(event, newValue) => handleRangeValue("price", newValue)}
                                                valueLabelDisplay="auto"
                                                min={0}
                                                max={FilterData?.price?.max}
                                                step={100}
                                                marks={marks?.price}
                                                sx={range_slider}
                                            />
                                        </div>
                                    </div> : ""}



                                {initFilter.hasOwnProperty('radius') ?

                                    <div className="contant__wapper mb-4">
                                        <div className="title_text">{t("Map Location Radius")}</div>
                                        <div className="price_range">
                                            <span style={{ color: color }}>{items?.radius}</span>{t("KM")}
                                        </div>
                                        <div className="bobules__wapper">
                                            <Slider
                                                value={items?.radius} // Set the value prop to the value state
                                                onChange={(event, newValue) => handleSingleSliderValue("radius", newValue)}
                                                valueLabelDisplay="auto"
                                                min={0}
                                                max={1000}
                                                step={1}
                                                sx={{
                                                    color: { color },
                                                    '& .MuiSlider-thumb': {
                                                        backgroundColor: { color },
                                                    },
                                                    '& .MuiSlider-track': {
                                                        backgroundColor: { color },
                                                    },
                                                    width: '80%',
                                                    margin: "0px 10px"
                                                }}
                                            />
                                        </div>
                                    </div> : ""}
                            </div>
                        ) : (
                            <div className="Filter__body__contant">
                                <div className="contant__wapper  mb-4">
                                    <div className="title_text"><b>{t("Price")}</b></div>
                                    <div className="contant__wapper">
                                        <div className="sorting_wapper">
                                            <div className="sorting_text_">{t("High price to Low price")}</div>
                                            <Radio
                                                onChange={() => {
                                                    setCheckedPrice('high')
                                                    setCheckedRating('');
                                                }}
                                                checked={CheckedPrice == 'high' ? true : false}
                                                name={CheckedPrice == 'high' ? 'radio-button-on' : 'radio-button-off'}
                                                sx={{
                                                    color: color,
                                                    '&.Mui-checked': {
                                                        color: color,
                                                    },
                                                }} />
                                        </div>
                                    </div>
                                    <div className="contant__wapper">
                                        <div className="sorting_wapper">
                                            <div className="sorting_text_">{t("Low price to High price")}</div>
                                            <Radio
                                                onChange={() => {
                                                    setCheckedPrice('low');
                                                    setCheckedRating('');
                                                }}
                                                checked={CheckedPrice == 'low' ? true : false}
                                                name={CheckedPrice == 'low' ? 'radio-button-on' : 'radio-button-off'}
                                                sx={{
                                                    color: color,
                                                    '&.Mui-checked': {
                                                        color: color,
                                                    },
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="contant__wapper  mb-4">
                                    <div className="title_text"><b>{t("Rating")}</b></div>
                                    <div className="contant__wapper">
                                        <div className="sorting_wapper">
                                            <div className="sorting_text_">{t("Highest to lowest")}</div>
                                            <Radio
                                                onChange={() => {
                                                    setCheckedRating('high');
                                                    setCheckedPrice("");
                                                }}
                                                checked={CheckedRating == 'high' ? true : false}
                                                name={CheckedRating == 'high' ? 'radio-button-on' : 'radio-button-off'}
                                                sx={{
                                                    color: color,
                                                    '&.Mui-checked': {
                                                        color: color,
                                                    },
                                                }} />
                                        </div>
                                    </div>
                                    <div className="contant__wapper">
                                        <div className="sorting_wapper">
                                            <div className="sorting_text_">{t("Lowest to highest")}</div>
                                            <Radio
                                                onChange={() => {
                                                    setCheckedRating('low');
                                                    setCheckedPrice("");
                                                }}
                                                checked={CheckedRating == 'low' ? true : false}
                                                name={CheckedRating == 'low' ? 'radio-button-on' : 'radio-button-off'}
                                                sx={{
                                                    color: color,
                                                    '&.Mui-checked': {
                                                        color: color,
                                                    },
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        <div className="Filter__footer__wapper">
                            {ActiveState == 1 ? (
                                <button className="applyBtn" style={{ backgroundColor: color }} onClick={ApplyBtn}>{t("Apply")}</button>
                            ) : (
                                <button className="applyBtn" style={{ backgroundColor: color }} onClick={() => handleSortValue(CheckedPrice, CheckedRating)}>{t("Apply")}</button>
                            )}
                            <button className="resetBtn" onClick={() => FilterReset('all')}>{t("Reset")}</button>
                        </div>

                    </div>

                </DialogContent>

            </Dialog>
        </>
    )
}

export default FilterPopup;