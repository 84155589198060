import { Dialog, DialogContent } from "@mui/material";
import "./AvatarSelectionDialog.scss";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  S3DataGetApi,
} from "../../Constants/Constants";

/**Assets */
import defaultProfile from "../../Assets/Authentication/defaultProfile.svg";
import uploadArrow from "../../Assets/Authentication/uploadArrow.svg";
import {
  SetSessionStorageData,
  nullUndefinedEmptyCheck,
  uploadFileToS3,
} from "../../Utilities/Helpers/Helper";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AvatarSelectionDialog = (avatarSelectionDialogProps: any) => {
  const { t } = useTranslation();
  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const DataKeys: any = useSelector((state: any) => state.s3DataKeyReducer.datakey);
  /**Initial states */
  const [profilePicture, setProfilePicture] = useState("");
  const profileInput = useRef<HTMLInputElement>(null);
  const avatarCarouselRef = useRef<any>(null);
  const [thumbIndex, setThumbIndex] = useState(-1);
  const [avatarImages, setAvatarImages] = useState([]);
  const [avatarItems, setAvaterItems] = useState<any>([]);
  const responsive = {
    0: {
      items: 1,
    },
    350: {
      items: 2,
    },
    500: {
      items: 3,
    },
    600: {
      items: 4,
    },
    900: {
      items: 6,
    },
  };

  useEffect(() => {
    getAvatarImages();
    setThumbIndex(-1)
  }, [avatarSelectionDialogProps.openAvatarPickerDialog]);

  useEffect(() => {
    avatarsLayout();
  }, [avatarImages]);

  /**Get avatar images */
  const getAvatarImages = () => {
    axiosInstance
      .get(`${API_END_POINTS.AVATAR_IMAGES}`)
      .then((avatarImageResponse: any) => {
        if (
          avatarImageResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          setAvatarImages(avatarImageResponse.data.result.data[0].avatarImages);
        }
      })
      .catch(() => { });
  };

  /**Handle upload profile picture on clicking other buttons */
  const handleUploadProfilePicture = () => {
    if (profileInput.current) {
      profileInput.current.value = "";
      profileInput.current?.click();
    }
  };

  /**Handle profile change */
  const handleChange = async (event: any) => {
    if (event.target.files[0].type.split("/")[0] === "image") {
      try {
        const uploadedImage: any = await S3DataGetApi(event);
        setProfilePicture(uploadedImage);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  /** Update profile picture*/
  const updateProfilePicture = () => {
    const payload = {
      profile_image:
        profilePicture === "" ? avatarImages[thumbIndex] : profilePicture,
    };
    if (nullUndefinedEmptyCheck(payload.profile_image)) {
      SetSessionStorageData(
        AUTHENTICATION.SHARED.PROFILE_PICTURE,
        payload.profile_image
      );
    }
    axiosInstance
      .put(`${API_END_POINTS.PROFILE_PICTURE_UPDATE}`, payload)
      .then((phoneSignUpResponse: any) => {
        if (
          phoneSignUpResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          toast.success(phoneSignUpResponse.data.message);
          avatarSelectionDialogProps.handleCloseAvatarPickerDialg();
          avatarSelectionDialogProps.setOpenPreferenceDialog(
            GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
          );
        }
      })
      .catch(() => { });
  };

  const syncThumbs = (e: any) => {
    setThumbIndex(e.item);
  };

  const avatarsLayout = () => {
    setAvaterItems(
      avatarImages?.map((avatarElement: any) => {
        return (
          <>
            {nullUndefinedEmptyCheck(avatarElement) ? (
              <img
                className="avatar_image"
                src={avatarElement}
                alt="Learn and Play"
              />
            ) : ('')}
          </>
        );
      })
    );
  };
  return (
    <>
      <Dialog
        dir={
          language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH
            ? GLOBAL_CONSTANTS.LAYOUT_DIRECTION.LTR
            : GLOBAL_CONSTANTS.LAYOUT_DIRECTION.RTL
        }
        className="avatar_selection_dialog_wrapper"
        open={avatarSelectionDialogProps.openAvatarPickerDialog}
      >
        <DialogContent>
          <div className="background_color_wrapper_left"></div>
          <div className="background_color_wrapper_right"></div>
          <div className="profile_avatar_wrapper">
            <div onClick={() => { avatarSelectionDialogProps.handleCloseAvatarPickerDialg() }} className={language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "skip_button" : "skip_button_ar"}>{t("Skip")}</div>
            <div className="profile_title_description_container">
              <div className="profile_title">{t("Say Cheeeeeese!")}</div>
              <div className="profile_description">
                {t("add a profile picture or select an avatar you like")}
              </div>
            </div>
            <div className="profile_upload_avatar_carousel">
              <div className="profile_upload_wrapper">
                <div className="profile_upload_seperator_container">
                  <input
                    ref={profileInput}
                    className="file_input"
                    type="file"
                    accept="image/png,image/jpg,image/jpeg,image/svg"
                    onChange={handleChange}
                  />
                  <div className="profile_upload_container">

                    <img
                      className="profile_picture"
                      src={
                        profilePicture === ""
                          ? (nullUndefinedEmptyCheck(avatarImages[thumbIndex]) ? avatarImages[thumbIndex] : defaultProfile)
                          : profilePicture
                      }
                      alt="Learn and Play"
                    />
                    {profilePicture === "" && (
                      <div className="upload_arrow_wrapper" onClick={() => {
                        handleUploadProfilePicture()
                      }}>
                        {/* <div className="upload_arrow_container"> */}
                        <img
                          className="upload_arrow"
                          src={uploadArrow}
                          alt="Learn and Play"
                        />
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                  <div className="or_seperator">{t("OR")}</div>
                </div>
                <div className="d-flex">
                  <button
                    className="upload_button"
                    onClick={() => {
                      handleUploadProfilePicture()
                    }}
                  >
                    {t("Upload")}
                  </button>
                  <button
                    className="upload_button"
                    onClick={() => {
                      setProfilePicture("");
                      setThumbIndex(-1)
                    }}
                  >
                    {t("Remove")}
                  </button>
                </div>
                <div className="profile_picture_label">{t("Profile picture")}</div>
              </div>
              <div className="profile_avatars_carousel_wrapper">
                <AliceCarousel
                  infinite
                  activeIndex={thumbIndex}
                  mouseTracking
                  responsive={responsive}
                  items={avatarItems}
                  disableDotsControls
                  paddingLeft={40}
                  onSlideChanged={syncThumbs}
                  ref={avatarCarouselRef}
                />
                <div className="profile_picture_label ms-5">{t("Select avatar")}</div>
              </div>
            </div>
            <button onClick={updateProfilePicture} className="next_button">
              {t("Next")}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AvatarSelectionDialog;
