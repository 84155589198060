import { Drawer } from "@mui/material";
import "./ProfileLayout.scss";
import { useEffect, useState } from "react";
import {
  GetSessionStorageData,
  SetSessionStorageData,
  clearSessionStorage,
  nullUndefinedEmptyCheck,
} from "../../Utilities/Helpers/Helper";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS, ProfileSiderBarArray, ROUTES } from "../../Constants/Constants";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem, Sidebar, SubMenu, useProSidebar, } from "react-pro-sidebar";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import defaultProfile from "../../Assets/Authentication/defaultProfile.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
// import required modules
import { Autoplay, Pagination, Mousewheel, Navigation, Keyboard, Grid } from 'swiper/modules';
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ProfileLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  /**Initial states */
  const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [profilePicture, setProfilePicture] = useState<any>("");
  const [breadCampData, setBreadCampData] = useState<any>("");
  const [parentProfileData, setParentProfileData] = useState<any>([]);
  const [childProfileData, setChildProfileData] = useState<any>([]);
  const [switchProfileAction, setSwitchProfileAction] = useState<any>(false);
  /**Handle side navigations */
  const handleSideNav = (route: any) => {
    navigate(route);
    SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
  };
  const location = useLocation();

  const isPathActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    if (
      nullUndefinedEmptyCheck(
        GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE)
      )
    ) {
      setProfilePicture(
        GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE)
      );
    }
    if (
      nullUndefinedEmptyCheck(GetSessionStorageData(AUTHENTICATION.SHARED.NAME))
    ) {
      setName(GetSessionStorageData(AUTHENTICATION.SHARED.NAME));
    }
    if (nullUndefinedEmptyCheck(GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER))) {
      setBreadCampData(GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER))
    }
    if (
      nullUndefinedEmptyCheck(GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL))
    ) {
      setEmail(GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL));
    }
    if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
      getProfileList();
    } else {
      setParentProfileData([]);
      setChildProfileData([]);
    }
  }, [switchProfileAction]);

  useEffect(() => {
    if (nullUndefinedEmptyCheck(GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER))) {
      setBreadCampData(GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER))
    }
  }, [location.pathname])

  const handleHeader = (value: any) => {
    SetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_HEADER, value)
    setBreadCampData(value);
  }

  /**Get profile data */
  const getProfileList = () => {
    axiosInstance
      .get(`${API_END_POINTS.PROFILE_LIST}`)
      .then((profileListResponse: any) => {
        if (
          profileListResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          setParentProfileData(profileListResponse.data.result.data.parent);
          setChildProfileData(profileListResponse.data.result.data.child);
        }
      })
      .catch(() => { });
  };

  /**Handle switch profile */
  const switchProfile = (
    accountType: any,
    childId: any,
    selectedEmail: any
  ) => {
    if (email === selectedEmail) {
      return;
    }
    let childPayload: any = {
      account_switch_type: accountType,
      child_id: childId,
    };
    let parentPayload: any = {
      account_switch_type: accountType,
      parent_id: childId,
    };
    axiosInstance
      .post(
        `${API_END_POINTS.PROFILE_SWITCH}`,
        accountType === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT
          ? parentPayload
          : childPayload
      )
      .then((profileSwitchResponse: any) => {
        setSwitchProfileAction(!switchProfileAction)
        if (
          profileSwitchResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          clearSessionStorage();
          toast.success(profileSwitchResponse.data.message);
          SetSessionStorageData(
            AUTHENTICATION.SHARED.ACCOUNT_TYPE,
            accountType
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.ACCESS_TOKEN,
            profileSwitchResponse.data.result.data.access_token
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.REFRESH_TOKEN,
            profileSwitchResponse.data.result.data.refresh_token
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.NAME,
            `${profileSwitchResponse.data.result.data.user_info.name}`
          );
          SetSessionStorageData(
            AUTHENTICATION.SHARED.EMAIL,
            profileSwitchResponse.data.result.data.user_info.email
          );
          if (accountType === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
            SetSessionStorageData(
              AUTHENTICATION.SHARED.USER_ID,
              profileSwitchResponse.data.result.data.user_info?.id
            );
          } else {
            SetSessionStorageData(
              AUTHENTICATION.SHARED.USER_ID,
              profileSwitchResponse.data.result.data.user_info?.child
            );
          }
          if (
            nullUndefinedEmptyCheck(
              profileSwitchResponse.data.result.data.user_info.image
            )
          ) {
            SetSessionStorageData(
              AUTHENTICATION.SHARED.PROFILE_PICTURE,
              profileSwitchResponse.data.result.data.user_info.image
            );
          }
          updateUserDetails();
          navigate(ROUTES.HOMEPAGE);
        }
      })
      .catch(() => { });
  };

  /**Update user details from session storage */
  const updateUserDetails = () => {
    if (
      nullUndefinedEmptyCheck(
        GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE)
      )
    ) {
      setProfilePicture(
        GetSessionStorageData(AUTHENTICATION.SHARED.PROFILE_PICTURE)
      );
    }
    if (
      nullUndefinedEmptyCheck(GetSessionStorageData(AUTHENTICATION.SHARED.NAME))
    ) {
      setName(GetSessionStorageData(AUTHENTICATION.SHARED.NAME));
    }
    if (
      nullUndefinedEmptyCheck(
        GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL)
      )
    ) {
      setEmail(GetSessionStorageData(AUTHENTICATION.SHARED.EMAIL));
    }
  };

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const MySwiperLanguage = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "my-swiper-eng" : "my-swiper-arab";
  const RightIconLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "rightIcon-eng" : "rightIcon-arab";
  const BreadCampRightIcon = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "BreadCampRightIcon-eng" : "BreadCampRightIcon-arab";
  const MenuActiveLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "MenuActiveLang_Eng" : "MenuActiveLang_Arab";
  const MenuItemListsLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "menu_item_lists" : "menu_item_lists_arab";
  const DraweOutletContainerLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "DraweOutletContainerLang_Eng" : "DraweOutletContainerLang_Arab";


  return (
    <>
      <div className="drawe_outlet_wrapper">

        <div className={`${DraweOutletContainerLang} drawe_outlet_container`}>

          <div className="drawer_wrapper">

            <div className="profile_contents_pad">
              <div className="profile_details_wrapper">
                <img
                  className="accounts_profile_picture"
                  src={profilePicture ? profilePicture : defaultProfile}
                  alt="Learn and Play" onError={(e: any) => { e.target.src = defaultProfile; }}
                />
                <div className="name_actions_container">
                  <div className="name">{name}</div>
                  <div className="actions_wrap">
                    <div className="edit_profile" onClick={() => {
                      handleSideNav(ROUTES.EDIT_PROFILE);
                      handleHeader("Edit Profile");
                    }} >{t("Edit Profile")}</div>

                    {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT &&
                      parentProfileData?.length + childProfileData?.length < 5 ?
                      (
                        <div
                          className="add_profile"
                          onClick={() => {
                            handleSideNav(ROUTES.ADD_PROFILE);
                            handleHeader("Add Profile");
                          }}>
                          {t("Add Profile")}
                        </div>
                      ) : ("")}
                  </div>
                </div>
              </div>
            </div>

            <div className="profile-layout-swiper">
              <Swiper
                slidesPerView={1}
                breakpoints={{
                  1080: {
                    slidesPerView: 2,
                  },
                }}
                spaceBetween={17}
                navigation={true}
                grid={{
                  rows: 1,
                }}
                keyboard={true}
                // pagination={{
                //   clickable: true,
                // }}
                modules={[Pagination, Keyboard, Grid, Navigation]}
                className={`${MySwiperLanguage} mySwiper`}
              >
                {parentProfileData.map((parentElement: any, i: any) => {
                  return (
                    <>
                      <SwiperSlide key={i}>
                        <div className="profile__card__conatiner">
                          <div className="contant__wapper">
                            <img src={
                              nullUndefinedEmptyCheck(
                                parentElement.profile_image
                              )
                                ? parentElement.profile_image
                                : defaultProfile
                            } alt="" className="profile_Img" onError={(e: any) => { e.target.src = defaultProfile; }} />
                            <div className="profile_Name">{parentElement.name}</div>
                            <div className="role">{t("Parent")}</div>
                            {email === parentElement.email ? (
                              <div className="primary_badge">{t("primary")}</div>
                            ) : (
                              <div className="select_badge"
                                onClick={() => {
                                  switchProfile(
                                    AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT,
                                    parentElement.id,
                                    parentElement.email
                                  );
                                }}>{t("Select")}</div>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  )
                })}
                {childProfileData.map((childElement: any, i: any) => {
                  return (
                    <>
                      <SwiperSlide key={i}>
                        <div className="profile__card__conatiner">
                          <div className="contant__wapper">
                            <img src={
                              nullUndefinedEmptyCheck(
                                childElement.profile_image
                              )
                                ? childElement.profile_image
                                : defaultProfile
                            } alt="" className="profile_Img" onError={(e: any) => { e.target.src = defaultProfile; }} />
                            <div className="profile_Name">{childElement.name}</div>
                            <div className="role">{t("Child")}{i + 1}</div>
                            {email === childElement.email ? (
                              <div className="primary_badge">{t("primary")}</div>
                            ) : (
                              <div className="select_badge"
                                onClick={() => {
                                  switchProfile(
                                    AUTHENTICATION.PROFILE_ACCOUNT_TYPE.CHILD,
                                    childElement.id,
                                    childElement.email
                                  );
                                }}  >{t("Select")}</div>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  )
                })}
              </Swiper>
            </div>

            <div className="accounts_divider"></div>

            <div className="Profile__sideBar__container">
              <Sidebar>
                <Menu>
                  {ProfileSiderBarArray?.map((data: any, i: any) => {
                    return (
                      <>
                        {GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT ? (
                          <MenuItem className={`${location.pathname.includes(data.path) ? `${MenuActiveLang} menu__active` : ""} ${MenuItemListsLang}`} key={i}
                            active={isPathActive(data.path)}
                            onClick={() => {
                              handleSideNav(data.path);
                              handleHeader(data?.title)
                            }} >
                            <div className={location.pathname.includes(data.path) ? "menu__data_wapper__active" : "menu__data_wapper"}>
                              <img src={location.pathname.includes(data.path) ? data.activeImg : data.InactiveImg} className="menu__icon" alt="Menu Icon" />
                              <span title={t(data?.title)}>{t(data?.title)}</span>
                              <ArrowForwardIosOutlinedIcon className={` ${RightIconLang} rightIcon`} />
                            </div>
                          </MenuItem>
                        ) : (
                          <>
                            {data.accountType !== 2 && (
                              <MenuItem className={`${location.pathname.includes(data.path) ? `${MenuActiveLang} menu__active` : ""} ${MenuItemListsLang}`} key={i}
                                active={isPathActive(data.path)}
                                onClick={() => {
                                  handleSideNav(data.path);
                                  handleHeader(data?.title)
                                }} >
                                <div className={location.pathname.includes(data.path) ? "menu__data_wapper__active" : "menu__data_wapper"}>
                                  <img src={location.pathname.includes(data.path) ? data.activeImg : data.InactiveImg} className="menu__icon" alt="Menu Icon" />
                                  <span title={t(data?.title)}>{t(data?.title)}</span>
                                  <ArrowForwardIosOutlinedIcon className={` ${RightIconLang} rightIcon`} />
                                </div>
                              </MenuItem>
                            )}
                          </>
                        )}
                      </>
                    )
                  })}
                </Menu>
              </Sidebar>
            </div>
          </div>

          <div className="outlet_container">
            <div className="breadCamp__container">
              <div className="home" onClick={() => handleSideNav(ROUTES.HOMEPAGE)}>{t("Home")}</div>
              <ArrowForwardIosOutlinedIcon className={`${BreadCampRightIcon} breadCamp__rightIcon`} />
              {location.pathname.includes(ROUTES.TUTOR_HUB_DETAILES) ?
                (<div className="title cursor-pointer" onClick={() => handleSideNav(ROUTES.TUTOR_HUB)}>{t(breadCampData)}</div>) :
                (<div className="title">{t(breadCampData)}</div>)}
              {nullUndefinedEmptyCheck(GetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME)) && (
                <>
                  <ArrowForwardIosOutlinedIcon className={`${BreadCampRightIcon} breadCamp__rightIcon`} />
                  <div className="title">{GetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME)}</div>
                </>
              )}
            </div>
            <div className="outlet_container__scroll">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileLayout;
