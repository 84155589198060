import "./PreferenceDialog.scss";
import {
  ClickAwayListener,
  Dialog,
  DialogContent,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useState, useEffect } from "react";

/**Assets*/
import info from "../../Assets/Authentication/info.svg";
import close from "../../Assets/Authentication/close.svg";
import preferenceBgCurve from "../../Assets/Authentication/preferenceBgCurve.svg";
import { useNavigate } from "react-router-dom";
import {
  API_END_POINTS,
  PreferenceDilogArray,
  ROUTES,
} from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";

const PreferenceDialog = (preferenceDialogProps: any) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedLabels, setSelectedLabels] = useState<any>([]);
  const [TutorselectedLabels, setTutorSelectedLabels] = useState<any>([]);
  const [CampselectedLabels, setCampSelectedLabels] = useState<any>([]);
  const [PersonalselectedLabels, setPersonalSelectedLabels] = useState<any>([]);
  const [SchoolselectedLabels, setSchoolSelectedLabels] = useState<any>([]);
  const [UniversityselectedLabels, setUniversitySelectedLabels] = useState<any>(
    []
  );
  const [PreferenceArray, setPreferenceArray] = useState<any>([]);

  const toggleLabel = (id: any) => {
    // Check if the label is already selected
    if (selectedLabels.includes(id)) {
      // If selected, remove it
      setSelectedLabels(
        selectedLabels?.filter((labelId: any) => labelId !== id)
      );
    } else {
      // If not selected, add it
      setSelectedLabels([...selectedLabels, id]);
    }
  };

  const toggleTutor = (id: any) => {
    if (TutorselectedLabels.includes(id)) {
      setTutorSelectedLabels(
        TutorselectedLabels?.filter((labelId: any) => labelId !== id)
      );
    } else {
      setTutorSelectedLabels([...TutorselectedLabels, id]);
    }
  };

  const toggleCamp = (id: any) => {
    if (CampselectedLabels.includes(id)) {
      setCampSelectedLabels(
        CampselectedLabels?.filter((labelId: any) => labelId !== id)
      );
    } else {
      setCampSelectedLabels([...CampselectedLabels, id]);
    }
  };

  const togglePersonal = (id: any) => {
    if (PersonalselectedLabels.includes(id)) {
      setPersonalSelectedLabels(
        PersonalselectedLabels?.filter((labelId: any) => labelId !== id)
      );
    } else {
      setPersonalSelectedLabels([...PersonalselectedLabels, id]);
    }
  };

  const toggleSchool = (id: any) => {
    if (SchoolselectedLabels.includes(id)) {
      setSchoolSelectedLabels(
        SchoolselectedLabels?.filter((labelId: any) => labelId !== id)
      );
    } else {
      setSchoolSelectedLabels([...SchoolselectedLabels, id]);
    }
  };

  const toggleUniversity = (id: any) => {
    if (UniversityselectedLabels.includes(id)) {
      setUniversitySelectedLabels(
        UniversityselectedLabels?.filter((labelId: any) => labelId !== id)
      );
    } else {
      setUniversitySelectedLabels([...UniversityselectedLabels, id]);
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const homePage = () => {
    var Preference = {
      tutor: TutorselectedLabels,
      recreationalcamp: CampselectedLabels,
      recreationalpersonal: PersonalselectedLabels,
      school: SchoolselectedLabels,
      university: UniversityselectedLabels,
    };
    preferenceDialogProps.handleClosePreferenceDialog();
    navigate(ROUTES.HOMEPAGE, { state: { Preference: Preference } });
    setSelectedLabels([]);
    setTutorSelectedLabels([]);
    setCampSelectedLabels([]);
    setPersonalSelectedLabels([]);
    setSchoolSelectedLabels([]);
    setUniversitySelectedLabels([]);
  };

  useEffect(() => {
    if (preferenceDialogProps.openPreferenceDialog) {
      setTutorSelectedLabels([]);
      setCampSelectedLabels([]);
      setPersonalSelectedLabels([]);
      setSchoolSelectedLabels([]);
      setUniversitySelectedLabels([]);
      getPreferenceApiCall();
    }
  }, [preferenceDialogProps.openPreferenceDialog]);

  const getPreferenceApiCall = () => {
    axiosInstance
      .get(`${API_END_POINTS.PREFERENCE_LIST}`)
      .then((Response: any) => {
        setPreferenceArray(Response?.data?.result);
      })
      .catch(() => { });
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }: any) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: theme.typography.pxToRem(12),
      backgroundColor: "#F8F7FF",
      maxWidth: 220,
      padding: 20,
      filter: "drop-shadow(0px 4px 11px rgba(131, 131, 131, 0.25))",
    },
  }));
  return (
    <>
      <Dialog
        className="preference_dialog_wrapper"
        onClose={() => {
          preferenceDialogProps.handleClosePreferenceDialog();
          setSelectedLabels([]);
        }}
        open={preferenceDialogProps.openPreferenceDialog}
      >
        <DialogContent>
          <div
            onClick={() => {
              preferenceDialogProps.handleClosePreferenceDialog();
              setSelectedLabels([]);
            }}
            className="skip_button_preference"
          >
            Skip
          </div>
          <div className="background_color_wrapper_left"></div>
          <div className="background_color_wrapper_right"></div>
          <div className="preference_dialog_contents_container">
            <div className="preference_title_container">
              <img
                src={preferenceBgCurve}
                alt="Learn and Play"
                className="preference_bg_curve"
              />
              <div className="bg_circle_small"></div>
              <div className="bg_circle_large"></div>
              <div className="preference_title">Select your preferences</div>
              <div className="preference_description_info">
                <div className="preference_description">
                  Know why we ask this
                </div>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <HtmlTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    // followCursor
                    title={
                      <>
                        <div className="tooltip_contents_wrapper">
                          <div className="tooltip_title_close_container">
                            <div className="tooltip_title">
                              Know why we ask this{" "}
                            </div>
                            <img
                              onClick={handleTooltipClose}
                              src={close}
                              alt=""
                            />
                          </div>
                          <div className="tooltip_description">
                            We only ask this to provide you with the most
                            personalized experience. You can change these
                            preferences anytime you want in your profile
                            section.
                          </div>
                        </div>
                      </>
                    }
                  >
                    <img
                      className="info_icon"
                      onClick={handleTooltipOpen}
                      src={info}
                      alt="Learn and Play"
                    />
                  </HtmlTooltip>
                </ClickAwayListener>
              </div>
            </div>
            <div className="preference_chips_wrapper">
              <div className="pref-body-wrap">
                <div className="pref_title_text">Schools</div>
                <div className="pref-item-list-wrap">
                  {PreferenceArray?.school?.map((item: any) => {
                    const isSelected = SchoolselectedLabels?.includes(item);
                    return (
                      <>
                        <div
                          key={item}
                          className={
                            isSelected
                              ? "preference_chips__active"
                              : "preference_chips"
                          }
                          onClick={() => toggleSchool(item)}
                        >
                          {item}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="pref-body-wrap">
                <div className="pref_title_text">Tutors</div>
                <div className="pref-item-list-wrap">
                  {PreferenceArray?.tutor?.map((item: any) => {
                    const isSelected = TutorselectedLabels?.includes(item);
                    return (
                      <>
                        <div
                          key={item}
                          className={
                            isSelected
                              ? "preference_chips__active"
                              : "preference_chips"
                          }
                          onClick={() => toggleTutor(item)}
                        >
                          {item}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="pref-body-wrap">
                <div className="pref_title_text">Activities</div>
                <div className="pref-item-list-wrap">
                  {PreferenceArray?.recreationalpersonal?.map((item: any) => {
                    const isSelected = PersonalselectedLabels?.includes(item);
                    return (
                      <>
                        <div
                          key={item}
                          className={
                            isSelected
                              ? "preference_chips__active"
                              : "preference_chips"
                          }
                          onClick={() => togglePersonal(item)}
                        >
                          {item}
                        </div>
                      </>
                    );
                  })}
                  {PreferenceArray?.recreationalcamp?.map((item: any) => {
                    const isSelected = CampselectedLabels?.includes(item);
                    return (
                      <>
                        <div
                          key={item}
                          className={
                            isSelected
                              ? "preference_chips__active"
                              : "preference_chips"
                          }
                          onClick={() => toggleCamp(item)}
                        >
                          {item}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              <div className="pref-body-wrap">
                <div className="pref_title_text">Universities</div>
                <div className="pref-item-list-wrap">
                  {PreferenceArray?.university?.map((item: any) => {
                    const isSelected = UniversityselectedLabels?.includes(item);
                    return (
                      <>
                        <div
                          key={item}
                          className={
                            isSelected
                              ? "preference_chips__active"
                              : "preference_chips"
                          }
                          onClick={() => toggleUniversity(item)}
                        >
                          {item}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {TutorselectedLabels?.length > 0 ||
              CampselectedLabels?.length > 0 ||
              PersonalselectedLabels?.length > 0 ||
              SchoolselectedLabels?.length > 0 ||
              UniversityselectedLabels?.length > 0 ? (
              <button
                type="button"
                className="home_nav"
                onClick={() => homePage()}
              >
                Go to homepage
              </button>
            ) : (
              <button type="button" className="home_nav__disable">
                Go to homepage
              </button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PreferenceDialog;
