import "./MyWallet.scss";
import walletCard from "../../Assets/ProfileSettings/walletCard.svg"
import { useEffect, useState } from "react";
import { API_END_POINTS, AUTHENTICATION, GLOBAL_CONSTANTS } from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import moment from 'moment';
import { GetSessionStorageData } from "../../Utilities/Helpers/Helper";
import Pagination from '@mui/material/Pagination';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const MyWallet = () => {
    const { t } = useTranslation();
    const [WalletList, setWalletList] = useState<any>([]);
    const [totalCount, setTotalCount] = useState<any>('');
    const [page, setPage] = useState<any>(1);
    const [pageCount, setPageCount] = useState<any>(1);

    useEffect(() => {
        getMyWallet();
    }, [page])

    /**Get My Wallet List*/
    const getMyWallet = () => {
        axiosInstance
            // .get(`${API_END_POINTS.USER_WALLET + "?page=" + page + "&page_per_limit=5"}`)
            .get(`${API_END_POINTS.USER_WALLET + "?page=" + page + "&limit=10"}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    setWalletList(Response?.data?.result?.data);
                    setTotalCount(Response?.data?.result?.total_wallet_amount)
                    setPageCount(Response?.data?.result?.total_count)
                }
            })
            .catch(() => { });
    };

    const handleChangePage = (event: any, value: number) => {
        setPage(value);
    }

    /**get selected or current language from redux */
    const language: any = useSelector((state: any) => {
        return state.languageReducer.language;
    });
    /*Below class name based on English Arabic*/
    const CustomPagination = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "CustomPagination-Eng" : "CustomPagination-Arab";


    return (
        <>
            <div className="MyWallet__container">
                <div className="container__wapper">
                    <div className="title">{t("My Wallet")}</div>
                    <div className="walletCard__wapper">
                        <div className="walletCard__Name">{GetSessionStorageData(AUTHENTICATION.SHARED.NAME)}</div>
                        <img src={walletCard} className="walletCard_img" alt="" />
                        <div className="walletCard__total_count">{totalCount} {t("AED")}</div>
                        <div className="walletCard__total_text">{t("Total Expenses")}</div>
                    </div>

                    {WalletList?.map((data: any, i: any) => {
                        return (
                            <>
                                <div className="detailed__card__wapper mt-2" key={data?.id || i}>
                                    <div className="d-flex flex-column">
                                        <div className="name__text">{data?.title}</div>
                                        <div className="date__text">{moment(new Date(data?.start_date)).format("DD/MM/YYYY")}</div>
                                    </div>
                                    <div className="amount">{data?.time_slot_price} {t("AED")}</div>
                                </div>
                            </>
                        )
                    })}

                    {WalletList?.length > 0 && (
                        <div className="pagination__container">
                            <Pagination
                                // count={(pageCount <= 5) ? 1 : (pageCount % 5) + 1}
                                count={Math.ceil(pageCount / 10)}
                                shape="rounded"
                                page={page}
                                onChange={handleChangePage}
                                className={`${CustomPagination} custom_pagination`}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )

}

export default MyWallet;