import { useState, useEffect } from "react";
import card_bg from "../../../Assets/Tutor/card_bg.png";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import shareIcon from "../../../Assets/University/universityShareIcon.svg";
import LikeIcon from "../../../Assets/University/UniversityLikeIcon.svg";
import comments from "../../../Assets/University/universityCommentIcon.svg";
import universityIcon from "../../../Assets/University/universityIcon.svg";
import navigation_arrows from "../../../Assets/home/nav_arrow.svg";
import back_img from "../../../Assets/University/universityBackImg.svg";
import tutor_icon from "../../../Assets/Tutor/TutorImg.svg";
import StarIcon from "@mui/icons-material/Star";
import AliceCarousel from "react-alice-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import "./UniversityDetaileView.scss";
import { Container } from "@mui/material";
import React from "react";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
  ROUTES,
  getGoogleMapLink,
} from "../../../Constants/Constants";
import CalenderIcon from "../../../Assets/University/universityCalenderIcon.svg";
import viewOnMap from "../../../Assets/University/universityViewMapIcon.svg";
import globalRank from "../../../Assets/University/universityGlobalRanking.svg";
import shangaiRank from "../../../Assets/University/universityShangai.svg";
import courseIocn from "../../../Assets/University/universityCourse.svg";
import noOfStudent from "../../../Assets/University/universityNoStud.svg";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import UniversityFill from "../../../Assets/HeartFill/UniversityFill.svg";
import toast from "react-hot-toast";
import {
  GetSessionStorageData,
  decrypPassData,
  encryptPassData,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import { setLoginSignUpOpen, setChildLoginRestriction } from "../../../Redux/Reducer/Reducer";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import emailIcon from "../../../Assets/School/emailIcon.svg";
import websiteIcon from "../../../Assets/School/websiteIcon.svg";
import callIcon from "../../../Assets/School/callIcon.svg";
import PersonIcon from "../../../Assets/School/PersonIcon.svg";
import location_icon from "../../../Assets/Header/header_location_icon.svg";
import chat_consultant from "../../../Assets/University/chat_consultant.svg";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import UniversityChatRequest from "../../../Components/UniversityChatRequest/UniversityChatRequest";
import ViewMoreLess from "../../../Components/ViewMoreCommon";
import { useTranslation } from "react-i18next";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UniversityDetaileView = () => {
  var CryptoJS = require("crypto-js");
  const { t } = useTranslation();
  const route = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qureyId: any = searchParams.get("id");
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [sericeCardsTemplate, setServiceCardsTemplate] = useState<any>([]);
  const [toturDetailes, setToturDetailes] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [ChatRequestOpen, setChatRequestOpen] = useState(false);
  const [ChatRequestShowData, setChatRequestShowData] = useState(false);
  const [ChatRequestMeg, setChatRequestMeg] = useState("");

  /**Sync card index on slide */
  const syncActiveIndex = ({ item }: any) => {
    setActiveIndex(item);
  };

  useEffect(() => {
    getOneList();
    setServiceCardsTemplate(getServiceCards());
  }, []);

  /**Get One List*/
  const getOneList = () => {
    var id: any = "?id=" + decrypPassData(qureyId);
    axiosInstance
      .get(`${API_END_POINTS.UNIVERSITYLIST + id}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          setToturDetailes(Response?.data?.result?.data);
        }
      })
      .catch(() => { });
  };

  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const navigateBookSlot = () => {
    // navigate(ROUTES.TUTORS_CALENDAR_VIEW, { state: { tutor_sesstion_id: courseIndex?.id } })
  };

  const getServiceCards = () => {
    return toturDetailes?.cover_images?.map((item: any) => {
      return (
        <div className="service_card">
          <div className="service_card_container">
            <img
              src={item ? item : card_bg}
              alt=""
              className="slider-img"
              onMouseMove={(event: any) => {
                positionElement(event);
              }}
              onMouseLeave={handleMouseLeave}
            />
          </div>
        </div>
      );
    });
  };

  /**Handle pointer navigation mouse leave */
  const handleMouseLeave = () => {
    setMousePosition({ x: null, y: null });
  };
  const positionElement = (e: any) => {
    setMousePosition({ x: e.pageX, y: e.pageY });
  };

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
            getOneList();
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  // Route to the Map view Page
  const handleRouteMap = () => {
    navigate(ROUTES.UNIVERSITY_MAPS_VIEW, {
      state: {
        tutor_list: toturDetailes,
        search_text: "",
        redius: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
        lat: parseFloat(toturDetailes?.latitude),
        lang: parseFloat(toturDetailes?.longitude),
        filter: "",
      },
    });
  };

  const handleChatRequestClose = () => {
    setChatRequestOpen(false);
    setChatRequestShowData(false);
    setChatRequestMeg("");
  };

  const handleChatRequestOpen = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
        axiosInstance
          .get(`${API_END_POINTS.UNIVERSITY_CHAT_CONSULTANT + '?university_id=' + toturDetailes?.id}`)
          .then((Response: any) => {
            if (Response?.data?.status) {
              var status = Response?.data?.result?.data[0];
              if (status?.room_id === 0) {
                let payload = {
                  university_id: toturDetailes?.id,
                }
                axiosInstance
                  .post(`${API_END_POINTS.UNIVERSITY_CHAT_CONSULTANT}`, payload)
                  .then((Response: any) => {
                    if (Response?.data?.status) {
                      var status_new = Response?.data?.result?.data[0];
                      navigate(ROUTES.UNIVERSITY_CHAT_VIEW, {
                        state: { roomData: status_new, is_notification: false },
                      });
                    }
                  })
                  .catch(() => { });
              } else {
                navigate(ROUTES.UNIVERSITY_CHAT_VIEW, {
                  state: { roomData: status, is_notification: false },
                });
              }
              // if (status?.is_expert === false && status?.user_exist === false) {
              //   setChatRequestOpen(true);
              //   setChatRequestShowData(true);
              //   setChatRequestMeg(Response?.data?.message);
              // } else if (
              //   status?.is_expert === true &&
              //   status?.user_exist === true
              // ) {
              //   navigate(ROUTES.UNIVERSITY_CHAT_VIEW, {
              //     state: { roomData: status },
              //   });
              // } else {
              //   setChatRequestOpen(true);
              //   setChatRequestShowData(false);
              //   setChatRequestMeg(Response?.data?.message);
              // }
            }
          })
          .catch(() => { });
      } else {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const NavigateCalendar = (url: any) => {
    // Open a new tab/window
    window.open(url, "_blank");
  };

  const handleOpenMap = (data: any) => {
    let getDestination = { lat: data.latitude, lng: data.longitude }
    const mapsUrl = getGoogleMapLink(getDestination);
    if (mapsUrl) {
      window.open(mapsUrl, '_blank');
    }
  };

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /*Below class name based on English Arabic*/
  const TutorListConatinerLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "tutorlist-conatiner-english" : "tutorlist-conatiner-arab";
  const Activities_back_img = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "back-img" : "back-img_arb";
  const UnversityIconLang = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "unversity-icon-english" : "unversity-icon-arab";
  const university__tap__contant = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "university__tap__contant" : "university__tap__contant_arb";
  return (
    <React.Fragment>
      <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
        {ChatRequestOpen && (
          <div className="overlay-ModelBackround">
            <UniversityChatRequest
              open={ChatRequestOpen}
              handleClose={handleChatRequestClose}
              data={ChatRequestShowData}
              message={ChatRequestMeg}
            />
          </div>
        )}
        <div className="University__Detaile__view">
          <div className="tittle-div">
            <img
              src={back_img}
              alt=""
              className={Activities_back_img}
              onClick={() => navigate(-1)}
            />
            <img src={universityIcon} alt="" className={`${UnversityIconLang} unversity-icon`} />
            <label className="text-tittle">{t("Universities")}</label>
          </div>
          <div className="row mt-2 w-100">
            <div className={`${TutorListConatinerLang} col-xl-4 col-lg-12 tutorlist-conatiner`}>
              <div className="display-container">
                <img
                  src={
                    toturDetailes?.images ? toturDetailes?.images : tutor_img
                  }
                  alt="" onError={(e: any) => { e.target.src = tutor_img; }}
                  className="tutor-image"
                />
                <label className="text14">
                  {toturDetailes?.university_name}
                </label>
                <div className="text10">{toturDetailes.about_me}</div>
                {/* <label className="text11">{courseIndex?.final_fee} AED</label> */}

                <div className="View__on__map__container">
                  {nullUndefinedEmptyCheck(
                    toturDetailes?.academic_calendar
                  ) && (
                      <div
                        className="wapperDiv"
                        onClick={() =>
                          NavigateCalendar(toturDetailes?.academic_calendar)
                        }
                      >
                        <img src={CalenderIcon} alt="" />
                        <span>{t("Academic Calendar")}</span>
                      </div>
                    )}
                  <div className="wapperDiv" onClick={() => handleOpenMap(toturDetailes)}>
                    <img src={viewOnMap} alt="" />
                    <span>{t("View on Map")}</span>
                  </div>
                </div>

                <div className="iconBtn__wapper">
                  <button
                    className="iconBtn"
                    onClick={() =>
                      handleChangeFavourites(
                        toturDetailes.is_favourite,
                        toturDetailes?.user
                      )
                    }
                  >
                    <img
                      src={
                        toturDetailes?.is_favourite ? UniversityFill : LikeIcon
                      }
                      alt=""
                      className="img-size5"
                    />
                    <span>{t("Add favorite")}</span>
                  </button>
                  <WebShareComponent
                    params={
                      ROUTES.UNIVERSITY_DETAILE_VIEW +
                      "?id=" +
                      encryptPassData(toturDetailes?.id)
                    }
                  >
                    <button className="iconBtn">
                      <img src={shareIcon} alt="" className="img-size5" />
                      <span>{t("Share Profile")}</span>
                    </button>
                  </WebShareComponent>
                </div>
                {/* <button className="Book-Slots-btn" onClick={navigateBookSlot}>
                                    Book Slots
                                </button> */}
              </div>
            </div>
            {/* <div className="col-xl-7 col-lg-12 tutorlist-conatiner detailes__scroll__container"> */}
            <div className={`${TutorListConatinerLang} col-xl-7 col-lg-12 tutorlist-conatiner detailes__scroll__container_university`}>
              <div className="display-container5">
                <AliceCarousel
                  mouseTracking
                  autoWidth
                  disableButtonsControls
                  items={sericeCardsTemplate}
                  disableDotsControls
                  activeIndex={activeIndex}
                  onSlideChanged={syncActiveIndex}
                />
                {mousePosition.x !== null && mousePosition.y !== null && (
                  <div
                    className="custom_cursor"
                    style={{ top: mousePosition.y, left: mousePosition.x }}
                  >
                    <img src={navigation_arrows} alt="Learn and Play" />
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-md-6 bottom__contant__wapper">
                  <div className="bottom__contant__left">
                    <img src={globalRank} alt="" />
                    <div>{t("QS world Rank")}</div>
                  </div>
                  <div className="bottom__contant__right">
                    {toturDetailes?.QS_ranking ? toturDetailes?.QS_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                  </div>
                </div>
                <div className="col-md-6 bottom__contant__wapper">
                  <div className="bottom__contant__left">
                    <img src={shangaiRank} alt="" />
                    <div>{t("The Higher Education Rank")}</div>
                  </div>
                  <div className="bottom__contant__right">
                    {toturDetailes?.THE_ranking ? toturDetailes?.THE_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                  </div>
                </div>
                <div className="col-md-6 bottom__contant__wapper">
                  <div className="bottom__contant__left">
                    <img src={shangaiRank} alt="" />
                    <div>{t("Shanghai World Rank")}</div>
                  </div>
                  <div className="bottom__contant__right">
                    {toturDetailes?.SH_ranking ? toturDetailes?.SH_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                  </div>
                </div>
                <div className="col-md-6 bottom__contant__wapper">
                  <div className="bottom__contant__left">
                    <img src={noOfStudent} alt="" />
                    <div>{t("No. of students")}</div>
                  </div>
                  <div className="bottom__contant__right">
                    {toturDetailes?.students_count
                      ? toturDetailes?.students_count
                      : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                  </div>
                </div>
                <div className="col-md-6 bottom__contant__wapper">
                  <div className="bottom__contant__left">
                    <img src={courseIocn} alt="" />
                    <div>{t("Courses")}</div>
                  </div>
                  <div className="bottom__contant__right">
                    {toturDetailes?.courses ? toturDetailes?.courses : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                  </div>
                </div>
              </div>

              {toturDetailes?.description && (
                <div className="mt-3">
                  <label className="title">{t("Description")}</label>
                  <ViewMoreLess
                    text={toturDetailes?.description}
                    maxLength={100}
                  />
                </div>
              )}

              <div className="row mt-2">
                <div className="col-md-6 founder__contant__wapper">
                  <span>{t("Founded")}</span>
                  <div>{toturDetailes?.founded_year ? toturDetailes?.founded_year : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                </div>
                <div className="col-md-6 founder__contant__wapper">
                  <span>{t("Language of Instruction")}</span>
                  <div>{toturDetailes?.available_mediums == "Both" ? t('Online & On-premises') : toturDetailes?.available_mediums}</div>
                </div>
              </div>
              {(toturDetailes?.application_process?.length > 0 || toturDetailes?.eligibility_criteria?.length > 0) && (
                <div className="school__Taps__container">
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      marginTop: "1rem",
                    }}
                  >
                    <Tabs
                      className="Uiversity__Taps"
                      value={value}
                      centered
                      onChange={handleChange}
                      // aria-label="basic tabs example"
                      indicatorColor="secondary"
                      textColor="secondary"
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="visible arrows tabs example"
                    >
                      <Tab label={t("Application Process")}  {...a11yProps(0)} />
                      <Tab label={t("Eligibility Criteria")}  {...a11yProps(1)} />
                    </Tabs>
                  </Box>

                  <TabPanel value={value} index={0}>
                    <div className={university__tap__contant}>
                      <ul>
                        {toturDetailes?.application_process?.map((data: any) => {
                          return <li>{data}</li>;
                        })}
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div className={university__tap__contant}>
                      <ul>
                        {toturDetailes?.eligibility_criteria?.map((data: any) => {
                          return <li>{data}</li>;
                        })}
                      </ul>
                    </div>
                  </TabPanel>
                </div>
              )}
              {toturDetailes?.university_program?.length > 0 && (
                <div className="Uiversity__Programs__text">{t("Programs")}</div>
              )}
              {toturDetailes?.university_program?.map((data: any, i: any) => {
                return (
                  <>
                    <div className="Uiversity__accordien">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon
                            // style={{ transform: "rotate(180deg)" }}
                            />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="title">
                            {data?.programs_offered}
                          </Typography>
                        </AccordionSummary>
                        {data?.program_classification?.map((sub: any) => {
                          return (
                            <AccordionDetails>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon
                                    // style={{transform: "rotate(270deg)"}}
                                    />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className="title">
                                    {sub?.programs_name}
                                  </Typography>
                                </AccordionSummary>
                                {sub?.subprogram_classifiction?.map(
                                  (subChild: any) => {
                                    return (
                                      <AccordionDetails className="detailes__wapper">
                                        <Typography className="text">
                                          {subChild?.sub_program_name}
                                        </Typography>
                                        <div className="d-flex gap-2 align-items-center">
                                          <Typography className="text">
                                            {t("AED")}
                                          </Typography>
                                          <Typography className="green__text">
                                            {subChild?.price}
                                          </Typography>
                                        </div>
                                      </AccordionDetails>
                                    );
                                  }
                                )}
                              </Accordion>
                            </AccordionDetails>
                          );
                        })}
                      </Accordion>
                    </div>
                  </>
                );
              })}
              <div className="School__personal__detailes__wapper">
                <div className="detailes__wapper">
                  <div className="left__side">
                    <img src={websiteIcon} alt="" />
                    <span>{t("Website")}</span>
                  </div>
                  <a
                    className="link"
                    href={toturDetailes?.web_link}
                    target="_blank"
                  >
                    {toturDetailes?.web_link}
                  </a>
                </div>
                <div className="detailes__wapper">
                  <div className="left__side">
                    <img src={emailIcon} alt="" />
                    <span>{t("Email")}</span>
                  </div>
                  <div className="number">{toturDetailes?.email}</div>
                </div>
                <div className="detailes__wapper">
                  <div className="left__side">
                    <img src={callIcon} alt="" />
                    <span>{t("Call")}</span>
                  </div>
                  <div className="number">{toturDetailes?.phone_number}</div>
                </div>
                {/* <div className="detailes__wapper">
                  <div className="left__side">
                    <img src={PersonIcon} alt="" />
                    <span>{t("Principal")}</span>
                  </div>
                  <div className="text">{toturDetailes?.principal}</div>
                </div> */}
                <div className="detailes__wapper">
                  <div className="left__side">
                    <img src={location_icon} alt="" />
                    <span>{t("Location")}</span>
                  </div>
                  <div className="text" title={toturDetailes?.address}>
                    {toturDetailes?.address}
                  </div>
                </div>
              </div>

              <div className="chat__consultant__btn__wapper">
                <button
                  className="chat__consultant__btn"
                  onClick={handleChatRequestOpen}
                >
                  <img src={chat_consultant} alt="" />
                  <span>{t("Chat with our Consultant")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default UniversityDetaileView;
