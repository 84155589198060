import { useEffect, useState, useRef } from "react";
import "./UniversityListView.scss";
import search from "../../../Assets/Tutor/searchIconGray.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_END_POINTS,
  AUTHENTICATION,
  FILTER_CONSTANT,
  GLOBAL_CONSTANTS,
  ROUTES,
  getGoogleMapLink,
} from "../../../Constants/Constants";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { store } from "../../../Redux/Store/Store";
import { setLoader, setLoginSignUpOpen, setChildLoginRestriction, setUniversityFilter } from "../../../Redux/Reducer/Reducer";
import {
  GetSessionStorageData,
  SetSessionStorageData,
  encryptPassData,
  getCurrentLocation,
  isObjectEmptyValue,
  nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";
import locationIcon from "../../../Assets/Tutor/location.svg";
import { Tooltip } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import AliceCarousel from "react-alice-carousel";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullSharpIcon from "@mui/icons-material/OpenInFullSharp";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import NavigateBeforeSharpIcon from "@mui/icons-material/NavigateBeforeSharp";
import card_bg from "../../../Assets/Tutor/card_bg.png";
import shareIcon from "../../../Assets/University/universityShareIcon.svg";
import CalenderIcon from "../../../Assets/University/universityCalenderIcon.svg";
import viewOnMap from "../../../Assets/University/universityViewMapIcon.svg";
import globalRank from "../../../Assets/University/universityGlobalRanking.svg";
import shangaiRank from "../../../Assets/University/universityShangai.svg";
import courseIocn from "../../../Assets/University/universityCourse.svg";
import noOfStudent from "../../../Assets/University/universityNoStud.svg";
import tutor_img from "../../../Assets/Tutor/tutor_image.png";
import universityImg from "../../../Assets/University/universityImg.png";
import UniversityLikeIcon from "../../../Assets/University/UniversityLikeIcon.svg";
import universityShareIcon from "../../../Assets/University/universityShareIcon.svg";
import universityIcon from "../../../Assets/University/universityIcon.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import UniversityFill from "../../../Assets/HeartFill/UniversityFill.svg";
import toast from "react-hot-toast";
import spacemen from "../../../Assets/Global/spacemen.svg";
import emailIcon from "../../../Assets/School/emailIcon.svg";
import websiteIcon from "../../../Assets/School/websiteIcon.svg";
import callIcon from "../../../Assets/School/callIcon.svg";
import PersonIcon from "../../../Assets/School/PersonIcon.svg";
import location_icon from "../../../Assets/Header/header_location_icon.svg";
import chat_consultant from "../../../Assets/University/chat_consultant.svg";
import WebShareComponent from "../../../Components/WebShare/WebShare";
import ExpandMap from "../../../Assets/Activities/ExpandMap.svg";
import UniversityMaps from "../../../Components/UniversityMaps/UniversityMaps";
import UniversityChatRequest from "../../../Components/UniversityChatRequest/UniversityChatRequest";
import ViewMoreLess from "../../../Components/ViewMoreCommon";
import AppFilter from "../../../Components/AppFilter.tsx/AppFilter";
import { useTranslation } from "react-i18next";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UniversityListView = () => {
  const University_search = GetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.UNIVERSITY);
  const route = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const carouselRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState(University_search ? University_search : '');
  const [triggerVal, setTriggerVal] = useState(true);
  const [UniversityList, setUniversityList] = useState<any>([]);
  const [searchTextList, setSearchTextList] = useState([]);
  const [currentLat, setCurrentLat] = useState<any>("");
  const [currentLang, setCurrentLang] = useState<any>("");
  const [FilterData, setFilterData] = useState<any>([]);
  const [IsTrigger, setIsTrigger] = useState<any>(null);
  const [value, setValue] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [toturDetailes, setToturDetailes] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState<any>(0);
  const [ViewOnMapActive, setViewOnMapActive] = useState(
    route?.state?.map_show
  );
  const [ChatRequestOpen, setChatRequestOpen] = useState(false);
  const [ChatRequestShowData, setChatRequestShowData] = useState(false);
  const [ChatRequestMeg, setChatRequestMeg] = useState("");

  const getUniversityFilter: any = useSelector((state: any) => {
    return state.filterReducer?.universityfilter
  })

  const init_Universityfilter: any = useSelector((state: any) => {
    return state.initFilterReducer.initUniversityfilter
  })

  // Filter
  const init_filter = {
    programs: [],
    price: [0, 0],
    qs_ranking: [0, 0],
    the_ranking: [0, 0],
    sh_ranking: [0, 0],
    language_instruction: [],
    radius: 10,
  }
  const [FilterPopOpen, setFilterPopOpen] = useState(false);
  const [initFilter, setInitFilter] = useState<any>(isObjectEmptyValue(init_Universityfilter) ? init_Universityfilter : init_filter);
  const [FilterApplyData, setFilterApplyData] = useState<any>(init_filter);
  const [initSort, setSort] = useState<any>({
    price: '',
    rating: ''
  });


  const onOpenFilterPop = () => {
    setFilterPopOpen(true);
  };

  const onCloseFilterPop = () => {
    setFilterPopOpen(false);
  };

  /** Onchange Function to Prev Button*/
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      UniversityList?.map((value: any, i: any) => {
        if (currentIndex - 1 === i) {
          setToturDetailes(value);
          setCurrentLat(value?.latitude);
          setCurrentLang(value?.longitude);
        }
      });
    }
  };

  /** Onchange Function to Next Button*/
  const handleNext = () => {
    if (currentIndex < UniversityList.length - 1) {
      setCurrentIndex(currentIndex + 1);
      UniversityList?.map((value: any, i: any) => {
        if (currentIndex + 1 === i) {
          setToturDetailes(value);
          setCurrentLat(value?.latitude);
          setCurrentLang(value?.longitude);
        }
      });
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  /**Slide previous card */
  const slidePrev = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slidePrev();
    }
  };

  /**Slide next card */
  const slideNext = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.slideNext();
    }
  };

  /**Sync card index on slide */
  const syncActiveIndex = ({ item }: any) => {
    setActiveIndex(item);
  };

  /**Sync card index on slide */
  const navigateUniversityDetailedView = (Detailes: any) => {
    navigate(ROUTES.UNIVERSITY_DETAILE_VIEW + "?id=" + encryptPassData(Detailes?.id), {
      state: { tutor_Detailes: Detailes },
    });
  };

  const CoverImg = toturDetailes?.images?.map((item: any) => {
    return (
      <>
        <img
          src={item ? item : card_bg}
          alt="card_bg" onError={(e: any) => { e.target.src = card_bg; }}
          className="card_bg_img"
        />
      </>
    );
  });

  const CoverImgEmpty = [0, 1, 2]?.map((item: any) => {
    return (
      <>
        <img src={card_bg} alt="card_bg" className="card_bg_img" />
      </>
    );
  });

  // Route to the Map view Page
  const handleRouteMap = () => {
    navigate(ROUTES.UNIVERSITY_MAPS_VIEW, {
      state: {
        University_list: UniversityList,
        search_text: searchValue,
        redius: FilterApplyData.radius,
        lat: currentLat,
        lang: currentLang,
        filter: FilterApplyData,
        filterBoolean: IsTrigger,
      },
    });
  };


  useEffect(() => {
    getFilterData();
  }, [route.state?.filter]);

  /**Header search location*/
  const HeaderLoction: any = useSelector((state: any) => {
    return state.locationReducer.location;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(HeaderLoction?.lat && HeaderLoction?.lng) && !ViewOnMapActive) {
      setCurrentLat(HeaderLoction?.lat);
      setCurrentLang(HeaderLoction?.lng);
      if (getUniversityFilter && isObjectEmptyValue(getUniversityFilter)) {
        UniversityListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, getUniversityFilter);
      } else {
        UniversityListApi(HeaderLoction?.lat, HeaderLoction?.lng, searchValue, FilterApplyData);
      }
    }
  }, [HeaderLoction, ViewOnMapActive]);

  useEffect(()=>{
    if (ViewOnMapActive && UniversityList?.length > 0) {
      setCurrentLat(UniversityList[currentIndex]?.latitude);
      setCurrentLang(UniversityList[currentIndex].longitude);
    }
  },[ViewOnMapActive])

  const payload = (latitude: any, longitude: any, value: string, filter: any) => {
    var payload;
    var qsValue: any = [];
    var shValue: any = [];
    var theValue: any = [];
    var priceValue: any = [];

    if (initFilter.price[0] === filter.price[0] && initFilter.price[1] === filter.price[1]) {
      priceValue = []
    } else {
      priceValue = {
        min: filter.price[0],
        max: filter.price[1],
      }
    }
    if (initFilter.qs_ranking[0] === filter.qs_ranking[0] && initFilter.qs_ranking[1] === filter.qs_ranking[1]) {
      qsValue = []
    } else {
      qsValue = {
        min: filter.qs_ranking[0],
        max: filter.qs_ranking[1],
      }
    }
    if (initFilter.sh_ranking[0] === filter.sh_ranking[0] && initFilter.sh_ranking[1] === filter.sh_ranking[1]) {
      shValue = []
    } else {
      shValue = {
        min: filter.sh_ranking[0],
        max: filter.sh_ranking[1],
      }
    }
    if (initFilter.the_ranking[0] === filter.the_ranking[0] && initFilter.the_ranking[1] === filter.the_ranking[1]) {
      theValue = []
    } else {
      theValue = {
        min: filter.the_ranking[0],
        max: filter.the_ranking[1],
      }
    }
    return payload = {
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
      radius: filter.radius,
      search: value ? value : "",
      price: priceValue,
      qs_ranking: qsValue,
      sh_ranking: shValue,
      the_ranking: theValue,
      programs: filter.programs,
      language_instruction: filter.language_instruction
    };

  };

  /**Tutor get List Api*/
  const UniversityListApi = (latitude: any, longitude: any, value: string, filter: any) => {
    var pay = payload(latitude, longitude, value, filter);
    axiosInstance
      .post(`${API_END_POINTS.UNIVERSITYLIST}`, pay)
      .then((Response: any) => {
        setUniversityList(Response?.data?.result?.data);
        setToturDetailes(Response?.data?.result?.data[0]);
      })
      .catch(() => { });
  };

  /**Tutor Search Keyword Api*/
  const searchUniversityKeyword = (search: any) => {
    var payload = {
      search_text: search
    };
    axiosInstance
      .post(`${API_END_POINTS.UNIVERSITY_KEY_WORD_SEARCH}`, payload)
      .then((Response: any) => {
        setSearchTextList(Response?.data.result?.data);
      })
      .catch(() => { });
  };

  /**Get Filter Categories List*/
  const getFilterData = () => {
    axiosInstance
      .get(`${API_END_POINTS.UNIVERSITYFILTERLIST}`)
      .then((Response: any) => {
        if (Response?.data?.status) {
          let data = Response?.data?.result?.data;
          setFilterData(data);
          if (data) {
            let init = {
              programs: [],
              price: [data.price?.min, data.price?.max],
              qs_ranking: [0, 0],
              the_ranking: [0, 0],
              sh_ranking: [0, 0],
              // qs_ranking: [data.qs_ranking?.min, data.qs_ranking?.max],
              // the_ranking: [data.the_ranking?.min, data.the_ranking?.max],
              // sh_ranking: [data.sh_ranking?.min, data.sh_ranking?.max],
              language_instruction: [],
              radius: 10,
            }
            setInitFilter(init);
            SetSessionStorageData(FILTER_CONSTANT.INIT_FILTER.UNIVERSITY, JSON.stringify(init));
            if (getUniversityFilter && isObjectEmptyValue(getUniversityFilter)) {
              setFilterApplyData(getUniversityFilter);
            } else {
              setFilterApplyData(init);
            }
          }

        }
      })
      .catch(() => { });
  };

  /**Filter Apply api call Function*/
  const FilterApply = (filter: any) => {
    setIsTrigger(true);
    SetSessionStorageData(FILTER_CONSTANT.STATE_FILTER.SCHOOL, JSON.stringify(filter));
    store.dispatch(setUniversityFilter(filter));
    setFilterApplyData(filter)
    UniversityListApi(currentLat, currentLang, searchValue, filter);
  };

  /**AutoComplete Onchange Function*/
  const onInputChange = (value: any) => {
    store.dispatch(setLoader(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE));
    if (nullUndefinedEmptyCheck(value) && value?.length > 2) {
      setSearchValue(value);
      SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.UNIVERSITY, value);
      if (value?.length > 2) {
        searchUniversityKeyword(value);
      }
    }
  };

  /**AutoComplete submit Function*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //  prevent page refresh
    event.preventDefault();
    UniversityListApi(currentLat, currentLang, searchValue, FilterApplyData);
  };

  /**Open the Model Diloge*/
  const handlePassSchoolData = (value: any) => {
    UniversityList?.map((item: any, i: any) => {
      if (value === i) {
        setToturDetailes(item);
        setCurrentIndex(value);
      }
    });
    if (nullUndefinedEmptyCheck(value)) {
      setTriggerVal(true);
    } else {
      setTriggerVal(false);
    }
  };

  const handleChangeFavourites = (val: any, id: any) => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      var payload = {
        service_provider_type: GLOBAL_CONSTANTS.INITIAL_VALUES.FIVE_INT,
        service_provider: id,
        is_favourite: !val,
      };
      axiosInstance
        .post(`${API_END_POINTS.FAVOURITE__ADD}`, payload)
        .then((Response: any) => {
          if (Response?.data?.status) {
            UniversityListApi(currentLat, currentLang, searchValue, FilterApplyData);
            toast.success(Response?.data?.message, {
              position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION,
          });
        });
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  /**Loader state*/
  const Loader: any = useSelector((state: any) => {
    return state.loaderReducer.loader;
  });

  // Render the  Map view Page size
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const handleChatRequestClose = () => {
    setChatRequestOpen(false);
    setChatRequestShowData(false);
    setChatRequestMeg("");
  };

  const handleChatRequestOpen = () => {
    const accessToken = GetSessionStorageData(
      AUTHENTICATION.SHARED.ACCESS_TOKEN
    );
    if (nullUndefinedEmptyCheck(accessToken)) {
      if (GetSessionStorageData(AUTHENTICATION.SHARED.ACCOUNT_TYPE) === AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT) {
        axiosInstance
          .get(`${API_END_POINTS.UNIVERSITY_CHAT_CONSULTANT + '?university_id=' + toturDetailes?.id}`)
          .then((Response: any) => {
            if (Response?.data?.status) {
              var status = Response?.data?.result?.data[0];
              if (status?.room_id === 0) {
                let payload = {
                  university_id: toturDetailes?.id,
                }
                axiosInstance
                  .post(`${API_END_POINTS.UNIVERSITY_CHAT_CONSULTANT}`, payload)
                  .then((Response: any) => {
                    if (Response?.data?.status) {
                      var status_new = Response?.data?.result?.data[0];
                      navigate(ROUTES.UNIVERSITY_CHAT_VIEW, {
                        state: { roomData: status_new, is_notification: false },
                      });
                    }
                  })
                  .catch(() => { });
              } else {
                navigate(ROUTES.UNIVERSITY_CHAT_VIEW, {
                  state: { roomData: status, is_notification: false },
                });
              }
            }
          })
          .catch(() => { });
      } else {
        dispatch(setChildLoginRestriction(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
      }
    } else {
      dispatch(setLoginSignUpOpen(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE));
    }
  };

  const NavigateCalendar = (url: any) => {
    // Open a new tab/window
    window.open(url, "_blank");
  };

  const handleOpenMap = (data: any) => {
    let getDestination = { lat: data.latitude, lng: data.longitude }
    const mapsUrl = getGoogleMapLink(getDestination);
    if (mapsUrl) {
      window.open(mapsUrl, '_blank');
    }
  };

  const handleProceedSort = (checkedPrice: any, checkedRating: any) => {
    if (checkedPrice == 'high') {
      const sortedData = [...UniversityList].sort((a: any, b: any) => b?.least_price - a?.least_price);
      setUniversityList(sortedData);
      setToturDetailes(sortedData[0]);
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedPrice == 'low') {
      const sortedData = [...UniversityList].sort((a: any, b: any) => a?.least_price - b?.least_price);
      setUniversityList(sortedData);
      setToturDetailes(sortedData[0]);
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'high') {
      const sortedData = [...UniversityList].sort((a: any, b: any) => b?.least_rating - a?.least_rating);
      setUniversityList(sortedData);
      setToturDetailes(sortedData[0]);
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    } else if (checkedRating == 'low') {
      const sortedData = [...UniversityList].sort((a: any, b: any) => a?.least_rating - b?.least_rating);
      setUniversityList(sortedData);
      setToturDetailes(sortedData[0]);
      setSort({
        price: checkedPrice,
        rating: checkedRating
      })
    }
  }

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /**get selected or current locationName from redux */
  const HeaderLocationName: any = useSelector((state: any) => {
    return state.locationNameReducer.locationName;
  });
  /*Below class name based on English Arabic*/
  const Search_icon_top = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "search_button_eng" : "search_button_arb";
  const top_cover_img_wrapper = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "top_cover_img_wrapper" : "top_cover_img_wrapper_arb";
  const university__tap__contant = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "university__tap__contant" : "university__tap__contant_arb";

  return (
    <>
      <div className="UniversityMapsView__container university__list__view">
        {ChatRequestOpen && (
          <div className="overlay-ModelBackround">
            <UniversityChatRequest
              open={ChatRequestOpen}
              handleClose={handleChatRequestClose}
              data={ChatRequestShowData}
              message={ChatRequestMeg}
            />
          </div>
        )}

        <div className="row">

          <div className="col-lg-6 col-md-12 ">
            <div className="List__topView__wapper">
              <div
                className="UniversityCard_Img_wapper"
                onClick={() => navigate(ROUTES.UNIVERSITYLAYOUT)}
              >
                <img draggable="false" src={universityIcon} />
                <div className="University__text">{t("Universities")}</div>
              </div>
              <div
                className={
                  ViewOnMapActive
                    ? "university__listView__Active List__topView__onMap"
                    : "List__topView__onMap"
                }
                onClick={() => setViewOnMapActive(!ViewOnMapActive)}
              >
                <img src={ExpandMap} alt="ExpandMap" />
                <span>
                  {ViewOnMapActive ? t("View on List") : t("View on Map")}{" "}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-12 ">
              <div className="search_field_wrapper">
                <div className="search__autocomplete">
                  <form onSubmit={handleSubmit}>
                    <Autocomplete
                      freeSolo
                      popupIcon
                      value={searchValue}
                      id="free-solo-2-demo"
                      disableClearable={false}
                      clearIcon={false}
                      onChange={(e, value) => {
                        const trimedvalue = value.trim()
                        setSearchValue(trimedvalue);
                        UniversityListApi(currentLat, currentLang, trimedvalue, FilterApplyData);
                        SetSessionStorageData(FILTER_CONSTANT.SEARCH_STATE.UNIVERSITY, trimedvalue);
                      }}
                      options={searchTextList?.map(
                        (option: any) => option?.university_name
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("Search for University")}
                          onChange={(inputChangeEvent: any) => {
                            onInputChange(inputChangeEvent.target.value?.trim());
                          }}
                          onKeyDown={(e: any) => {
                            if (e.key === GLOBAL_CONSTANTS.INITIAL_VALUES.ENTER) {
                              e.preventDefault();
                              const trimedvalue = searchValue.trim();
                              if(trimedvalue) {
                                UniversityListApi(currentLat, currentLang, searchValue, FilterApplyData);}
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            type: "text",
                          }}
                        />
                      )}
                    />
                    <img
                      draggable="false"
                      src={search}
                      alt="search"
                      className={Search_icon_top}
                      onClick={() =>
                        UniversityListApi(currentLat, currentLang, searchValue, FilterApplyData)
                      }
                    />
                  </form>
                </div>

              </div>
            </div>
            <div className="col-lg-9 col-md-12">
              <AppFilter
                FilterPopOpen={FilterPopOpen}
                onOpenPop={onOpenFilterPop}
                onClosePop={onCloseFilterPop}
                FilterData={FilterData}
                FilterApply={FilterApply}
                FilterApplyData={FilterApplyData}
                initFilter={initFilter}
                initSort={initSort}
                handleApplySort={handleProceedSort}
                type="university"
                color="#12CC92" />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              {ViewOnMapActive ? (
                <>
                  <div className="Maps__view__listPage">
                    <UniversityMaps
                      zoom={12}
                      radius={FilterApplyData.radius * 1000}
                      lat={currentLat}
                      lang={currentLang}
                      containerStyle={containerStyle}
                      disable={true}
                      zoomControl={false}
                      clickableIcons={false}
                      draggable={false}
                      UniversityList={UniversityList}
                      initSort={initSort}
                      getOneTutor={[]}
                      filter={isObjectEmptyValue(getUniversityFilter) ? getUniversityFilter : FilterApplyData}
                      initFilter={initFilter}
                      searchValue={searchValue}
                    />
                    <div className="viewMap" onClick={() => handleRouteMap()}>
                      <img src={ExpandMap} alt="ExpandMap" />
                      <span>View on Map</span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="mt-3 University__listView__container">
                  {UniversityList.map((item: any, i: any) => {
                    return (
                      <>
                        <div
                          className={
                            currentIndex === i
                              ? "school__list__wapper university__listView__Active"
                              : "school__list__wapper"
                          }
                          key={i}
                          onClick={() => handlePassSchoolData(i)}
                          onDoubleClick={() => navigateUniversityDetailedView(item)}
                        >
                          <div className="university__leftside">
                            <img
                              src={
                                nullUndefinedEmptyCheck(item?.images)
                                  ? item?.images
                                  : universityImg
                              }
                              alt="universityImg"
                              onError={(e: any) => {
                                e.target.src = universityImg;
                              }}
                            />
                          </div>
                          <div className="university__rightside">
                            <div className="university__name">
                              <span className="name">
                                {item?.university_name}
                              </span>
                              <div className="imageIcon" onClick={(e) => e.stopPropagation()}>
                                <WebShareComponent
                                  params={
                                    ROUTES.UNIVERSITY_DETAILE_VIEW +
                                    "?id=" +
                                    encryptPassData(item?.id)
                                  }
                                >
                                  <img
                                    src={universityShareIcon}
                                    alt=""
                                    className="mb-1"
                                  />
                                </WebShareComponent>
                                <img
                                  src={
                                    item?.is_favourite
                                      ? UniversityFill
                                      : UniversityLikeIcon
                                  }
                                  alt=""
                                  onClick={() =>
                                    handleChangeFavourites(
                                      item.is_favourite,
                                      item?.user
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className="university__location">
                              <img src={locationIcon} alt="" />
                              <span className="location" title={nullUndefinedEmptyCheck(item?.city) ? item?.city : item.address}>
                                {nullUndefinedEmptyCheck(item?.city) ? item?.city : item.address}
                              </span>
                            </div>

                            <div className="row">
                              <div className="col-xxl-4 col-lg-4 col-md-4 university__ranking">
                                <div className="text__black">{t("QS")} :</div>
                                <div className="text__green">{item?.QS_ranking ? item?.QS_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                              </div>
                              <div className="col-xxl-4 col-lg-4 col-md-4 university__ranking">
                                <div className="text__black">{t("THE")} : </div>
                                <div className="text__green">{item?.THE_ranking ? item?.THE_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                              </div>
                              <div className="col-xxl-4 col-lg-4 col-md-4 university__ranking">
                                <div className="text__black">{t("SH")} : </div>
                                <div className="text__green">{item?.SH_ranking ? item?.SH_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                              </div>
                              <div className="col-xxl-6 col-lg-6 col-md-6 mt-3 university__ranking">
                                <div className="text__black">{t("No. of students")} : </div>
                                <div className="text__green">{item?.students_count ? item?.students_count : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                              </div>
                              <div className="col-xxl-6 col-lg-6 col-md-6 mt-3 university__ranking">
                                <div className="text__black">{t("Mediums")} : </div>
                                <div className="text__green">
                                  {item?.available_mediums == "Both" ? t('Online & On-premises') : item?.available_mediums}
                                </div>
                              </div>
                            </div>
                            <div className="university__rating__list__view">
                              <div className="year">
                                {t("Founded")} : {item?.founded_year ? item?.founded_year : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                              </div>
                              {/* <div className="rating__wapper">
                              <StarIcon className="StarIcon" />
                              <span className="points">{item?.rating}</span>
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {UniversityList?.length === 0 && !Loader && (
                    <>
                      <div className="No__data__contant">
                        <img src={spacemen} alt="" />
                        <div className="text">{t("No Data Found")}</div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3">
              {UniversityList?.length > 0 && (
                <div
                  className={
                    triggerVal
                      ? "schoolListView__rightSide__Pop"
                      : "animate-slideInRight Model__display__none"
                  }
                >
                  <div className="top__contant__wapper__university">
                    <div className="top__PageBtn__icons">
                      {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                        <NavigateBeforeSharpIcon className={currentIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrev} />
                      ) : (
                        <NavigateNextSharpIcon className={currentIndex > 0 ? "prev__icons" : "icons__disable"} onClick={handlePrev} />
                      )}
                      <div
                        className={
                          currentIndex > 0 ? "prev__text" : "text__disable"
                        }
                        onClick={handlePrev}
                      >
                        {t("Prev")}
                      </div>
                      <div
                        className={
                          currentIndex < UniversityList?.length - 1
                            ? "prev__text"
                            : "text__disable"
                        }
                        onClick={handleNext}
                      >
                        {t("Next")}
                      </div>
                      {language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? (
                        <NavigateNextSharpIcon className={currentIndex < UniversityList?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNext} />
                      ) : (
                        <NavigateBeforeSharpIcon className={currentIndex < UniversityList?.length - 1 ? "next__icons" : "icons__disable"} onClick={handleNext} />
                      )}
                    </div>
                    <div className="top__close__icons">
                      <Tooltip title="Expanded">
                        <OpenInFullSharpIcon
                          className="expanded__icons"
                          onClick={() => navigateUniversityDetailedView(toturDetailes)}
                        />
                      </Tooltip>
                      <Tooltip title="close">
                        <CloseIcon
                          className="close__icons"
                          onClick={() => setTriggerVal(!triggerVal)}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className={top_cover_img_wrapper}>
                    <div className="arrow_navs">
                      <NavigateBeforeSharpIcon
                        onClick={slidePrev}
                        className="left_arrow"
                      />
                      <NavigateNextSharpIcon
                        onClick={slideNext}
                        className="right_arrow"
                      />
                    </div>
                    <AliceCarousel
                      // infinite
                      mouseTracking
                      autoWidth
                      disableButtonsControls
                      items={
                        toturDetailes?.images?.length > 0
                          ? CoverImg
                          : CoverImgEmpty
                      }
                      disableDotsControls
                      activeIndex={activeIndex}
                      onSlideChanged={syncActiveIndex}
                      ref={carouselRef}
                    />
                    <img
                      src={
                        toturDetailes?.images?.length > 0 ? toturDetailes?.images[0] : tutor_img
                      }
                      alt="tutor_img"
                      className="profileImg"
                      onError={(e: any) => { e.target.src = tutor_img; }}
                    />
                  </div>

                  <div className="card__container">
                    <div className="w-100">
                      <div className="user__Detailes">
                        <div className="user__name">
                          {toturDetailes?.university_name}
                        </div>
                        <div className="user__icon__wrapper">
                          {/* <img src={comments} alt="comments" /> */}
                          <WebShareComponent
                            params={
                              ROUTES.UNIVERSITY_DETAILE_VIEW +
                              "?id=" +
                              encryptPassData(toturDetailes?.id)
                            }
                          >
                            <img
                              src={shareIcon}
                              alt="shareIcon"
                              className="mb-1"
                            />
                          </WebShareComponent>
                          <img
                            src={
                              toturDetailes?.is_favourite
                                ? UniversityFill
                                : UniversityLikeIcon
                            }
                            alt=""
                            onClick={() =>
                              handleChangeFavourites(
                                toturDetailes?.is_favourite,
                                toturDetailes?.user
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="View__on__map__container">
                        {nullUndefinedEmptyCheck(
                          toturDetailes?.academic_calendar
                        ) && (
                            <div
                              className="wapperDiv"
                              onClick={() =>
                                NavigateCalendar(toturDetailes?.academic_calendar)
                              }
                            >
                              <img src={CalenderIcon} alt="" />
                              <span>{t("Academic Calendar")}</span>
                            </div>
                          )}
                        <div className="wapperDiv" onClick={() => handleOpenMap(toturDetailes)}>
                          <img src={viewOnMap} alt="" />
                          <span>{t("View on Map")}</span>
                        </div>
                      </div>

                      <div className="row University__detailed__container__">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                          <div className="detailed__container__wapper">
                            <img src={globalRank} alt="" />
                            <span>{t("QS world Rank")}</span>
                          </div>
                          <div className="number">
                            {toturDetailes?.QS_ranking ? toturDetailes?.QS_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                          <div className="detailed__container__wapper">
                            <img src={shangaiRank} alt="" />
                            <span>{t("The Higher Education Rank")}</span>
                          </div>
                          <div className="number">
                            {toturDetailes?.THE_ranking ? toturDetailes?.THE_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                          <div className="detailed__container__wapper">
                            <img src={shangaiRank} alt="" />
                            <span>{t("Shanghai World Rank")}</span>
                          </div>
                          <div className="number">
                            {toturDetailes?.SH_ranking ? toturDetailes?.SH_ranking : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                          <div className="detailed__container__wapper">
                            <img src={noOfStudent} alt="" />
                            <span>{t("No. of students")}</span>
                          </div>
                          <div className="number">
                            {toturDetailes?.students_count ? toturDetailes?.students_count : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 flexDiv">
                          <div className="detailed__container__wapper">
                            <img src={courseIocn} alt="" />
                            <span>{t("Courses")}</span>
                          </div>
                          <div className="number">{toturDetailes?.courses ? toturDetailes?.courses : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                      </div>

                      {toturDetailes?.description && (
                        <div className="university__description__container">
                          <div className="title">{t("Description")}</div>
                          <ViewMoreLess
                            text={toturDetailes?.description}
                            maxLength={100}
                          />
                        </div>
                      )}

                      <div className="university__bottom__contant">
                        <div className="bottom__contant__wapper">
                          <span>{t("Founded")}</span>
                          <div>{toturDetailes?.founded_year ? toturDetailes?.founded_year : GLOBAL_CONSTANTS.INITIAL_VALUES.NA}</div>
                        </div>
                        <div className="bottom__contant__wapper mt-2">
                          <span>{t("Language of Instruction")}</span>
                          <div>{toturDetailes?.available_mediums == "Both" ? t('Online & On-premises') : toturDetailes?.available_mediums}</div>
                        </div>
                      </div>
                      {(toturDetailes?.application_process?.length > 0 || toturDetailes?.eligibility_criteria?.length > 0) && (
                        <div className="school__Taps__container">
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                            }}
                          >
                            <Tabs
                              className="Uiversity__Taps"
                              value={value}
                              centered
                              onChange={handleChange}
                              aria-label="basic tabs example"
                              textColor="secondary"
                              indicatorColor="secondary"
                            >
                              <Tab label={t("Application Process")} {...a11yProps(0)} />
                              <Tab label={t("Eligibility Criteria")} {...a11yProps(1)} />
                            </Tabs>
                          </Box>

                          <TabPanel value={value} index={0}>
                            <div className={university__tap__contant}>
                              <ul>
                                {toturDetailes?.application_process?.map(
                                  (data: any) => {
                                    return <li>{data}</li>;
                                  }
                                )}
                              </ul>
                            </div>
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <div className={university__tap__contant}>
                              <ul>
                                {toturDetailes?.eligibility_criteria?.map(
                                  (data: any) => {
                                    return <li>{data}</li>;
                                  }
                                )}
                              </ul>
                            </div>
                          </TabPanel>
                        </div>
                      )}
                      {toturDetailes?.university_program?.length > 0 && (
                        <div className="Uiversity__Programs__text">{t("Programs")}</div>
                      )}
                      {toturDetailes?.university_program?.map(
                        (data: any, i: any) => {
                          return (
                            <>
                              <div className="Uiversity__accordien">
                                <Accordion>
                                  <AccordionSummary
                                    className="Uiversity__accordien__head"
                                    expandIcon={
                                      <ExpandMoreIcon
                                      // style={{ transform: "rotate(180deg)" }}
                                      />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography className="title">
                                      {data?.programs_offered}
                                    </Typography>
                                  </AccordionSummary>
                                  {data?.program_classification?.map(
                                    (sub: any) => {
                                      return (
                                        <AccordionDetails>
                                          <Accordion>
                                            <AccordionSummary
                                              className="Uiversity__accordien__body_head"
                                              expandIcon={
                                                <ExpandMoreIcon
                                                // style={{ transform: "rotate(180deg)" }}
                                                />
                                              }
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <Typography className="title">
                                                {sub?.programs_name}
                                              </Typography>
                                            </AccordionSummary>
                                            {sub?.subprogram_classifiction?.map(
                                              (subChild: any) => {
                                                return (
                                                  <AccordionDetails className="detailes__wapper">
                                                    <Typography className="text">
                                                      {subChild?.sub_program_name}
                                                    </Typography>
                                                    <div className="d-flex gap-2 align-items-center">
                                                      <Typography className="text">
                                                        AED
                                                      </Typography>
                                                      <Typography className="green__text">
                                                        {subChild?.price}
                                                      </Typography>
                                                    </div>
                                                  </AccordionDetails>
                                                );
                                              }
                                            )}
                                          </Accordion>
                                        </AccordionDetails>
                                      );
                                    }
                                  )}
                                </Accordion>
                              </div>
                            </>
                          );
                        }
                      )}
                      <div className="School__personal__detailes__wapper mt-3">
                        <div className="detailes__wapper">
                          <div className="left__side">
                            <img src={websiteIcon} alt="" />
                            <span>{t("Website")}</span>
                          </div>
                          <a
                            className="link"
                            href={toturDetailes?.web_link}
                            target="_blank"
                          >
                            {toturDetailes?.web_link}
                          </a>
                        </div>
                        <div className="detailes__wapper">
                          <div className="left__side">
                            <img src={emailIcon} alt="" />
                            <span>{t("Email")}</span>
                          </div>
                          <div className="number">{toturDetailes?.email}</div>
                        </div>
                        <div className="detailes__wapper">
                          <div className="left__side">
                            <img src={callIcon} alt="" />
                            <span>{t("Call")}</span>
                          </div>
                          <div className="number">
                            {toturDetailes?.phone_number}
                          </div>
                        </div>
                        {/* <div className="detailes__wapper">
                          <div className="left__side">
                            <img src={PersonIcon} alt="" />
                            <span>{t("Principal")}</span>
                          </div>
                          <div className="text">{toturDetailes?.principal}</div>
                        </div> */}
                        <div className="detailes__wapper">
                          <div className="left__side">
                            <img src={location_icon} alt="" />
                            <span>{t("Location")}</span>
                          </div>
                          <div className="text" title={toturDetailes?.address}>
                            {toturDetailes?.address}
                          </div>
                        </div>
                      </div>
                      <div className="chat__consultant__btn__wapper">
                        <button
                          className="chat__consultant__btn"
                          onClick={handleChatRequestOpen}
                        >
                          <img src={chat_consultant} alt="" />
                          <span>{t("Chat with our Consultant")}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UniversityListView;
