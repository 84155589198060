import "./AboutUS.scss";
import AboutImg from "../../Assets/ProfileSettings/about-us-Img.png"
import { GetSessionStorageData } from "../../Utilities/Helpers/Helper";
import { AUTHENTICATION } from "../../Constants/Constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AboutUS = () => {
    const token = GetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN);
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className={token ? "AboutUs__container" : "AboutUs__container__without_token"} >

                {!token && (<div className="title_head">{t("About US")}</div>)}

                <div className="container__wapper">
                    <img src={AboutImg} alt="" className="AboutImg" />
                    <div className="title">{t("A promise that’s worth working for")}</div>
                    <p className="pragrap">{t("AboutUs Des1")}</p>
                    <p className="pragrap">{t("AboutUs Des2")}</p>
                    <img src={AboutImg} alt="" className="AboutImg" />
                    <p className="pragrap">{t("AboutUs Des3")} </p>
                </div>

            </div>
        </>
    )
}

export default AboutUS;