import "./Support.scss";
import supportImg from "../../Assets/ProfileSettings/supportImg.png";
import Email from "../../Assets/ProfileSettings/supportEmail.svg";
import Call from "../../Assets/ProfileSettings/supportCall.svg";
import Chat from "../../Assets/ProfileSettings/supportChat.svg";
import Feedback from "../../Assets/ProfileSettings/supportFeedback.svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import { API_END_POINTS, GLOBAL_CONSTANTS, ROUTES } from "../../Constants/Constants";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { useState } from "react";
import SupportChatRequest from "../SupportChatReq/SupportChatReq";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Support = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ChatRequestOpen, setChatRequestOpen] = useState(false);
    const [ChatRequestShowData, setChatRequestShowData] = useState(false);
    const [ChatRequestMeg, setChatRequestMeg] = useState('');

    const handleChatRequestOpen = () => {
        axiosInstance
            .get(`${API_END_POINTS.SUPPORT_CHAT}`)
            .then((Response: any) => {
                if (Response?.data?.status) {
                    var status = Response?.data?.result?.data;
                    if (status?.is_expert === false && status?.user_exist === false) {
                        setChatRequestOpen(true);
                        setChatRequestShowData(true);
                        setChatRequestMeg(Response?.data?.message);
                    } else if (status?.is_expert === true && status?.user_exist === true) {
                        navigate(ROUTES.ADMIN_CHAT, { state: { roomData: status } })
                    } else {
                        setChatRequestOpen(true)
                        setChatRequestShowData(false);
                        setChatRequestMeg(Response?.data?.message);
                    }
                }
            })
            .catch(() => { });

    };

    const handleChatRequestClose = () => {
        setChatRequestOpen(false);
        setChatRequestShowData(false);
        setChatRequestMeg("");
    }

    const openCall = () => {
        navigator.clipboard.writeText('+123456789');
        toast.success("Copied!", {
            position: GLOBAL_CONSTANTS.INITIAL_VALUES.TOAST_POSITION
        });
        // window.location.href = 'tel:' + '+123456789';
    };

    const handleEmaiClick = () => {
        window.open('mailto:LearnAndplay@gmail.com', '_blank');
    };

        /**get selected or current language from redux */
        const language: any = useSelector((state: any) => {
            return state.languageReducer.language;
          });
        /*Below class name based on English Arabic*/
         const ButtonWapperImg = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "ButtonWapperImg-Eng" : "ButtonWapperImg-Arab";


    return (
        <>

            {ChatRequestOpen && (
                <div className="overlay-ModelBackround">
                    <SupportChatRequest open={ChatRequestOpen} handleClose={handleChatRequestClose} data={ChatRequestShowData} message={ChatRequestMeg} />
                </div>
            )}
            <div className="Support__container">
                <div className="container__wapper">

                    <div className="img__container">
                        <img src={supportImg} alt="" />
                    </div>

                    <div className="contants mt-3">
                        {t("hello there! our support team will contact you within 24-48 hours once you reach out to them")}
                    </div>

                    <div className="Button__wapper" onClick={handleEmaiClick}>
                        <div className="accordion__lable__wapper">
                            <img src={Email} alt=""/>
                            <div className="text">{t("Email")}</div>
                        </div>
                        <ExpandMoreIcon style={{ transform: 'rotate(270deg)'}} className={`${ButtonWapperImg}`} />
                    </div>
                    <div className="Button__wapper" onClick={openCall}>
                        <div className="accordion__lable__wapper">
                            <img src={Call} alt=""/>
                            <div className="text">{t("Call")}</div>
                        </div>
                        <ExpandMoreIcon style={{ transform: 'rotate(270deg)'}} className={`${ButtonWapperImg}`} />
                    </div>
                    {/* <div className="Button__wapper" onClick={handleChatRequestOpen}>
                        <div className="accordion__lable__wapper">
                            <img src={Chat} alt="" />
                            <div className="text">Chat with us</div>
                        </div>
                        <ExpandMoreIcon style={{ transform: 'rotate(270deg)' }} />
                    </div>
                    <div className="Button__wapper">
                        <div className="accordion__lable__wapper">
                            <img src={Feedback} alt="" />
                            <div className="text">Send Feedback</div>
                        </div>
                        <ExpandMoreIcon style={{ transform: 'rotate(270deg)' }} />
                    </div> */}
                    {/* <div className="session__wapper mt-4">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ transform: 'rotate(180deg)' }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    <div className="accordion__lable__wapper">
                                        <img src={Email} alt="" />
                                        <div className="text">Email</div>
                                    </div>

                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div className="paragraph__contant">Lorem ipsum dolor sit amet, consectetuer <a href="mailto:LearnAndplay@gmail.com" target="_blank">Learn&play@gmail.com</a>  nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                                        quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div> */}

                    {/* <div className="session__wapper">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ transform: 'rotate(180deg)' }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    <div className="accordion__lable__wapper">
                                        <img src={Call} alt="" />
                                        <div className="text">Call</div>
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div className="paragraph__contant">Lorem ipsum dolor sit amet, consectetuer <a href="" target="_blank" onClick={openCall}>+123456789</a>  nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                                        quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div> */}

                    {/* <div className="session__wapper">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ transform: 'rotate(180deg)' }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    <div className="accordion__lable__wapper">
                                        <img src={Chat} alt="" />
                                        <div className="text">Chat with us</div>
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <button className="chatBtn" onClick={handleChatRequestOpen} >Click to Chat with us</button>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div> */}

                    {/* <div className="session__wapper">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ transform: 'rotate(180deg)' }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    <div className="accordion__lable__wapper">
                                        <img src={Feedback} alt="" />
                                        <div className="text">Send Feedback</div>
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div className="paragraph__contant">Lorem ipsum dolor sit amet, consectetuer <a href="/Learn&play@gmail.com">Learn&play@gmail.com</a>  nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                                        quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div> */}

                </div>

            </div >
        </>
    )
}

export default Support;