import AWS from "aws-sdk";
var CryptoJS = require("crypto-js");
const secretPass = "XkhZG4fW2t2W";

/** store data in session storage */
export const SetSessionStorageData = (sessionKey: string, payload: any) => {
  return sessionStorage.setItem(sessionKey, payload);
};

/** get data from session storage */
export const GetSessionStorageData = (sessionKey: string) => {
  return sessionStorage.getItem(sessionKey);
};
/** check for null, undefined and empty value */
export const nullUndefinedEmptyCheck = (data: any) => {
  return data !== null && data !== undefined && data !== "";
};
/** check for null value */
export const nullCheck = (data: any) => {
  return data !== null;
};
/** check for object Key */
export const isObjectEmptyKey = (obj: any) => {
  return Object.keys(obj)?.length > 0;
};

/** check for object values */
export const isObjectEmptyValue = (obj: any) => {
  return Object.values(obj)?.length > 0;
};

/* To clear session storage */
export const clearSessionStorage = () => {
  return sessionStorage.clear();
};

/* Get current user's location using the browser's Geolocation API */
export const getCurrentLocation = (successCallback: any, errorCallback: any) => {
  return navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
};
/**Upload files to S3 bucket */
export const uploadFileToS3 = (file: any, key: any) => {
  return new Promise((resolve, reject) => {
    AWS.config.update({
      accessKeyId: key?.access_key,
      secretAccessKey: key?.secret_access_key,
      region: process.env.REACT_APP_S3_REGION,
    });

    const s3 = new AWS.S3();
    const params: any = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: file.name,
      Body: file,
      ACL: "public-read",
      ContentType: file?.type,
    };

    s3.upload(params, (err: any, data: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(data.Location);
      }
    });
  });
};

/**empty length check */
export const checkEmpty = (data: any): boolean => {
  return data.length !== 0;
};

/**crypto-js encrypt Data */
export const encryptPassData = (data: any) => {
  var encodeURI = CryptoJS.AES.encrypt(JSON.stringify(data), secretPass).toString()
  return encodeURIComponent(encodeURI);
};

/**crypto-js decryp Data */
export const decrypPassData = (data: any) => {
  let decryp = decodeURIComponent(data);
  var bytes = CryptoJS?.AES?.decrypt(decryp, secretPass)?.toString(CryptoJS?.enc?.Utf8);
  return JSON.parse(bytes);
};