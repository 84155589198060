import { useEffect } from 'react'
import { SetSessionStorageData } from '../../../Utilities/Helpers/Helper';
import { AUTHENTICATION, GLOBAL_CONSTANTS } from '../../../Constants/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../../Redux/Reducer/Reducer';
import { useTranslation } from 'react-i18next';
import './GTranslator.scss';

const GTranslator = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  // const [durections, setDropdownDirection] = useState<any>('ltr');

  /**get selected or current language from redux */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const getCurrentTranslateLanguage = (): string | null => {
    const selectElement = document.querySelector(".goog-te-combo") as HTMLSelectElement;
    return selectElement ? selectElement.value : null;
  };

  const handleTranslateLanguageChange = () => {
    const selectedLanguage = getCurrentTranslateLanguage();
    // Do something with the selected language
    if (selectedLanguage === 'ar') {
      SetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH, 'Arabic');
      dispatch(setLanguage('Arabic'));
      i18n.changeLanguage('Arabic');
      setDropdownDirection('rtl');
    } else if (selectedLanguage === 'en') {
      SetSessionStorageData(AUTHENTICATION.SHARED.LANGUAGE_SWICTH, 'English');
      dispatch(setLanguage('English'));
      i18n.changeLanguage('English');
      setDropdownDirection('ltr');
    }
  };

  const setDropdownDirection = (direction: 'rtl' | 'ltr') => {
    const selectElement = document.querySelector(".goog-te-combo") as HTMLSelectElement;
    if (selectElement) {
      selectElement.style.direction = direction;
    }
  };

  const updateArabicOptionText = () => {
    const selectElement = document.querySelector(".goog-te-combo") as HTMLSelectElement;
    const userLang = navigator?.language;
    if (selectElement && selectElement.options.length > 0) {
      const optionAr = selectElement.querySelector('option[value="ar"]');
      const optionEn = selectElement.querySelector('option[value="en"]');
      if (optionAr) {
        optionAr.textContent = "العربية";
        optionAr.setAttribute('translate', 'no');
      }
      if (optionEn && userLang !== 'en') {
        optionEn.textContent = "English";
        optionEn.setAttribute('translate', 'no');
      }
      // Add translate="no" attribute to all options
      Array.from(selectElement.options).forEach(option => {
        option.setAttribute('translate', 'no');
      });
    }
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "ar",
        autoDisplay: true,
        includedLanguages: "en,ar",
        multilanguagePage: true,
      },
      "google_translate_element"
    );

    const observer = new MutationObserver(() => {
      updateArabicOptionText();
    });

    observer.observe(document.body, { childList: true, subtree: true });


    // Remove unnecessary elements
    const googleDivChild = document.querySelector("#google_translate_element .skiptranslate div");
    if (googleDivChild && googleDivChild.nextElementSibling) {
      googleDivChild.nextElementSibling.remove();
    }
    const googleDiv: any = document.getElementById("google_translate_element");
    googleDiv.childNodes?.forEach((node: any) => {
      if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() !== "") {
        googleDiv.removeChild(node);
      }
    });

    // Set up the language selection dropdown
    const selectElement: any = document.querySelector(".goog-te-combo") as HTMLSelectElement;
    if (selectElement) {
      setTimeout(() => {
        selectElement.value = language === GLOBAL_CONSTANTS.LANGUAGES.ENGLISH ? "en" : "ar";
        // Dispatch change event manually as some browsers do not trigger it automatically
        const event = new Event("change", { bubbles: true });
        selectElement.dispatchEvent(event);
        setDropdownDirection(language === 'Arabic' ? 'rtl' : 'ltr'); // Set initial direction
      }, 100);
      selectElement.addEventListener("change", handleTranslateLanguageChange);
    }
  };

  useEffect(() => {
    const addScript = document.createElement("script");
    addScript.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    addScript.async = true;
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    // Hide the "Select Language" label using CSS
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .goog-te-combo option[value=""] {
        display: none;
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      document.body.removeChild(addScript);
      document.head.removeChild(styleElement);
      const selectElement = document.querySelector(".goog-te-combo") as HTMLSelectElement;
      selectElement?.removeEventListener("change", handleTranslateLanguageChange);
    };
  }, []);

  return (
    <div id="google_translate_element" className="web_view" onChange={getCurrentTranslateLanguage}></div>
  )
}

export default GTranslator;