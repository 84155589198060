import * as React from "react";
import styled from "@emotion/styled";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NestedMenuItem from "./NestedMenuItem";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import "./SchoolDashboardLayout.scss";

export const Dropdown = React.forwardRef((
    {
        trigger,
        isTrigger,
        menu,
        keepOpen: keepOpenGlobal,
        isOpen: controlledIsOpen,
        onOpen: onControlledOpen,
        minWidth,
        Submit, Reset
    }: any,
    ref
) => {
    const [isInternalOpen, setInternalOpen] = React.useState(isTrigger ? isTrigger : null);

    const isOpen = controlledIsOpen || isInternalOpen;

    let anchorRef: any = React.useRef(null);
    if (ref) {
        anchorRef = ref;
    }

    /**Filter List open Function*/
    const handleOpen = (event: any) => {
        event.stopPropagation();

        if (menu.length) {
            onControlledOpen
                ? onControlledOpen(event.currentTarget)
                : setInternalOpen(event.currentTarget);
        }
    };

    /**Filter List close Function*/
    const handleClose = (event: any) => {
        event.stopPropagation();

        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        handleForceClose();
    };

    /**Filter List close hover Function*/
    const handleForceClose = () => {
        onControlledOpen ? onControlledOpen(null) : setInternalOpen(null);
    };

    const renderMenu: any = (menuItem: any, index: any) => {
        const { keepOpen: keepOpenLocal, ...props }: any = menuItem.props;

        let extraProps = {};
        if (props.menu) {
            extraProps = {
                parentMenuOpen: isOpen
            };
        }

        return React.createElement(menuItem.type, {
            ...props,
            key: index,
            ...extraProps,
            onClick: (event: any) => {
                event.stopPropagation();

                if (!keepOpenGlobal && !keepOpenLocal) {
                    handleClose(event);
                }

                if (menuItem.props.onClick) {
                    menuItem.props.onClick(event);
                }
            },
            children: props.menu
                ? React.Children.map(props.menu, renderMenu)
                : props.children
        });
    };

    return (
        <>
            {React.cloneElement(trigger, {
                onClick: isOpen ? handleForceClose : handleOpen,
                ref: anchorRef
            })}
            <Menu
                PaperProps={{ sx: { minWidth: minWidth ?? 0 } }}
                anchorEl={isOpen}
                open={!!isOpen}
                onClose={handleClose}
            >
                <div className="Filter__drp_top_wapper__school">
                    <ChevronLeftIcon className="left__icon" onClick={handleForceClose} />
                    <span className="title">Filters</span>
                </div>

                {React.Children.map(menu, renderMenu)}
                <div className="Filter__drp__bottom_btn__school">
                    <button className="resetBtn" onClick={Reset}>Reset</button>
                    <button className="applyBtn" onClick={Submit}>Apply</button>
                </div>

            </Menu>
        </>
    );
}
);

export const DropdownMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between !important;
  color:"red";

  & > svg {
    margin-left: 32px;
  }
`;

export const DropdownNestedMenuItem = styled(NestedMenuItem)`
  display: flex;
  justify-content: space-between !important;
  color:"red";

  & > svg {
    margin-left: 32px;
  }
`;
