import { useSelector } from "react-redux";
import "./Loader.scss";
import { ColorRing } from "react-loader-spinner";

const Loader = () => {
  const loaderVisibility: any = useSelector((state: any) => {
    return state.loaderReducer.loader;
  });
  return (
    <>
      {loaderVisibility && (
        <div className="spinner_container">
          {/* <ColorRing
            visible={loaderVisibility}
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#f8e541", "#bd51ff", "#51daff", "#f8e541", "#bd51ff"]}
          /> */}
          <div className="loader loader_bubble"></div>
        </div>
      )}
    </>
  );
};
export default Loader;
