import React, { useEffect, useState } from 'react';
import { Dialog, Button, Divider } from '@mui/material';
import OtpInput from 'react-otp-input';
import './ThemeParkGuestUserLoginOtp.scss';
import OtpVerficationArrowIcon from "../../../Assets/ThemePark/OtpVerficationArrowIcon.svg";
import {
    API_END_POINTS,
    AUTHENTICATION,
    GLOBAL_CONSTANTS,
} from "../../../Constants/Constants";
import { axiosInstance } from "../../../Utilities/Interceptor/Interceptor";
import toast from 'react-hot-toast';
import {
    GetSessionStorageData,
    SetSessionStorageData,
    clearSessionStorage,
    getCurrentLocation,
    nullCheck,
    nullUndefinedEmptyCheck,
} from "../../../Utilities/Helpers/Helper";

interface OtpModalProps {
    openUserLoginOtp: any;
    CloseUserLoginOtpModel: () => void;
    handleBack: any;
    handleGuestSignUpClose: (isSignupNavigation: any) => void;
}
interface GuestUserData {
    email: string;
    firstName: string;
    lastName: string;
    // Add other properties if there are more in your actual data structure
}

const ThemeParkGuestUserLoginOtp: React.FC<OtpModalProps> = (props: OtpModalProps) => {
    const { openUserLoginOtp, CloseUserLoginOtpModel, handleBack, handleGuestSignUpClose } = props;
    const [otp, setOtp] = React.useState<string>('');
    const [showResend, setShowResend] = useState(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    const [timer, setTimer] = useState(AUTHENTICATION.OTP_VERIFICATION_TIMER.TIMER_INITIAL_VALUE);
    const [guestUserData, setGuestUserData] = useState<GuestUserData | null>(null);
    const [submitted, setSubmitted] = useState(false);


    useEffect(() => {
        const guest: any = GetSessionStorageData(AUTHENTICATION.SHARED.GUEST_DATA);
        if (nullUndefinedEmptyCheck(guest)) {
            let parsedData = JSON.parse(guest);
            console.log(parsedData, "guest")
            setGuestUserData(JSON.parse(guest));
        }

    }, [openUserLoginOtp]);

    useEffect(() => {
        setOtp("");
        setSubmitted(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
    }, [openUserLoginOtp]);

    const handleChange = (otp: string) => {
        setOtp(otp);
    };

    useEffect(() => {
        setTimer(AUTHENTICATION.OTP_VERIFICATION_TIMER.TIMER_INITIAL_VALUE);
    }, [])

    useEffect(() => {
        if (openUserLoginOtp) {
            updateTimer();
        }
    }, [timer, openUserLoginOtp]);

    /**Update timer */
    const updateTimer = () => {
        setTimeout(() => {
            if (timer > GLOBAL_CONSTANTS.INITIAL_VALUES.ZERO_INT) {
                setTimer(timer - GLOBAL_CONSTANTS.INITIAL_VALUES.ONE_INT);
            } else {
                setShowResend(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
            }
        }, AUTHENTICATION.OTP_VERIFICATION_TIMER.OTP_VERIFICATION_TIMEOUT);
    };

    /**Resend Otp */
    const resendOtp = () => {
        setShowResend(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
        setTimer(AUTHENTICATION.OTP_VERIFICATION_TIMER.TIMER_INITIAL_VALUE);
        guestUserSignUp();

    };

    const guestUserSignUp = () => {
        let data = {
            login_type: 1,
            email: guestUserData?.email
        }
        axiosInstance
            .post(`${API_END_POINTS.THEMEPARK_GUESTUSER}`, data)
            .then((res: any) => {
                console.log(res, "res")
                if (res.data[AUTHENTICATION.SHARED.STATUS] === AUTHENTICATION.SHARED.TRUE) {
                    toast.success(res.data.message, {
                        position: "top-right"
                    });
                }
            })
            .catch((err) => {
                console.log(err, "err")
                handleGuestSignUpClose(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
            });
    };

    const verifyOtp = () => {
        setSubmitted(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);

        let payload = {
            type: "email",
            email: guestUserData?.email,
            firstname: guestUserData?.firstName,
            lastname: guestUserData?.lastName,
            otp: otp
        }

        axiosInstance
            .post(API_END_POINTS.THEMEPARK_GUESTUSER_VERIFY, payload)
            .then((res: any) => {
                if (
                    res.data[AUTHENTICATION.SHARED.STATUS] ===
                    AUTHENTICATION.SHARED.TRUE
                ) {
                    toast.success(res.data.message, {
                        position: "top-right"
                    });
                    CloseUserLoginOtpModel();

                    SetSessionStorageData(
                        AUTHENTICATION.SHARED.GUEST_ACCESS_TOKEN,
                        res.data.result.data.access_token
                    );
                    SetSessionStorageData(
                        AUTHENTICATION.SHARED.GUEST_REFRESH_TOKEN,
                        res.data.result.data.refresh_token
                    );
                    // if (!isLogin) {
                    //     setOpenPersonalDetailsDialog(
                    //         GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE
                    //     );
                    // } else {
                    // SetSessionStorageData(
                    //     AUTHENTICATION.SHARED.ACCESS_TOKEN,
                    //     res.data.result.data.access_token
                    // );
                    // SetSessionStorageData(
                    //     AUTHENTICATION.SHARED.REFRESH_TOKEN,
                    //     res.data.result.data.refresh_token
                    // );
                    // SetSessionStorageData(
                    //     AUTHENTICATION.SHARED.NAME,
                    //     res.data.result.data.user_info.name
                    // );
                    // SetSessionStorageData(
                    //     AUTHENTICATION.SHARED.EMAIL,
                    //     res.data.result.data.user_info.email
                    // );
                    // SetSessionStorageData(
                    //     AUTHENTICATION.SHARED.MOBILE,
                    //     res.data.result.data.user_info.phone_number
                    // );
                    // SetSessionStorageData(
                    //     AUTHENTICATION.SHARED.PARENT_DOB,
                    //     res.data.result.data.user_info.dob
                    // );
                    // SetSessionStorageData(
                    //     AUTHENTICATION.SHARED.USER_ID,
                    //     res.data.result.data.user_info.id
                    // );
                    // SetSessionStorageData(
                    //     AUTHENTICATION.SHARED.ACCOUNT_TYPE,
                    //     AUTHENTICATION.PROFILE_ACCOUNT_TYPE.PARENT
                    // );
                    // if (
                    //     nullUndefinedEmptyCheck(
                    //         res.data.result.data.user_info.image
                    //     )
                    // ) {
                    //     SetSessionStorageData(
                    //         AUTHENTICATION.SHARED.PROFILE_PICTURE,
                    //         res.data.result.data.user_info.image
                    //     );
                    // }
                    // }
                }
            })
            .catch(() => { });
    };

    const renderInput = (inputProps: any, index: number) => {
        return (
            <input
                key={index}
                type="number"
                {...inputProps}
                className='guest-usert-otp-input'
            />
        );
    };

    return (
        <Dialog
            className='guest-user-login-otp-model'
            open={openUserLoginOtp}
            keepMounted
            onClose={CloseUserLoginOtpModel}
            aria-describedby="alert-dialog-slide-description"
        >

            <div className='user-content-container'>
                <div className='guest-user-otp-head'>
                    <img src={OtpVerficationArrowIcon} alt="arrowIcon" style={{ cursor: 'pointer' }} onClick={handleBack} />
                    <p>OTP Verification</p>
                </div>

                <Divider />

                <div className='guest-user-otp-body'>
                    <div>
                        enter the OTP code send to<br />
                        <span> {guestUserData?.email}</span>
                    </div>
                    <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={4}
                        // containerStyle={{  alignContent: 'start' }}
                        renderInput={renderInput}
                    // inputStyle={{ alignContent: 'flex-start',background:"red" }}
                    />
                    {submitted && otp === "" && (
                        <div className="errorMessageOtp">
                            {
                                AUTHENTICATION.LOGIN_SIGNUP_FIELDS_ERROR_MESSAGES
                                    .OTP_REQUIRED
                            }
                        </div>
                    )}

                    {!showResend && (
                        <p className='guest-user-model-timing'>Time Remaining:  {timer} secs</p>
                    )}
                </div>

                <div className='guest-user-verify-button'>
                    <Button variant="contained" color="primary" onClick={verifyOtp}>
                        Verify & Proceed
                    </Button>
                </div>
                {showResend && (
                    <div className="resend_otp_button mt-3">
                        Didn’t receive the OTP?{" "}
                        <span className="resend_otp" onClick={resendOtp}>
                            Resend OTP
                        </span>
                    </div>
                )}

            </div>


        </Dialog>
    );
};

export default ThemeParkGuestUserLoginOtp;
