import { Dialog, DialogContent } from "@mui/material";
import "./ProfileAvatarsDialog.scss";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../Utilities/Interceptor/Interceptor";
import {
  API_END_POINTS,
  AUTHENTICATION,
  GLOBAL_CONSTANTS,
} from "../../Constants/Constants";

/**Assets */
import defaultProfile from "../../Assets/Authentication/defaultProfile.svg";
import selectedAvatarMarker from "../../Assets/Accounts/AddProfile/selectedAvatar.svg";

const ProfileAvatarsDialog = (profileAvatarDialogProps: any) => {
  /**Initial states */
  const [avatarImageList, setAvatarImageList] = useState<any>([]);
  const [avatarImage, setAvatarImage] = useState<any>("");
  const [avatarError, setAvatarError] = useState(
    GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE
  );
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState<any>(null);

  useEffect(() => {
    if (profileAvatarDialogProps.profilePicture === "") {
      clearValues();
    }
  }, [profileAvatarDialogProps.profilePicture]);

  useEffect(() => {
    getAvatarImages();
  }, []);

  /**Avatar images*/
  const getAvatarImages = () => {
    axiosInstance
      .get(`${API_END_POINTS.AVATAR_IMAGES}`)
      .then((avatarImageResponse: any) => {
        if (
          avatarImageResponse.data[AUTHENTICATION.SHARED.STATUS] ===
          AUTHENTICATION.SHARED.TRUE
        ) {
          setAvatarImageList(
            avatarImageResponse.data.result.data[0].avatarImages
          );
        }
      })
      .catch(() => { });
  };

  /**Handle avatar image change */
  const handleAvatarImageChange = (avatarImage: any, index: any) => {
    setSelectedAvatarIndex(index);
    setAvatarImage(avatarImage);
    setAvatarError(GLOBAL_CONSTANTS.INITIAL_VALUES.FALSE);
  };

  /**Update avatar Image */
  const updateAvatarImage = () => {
    if (avatarImage === "") {
      setAvatarError(GLOBAL_CONSTANTS.INITIAL_VALUES.TRUE);
    } else {
      profileAvatarDialogProps.handleCloseProfileAvatarDialog(avatarImage);
    }
  };

  /**Clear all values on component destroy */
  const clearValues = () => {
    setAvatarImage("");
    setSelectedAvatarIndex(null);
  };

  return (
    <>
      <Dialog
        className="avatar_selection_dialog_wrapper"
        open={profileAvatarDialogProps.openProfileAvatars}
      >
        <DialogContent>
          <div className="profile_avatar_dialog_wrapper">
            <div className="profile_avatar_dialog_title">Select Avatar</div>
            <div className="profile_avatar_container">
              <div className="selected_avatar">
                <img
                  src={avatarImage !== "" ? avatarImage : defaultProfile}
                  alt="Learn and Play"
                  className="profile_pic"
                />
                {avatarError && (
                  <div className="errorMessage">Please select an Avatar</div>
                )}
                <div className="avatar_action_buttons_wrapper">
                  <button
                    type="submit"
                    onClick={updateAvatarImage}
                    className="avatar_apply_button"
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    className="cancel_button"
                    onClick={() => {
                      profileAvatarDialogProps.handleCloseProfileAvatarDialog(
                        ""
                      );
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="avatar_images_wrapper">
                {avatarImageList.map((avatarImageElements: any, index: any) => {
                  return (
                    <>
                      <div className="avatar_image_container">
                        <img
                          key={index}
                          src={avatarImageElements}
                          alt="Learn and Play"
                          className="avatar_image_elements"
                          onClick={() => {
                            handleAvatarImageChange(avatarImageElements, index);
                          }}
                        />
                        {selectedAvatarIndex === index && (
                          <img
                            src={selectedAvatarMarker}
                            alt="Learn and Play"
                            className="selected_icon_indicator"
                          />
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfileAvatarsDialog;
