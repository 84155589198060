import Autocomplete from 'react-google-autocomplete';
import { setLocation, setLocationName } from '../../Redux/Reducer/Reducer';
import { useDispatch, useSelector } from 'react-redux';
import { SetSessionStorageData } from '../../Utilities/Helpers/Helper';
import { AUTHENTICATION } from '../../Constants/Constants';
import { useLoadScript } from '@react-google-maps/api';
import { useEffect, useState } from 'react';

const libraries: any = ['places'];

const GoogleCountryDropdown = ({ country, onChange }: any) => {
    const [ShowCountry, setShowCountry] = useState<string>(country || '');
    const [isTyping, setIsTyping] = useState(false); // Track if user is typing manually
    const dispatch = useDispatch();

    const HeaderCountryName: any = useSelector((state: any) => state.locationNameReducer?.locationName);

    // Sync Redux state with the internal component state
    useEffect(() => {
        if (!isTyping && HeaderCountryName && HeaderCountryName !== ShowCountry) {
            // Only update when the user is not typing and the values differ
            setShowCountry(HeaderCountryName);
        }
    }, [HeaderCountryName, ShowCountry, isTyping]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
        libraries, // Load the places library
    });

    if (loadError) {
        return <div>Search Not Fetching..</div>;
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    const geocodeByLatLng = (lat: any, lng: any) => {
        const geocoder = new window.google.maps.Geocoder();
        return new Promise((resolve, reject) => {
            geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {
                if (status === 'OK') {
                    resolve(results);
                } else {
                    reject(status);
                }
            });
        });
    };

    const getFormattedAddress = async (lat: any, lng: any) => {
        try {
            const results: any = await geocodeByLatLng(lat, lng);
            if (results[0]) {
                const location: any = { lat, lng };
                const filterCountry = results[0]?.address_components?.filter((res: any) => res.types.includes('country'));

                const countryName = filterCountry[0]?.long_name || 'N/A';

                setShowCountry(countryName);
                onChange(countryName);

                // dispatch(setLocation(location));
                // dispatch(setLocationName(countryName));
                // SetSessionStorageData(AUTHENTICATION.SHARED.CURRENT_LOCATION, JSON.stringify(location));
                // SetSessionStorageData(AUTHENTICATION.SHARED.COUNTRY_NAME, countryName);
            }
        } catch (error) {
            console.error('Geocode error:', error);
        }
    };

    return (
        <div>
            <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                onPlaceSelected={(place: any) => {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    getFormattedAddress(lat, lng);

                    // SetSessionStorageData(AUTHENTICATION.SHARED.REGION_NAME, place?.formatted_address);

                    setIsTyping(false); // Reset typing flag when a place is selected
                }}
                options={{
                    types: ['geocode'], // Show all results in the dropdown
                }}
                className="auto-complete my-country-dropdown-one"
                value={ShowCountry}  // Bind value to state
                onChange={(e: any) => {
                    setIsTyping(true); // Set typing flag when user is manually typing
                    setShowCountry(e.target.value); // Update input field
                }}
                placeholder="Enter your country"
            />
        </div>
    );
};

export default GoogleCountryDropdown;
